import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
export function DataTableCell({ children, condensed, alignment = 'left', isTotal = false }) {
    return (_jsx("td", Object.assign({ className: classNames('px-1.5 first:pl-3 last:pr-3 font-medium', {
            'py-2': !condensed,
            'py-1.5': condensed,
            'text-left': alignment === 'left',
            'text-right': alignment === 'right',
            'font-bold': isTotal
        }) }, { children: children })));
}
