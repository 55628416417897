var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { InternalServerError, wrapErrorAsColumnError } from '../errors/ColumnErrors';
import { ColumnService } from '../services/directory';
import { wrapSuccess } from '../types/responses';
import { getErrorReporter } from '../utils/errors';
export function safeUpdateModel(model, updateData) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        try {
            yield model.ref.update(updateData);
            return wrapSuccess(undefined);
        }
        catch (e) {
            getErrorReporter().logAndCaptureError(ColumnService.DATABASE, e, 'Failed to update model', {
                id: (_a = model === null || model === void 0 ? void 0 : model.id) !== null && _a !== void 0 ? _a : 'null',
                type: (_b = model === null || model === void 0 ? void 0 : model.type) !== null && _b !== void 0 ? _b : 'null'
            });
            return wrapErrorAsColumnError(e, InternalServerError);
        }
    });
}
