var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { ColumnButton } from 'lib/components/ColumnButton';
import { Modal } from 'lib/components/Modal';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { useFirestoreDocumentListener } from 'lib/frontend/hooks/useFirestoreDocumentListener';
import { getInvoiceLogoFromNewspaper } from 'lib/utils/invoices';
import LoadingState from 'components/LoadingState';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import Firebase from 'EnoticeFirebase';
import { ColumnService } from 'lib/services/directory';
function OrderSelectCarousel({ onSetSelectedNewspaperOrder, selectedNewspaperOrder, activePublisher, newspaperOrders }) {
    const selectedOrderIndex = newspaperOrders.findIndex(order => columnObjectsAreEqual(order, selectedNewspaperOrder));
    const publisherLogo = (activePublisher === null || activePublisher === void 0 ? void 0 : activePublisher.data().darkLogo) ||
        (activePublisher === null || activePublisher === void 0 ? void 0 : activePublisher.data().lightLogo) ||
        getInvoiceLogoFromNewspaper(activePublisher);
    return (_jsxs("div", Object.assign({ className: "flex items-center w-full lg:w-3/4 mx-auto" }, { children: [_jsx(ColumnButton, { type: "button", buttonText: _jsx(ChevronLeftIcon, { className: "w-6 h-6 column-gray-300 hover:column-primary-500" }), disabled: selectedOrderIndex === 0, onClick: () => onSetSelectedNewspaperOrder(newspaperOrders[selectedOrderIndex - 1]) }), _jsxs("div", Object.assign({ className: "flex-1 flex flex-col items-center gap-2" }, { children: [_jsx("div", Object.assign({ className: "max-w-28" }, { children: _jsx("img", { src: publisherLogo }) })), _jsxs("div", Object.assign({ className: "text-sm text-column-gray-400" }, { children: ["Proof ", selectedOrderIndex + 1, " of ", newspaperOrders.length] }))] })), _jsx(ColumnButton, { type: "button", buttonText: _jsx(ChevronRightIcon, { className: "w-6 h-6 column-gray-300 hover:column-primary-500" }), disabled: selectedOrderIndex === newspaperOrders.length - 1, onClick: () => onSetSelectedNewspaperOrder(newspaperOrders[selectedOrderIndex + 1]) })] })));
}
/**
 * Proof preview component button that pops a modal with the preview of the ad.
 * This preview can then be downloaded as a PDF or printed directly from the modal.
 */
export default function ProofPreview({ newspaperOrdersFormData, reloadingPreview, order, version }) {
    var _a, _b;
    const [selectedNewspaperOrder, setSelectedNewspaperOrder] = useState();
    const [showModal, setShowModal] = useState(false);
    const { value: newspaperOrders } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            const newspaperOrders = yield order.getNewspaperOrders({
                specifiedVersion: version
            });
            setSelectedNewspaperOrder(newspaperOrders[0]);
            return newspaperOrders;
        }),
        dependencies: [
            order.id,
            newspaperOrdersFormData === null || newspaperOrdersFormData === void 0 ? void 0 : newspaperOrdersFormData.map(order => { var _a; return (_a = order.newspaper) === null || _a === void 0 ? void 0 : _a.id; }).join(',')
        ]
    });
    const activePublisher = useFirestoreDocumentListener((_a = selectedNewspaperOrder === null || selectedNewspaperOrder === void 0 ? void 0 : selectedNewspaperOrder.data()) === null || _a === void 0 ? void 0 : _a.newspaper);
    const refreshedNewspaperOrder = useFirestoreDocumentListener(selectedNewspaperOrder === null || selectedNewspaperOrder === void 0 ? void 0 : selectedNewspaperOrder.ref);
    const selectedProofPath = (_b = refreshedNewspaperOrder === null || refreshedNewspaperOrder === void 0 ? void 0 : refreshedNewspaperOrder.data()) === null || _b === void 0 ? void 0 : _b.pdfStoragePath;
    const { value: proofUrl, isLoading: isProofLoading } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (!selectedProofPath)
                return;
            const proofUrl = yield Firebase.storage()
                .ref(selectedProofPath)
                .getDownloadURL();
            return proofUrl;
        }),
        dependencies: [selectedProofPath, showModal]
    });
    const enableViewingProofPreviewModal = !!activePublisher &&
        !!newspaperOrders &&
        !!selectedNewspaperOrder &&
        !!selectedProofPath;
    return (_jsxs("div", Object.assign({ className: "flex items-center justify-center" }, { children: [_jsx(ColumnButton, { type: "button", buttonText: "View proof", onClick: () => setShowModal(true), disabled: !enableViewingProofPreviewModal || reloadingPreview, size: "sm" }), showModal && enableViewingProofPreviewModal && (_jsxs(Modal, Object.assign({ id: "proof-preview", onClose: () => setShowModal(false), title: "Proof Preview", size: "2xl" }, { children: [
                    // Show the carousel if there are multiple proofs
                    (newspaperOrders === null || newspaperOrders === void 0 ? void 0 : newspaperOrders.length) && (newspaperOrders === null || newspaperOrders === void 0 ? void 0 : newspaperOrders.length) > 1 && (_jsx(OrderSelectCarousel, { newspaperOrders: newspaperOrders, selectedNewspaperOrder: selectedNewspaperOrder, onSetSelectedNewspaperOrder: setSelectedNewspaperOrder, activePublisher: activePublisher })), _jsx("div", Object.assign({ className: "flex justify-center overflow-scroll pt-4", style: { height: '60vh' } }, { children: isProofLoading ? (_jsx(LoadingState, { context: { service: ColumnService.OBITS } })) : (_jsx("iframe", { title: `Proof Preview for ${activePublisher.data().name}`, src: `${proofUrl}#navpanes=0&scrollbar=0`, frameBorder: "0", width: "100%" })) }))] })))] })));
}
