export default {
    apiKey: "AIzaSyBZjzVWVMptcmXLCW1WWvypB7bqejr6i2s",
    authDomain: "enotice-demo-8d99a.firebaseapp.com",
    databaseURL: "https://enotice-demo-8d99a.firebaseio.com",
    projectId: "enotice-demo-8d99a",
    storageBucket: "enotice-demo-8d99a.appspot.com",
    messagingSenderId: "981763803674",
    appId: "1:981763803674:web:afa37bfffac67fea",
    measurementId: "G-FKYRQ5TJZM"
};
