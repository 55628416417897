var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { safeGetOrThrow } from 'lib/safeWrappers';
import { exists } from 'lib/types';
import { getFirebaseContext } from 'utils/firebase';
import { logError } from 'utils/logger';
function fetchAdjudicationAreas(activeOrganization) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const adjudicationAreas = (_a = activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.adjudicationAreas;
        const promises = (adjudicationAreas === null || adjudicationAreas === void 0 ? void 0 : adjudicationAreas.map((areaRef) => __awaiter(this, void 0, void 0, function* () {
            return safeGetOrThrow(areaRef);
        }))) || [];
        const adjudicationAreasSnapshot = yield Promise.all(promises);
        const adjudicationAreasData = adjudicationAreasSnapshot
            .map(snap => {
            const { response: adjudicationSnap, error } = snap;
            if (error) {
                logError('Unable to load data for adjudication area', {
                    message: error.message
                });
                return null;
            }
            return Object.assign(Object.assign({}, adjudicationSnap.data()), { id: adjudicationSnap.id });
        })
            .filter(entry => entry !== null);
        return adjudicationAreasData;
    });
}
function groupAdjudicationAreasByParent(adjudicationAreasData) {
    return __awaiter(this, void 0, void 0, function* () {
        const adjudicationAreaHierarchy = {};
        const statesRef = yield getFirebaseContext()
            .adjudicationAreasRef()
            .where('type', '==', 'state')
            .get();
        for (const doc of statesRef.docs) {
            const stateData = doc.data();
            adjudicationAreaHierarchy[doc.id] = {
                name: stateData.name,
                counties: {}
            };
        }
        // fetch parent data
        const selectedCountiesParents = adjudicationAreasData
            .filter(area => area.type === 'county')
            .map(area => { var _a; return (_a = area.parent) === null || _a === void 0 ? void 0 : _a.get(); });
        const selectedPlacesParents = adjudicationAreasData
            .filter(area => area.type === 'place')
            .map(area => { var _a; return (_a = area.parent) === null || _a === void 0 ? void 0 : _a.get(); });
        const states = yield Promise.all(selectedCountiesParents);
        const counties = yield Promise.all(selectedPlacesParents);
        // group data
        states.filter(exists).forEach(state => {
            var _a;
            adjudicationAreaHierarchy[state.id] = {
                name: ((_a = state.data()) === null || _a === void 0 ? void 0 : _a.name) || '',
                counties: {}
            };
        });
        counties.filter(exists).forEach(county => {
            var _a, _b, _c;
            const parentId = (_b = (_a = county.data()) === null || _a === void 0 ? void 0 : _a.parent) === null || _b === void 0 ? void 0 : _b.id;
            if (parentId) {
                adjudicationAreaHierarchy[parentId].counties = Object.assign(Object.assign({}, adjudicationAreaHierarchy[parentId].counties), { [county.id]: {
                        name: ((_c = county.data()) === null || _c === void 0 ? void 0 : _c.name) || '',
                        places: []
                    } });
            }
        });
        // backfill data
        adjudicationAreasData.forEach(area => {
            var _a, _b, _c, _d;
            if (area.type === 'county') {
                const stateId = (_a = area.parent) === null || _a === void 0 ? void 0 : _a.id;
                if (stateId) {
                    adjudicationAreaHierarchy[stateId].counties[area.id] = {
                        name: area.name,
                        places: []
                    };
                }
            }
            if (area.type === 'place') {
                const countyId = (_b = area.parent) === null || _b === void 0 ? void 0 : _b.id;
                const countyRecord = counties.find(county => {
                    if (county)
                        return county.id === countyId;
                    return false;
                });
                if (!countyRecord)
                    return;
                const countyDataRecord = countyRecord === null || countyRecord === void 0 ? void 0 : countyRecord.data();
                const stateId = (_c = countyDataRecord === null || countyDataRecord === void 0 ? void 0 : countyDataRecord.parent) === null || _c === void 0 ? void 0 : _c.id;
                if (countyId && stateId) {
                    (_d = adjudicationAreaHierarchy[stateId].counties[countyId]) === null || _d === void 0 ? void 0 : _d.places.push(area.name);
                }
            }
        });
        return adjudicationAreaHierarchy;
    });
}
/**
 *
 * @param data adjudication areas
 * @returns concatenated string of adjudication areas id joined by comma
 */
function getAdjudicationAreasJoinedString(data) {
    var _a;
    if (!data || !data.adjudicationAreas)
        return '';
    return (_a = data === null || data === void 0 ? void 0 : data.adjudicationAreas) === null || _a === void 0 ? void 0 : _a.map(area => area.id).join(', ');
}
function shouldDisplayAddButton(data) {
    var _a;
    return !((_a = data === null || data === void 0 ? void 0 : data.adjudicationAreas) === null || _a === void 0 ? void 0 : _a.length);
}
export { fetchAdjudicationAreas, groupAdjudicationAreasByParent, getAdjudicationAreasJoinedString, shouldDisplayAddButton };
