var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { ProductPublishingSettingsService } from 'lib/services/productPublishingSettingsService';
import { getFirebaseContext } from 'utils/firebase';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { safeStringify } from 'lib/utils/stringify';
import { useAppSelector } from 'redux/hooks';
import { selectIsPublisher } from 'redux/auth';
import LoadingState from 'components/LoadingState';
import { ColumnService } from 'lib/services/directory';
import { asyncMap, isDefined } from 'lib/helpers';
import { Alert } from 'lib/components/Alert';
import { logAndCaptureException } from 'utils';
import PerPublisherSettings, { getValidFilingTypes } from './PerPublisherSettings';
import { getValidationMessageForFilingTypeSelection } from './validation';
import { NewspapersContext } from '../../contexts/NewspapersContext';
import getNextPublishingDate from './getNextPublishingDate';
const getNewspaperOrderWithPublishingSettings = (product, isPublisher, productPublishingSettingService, newspaperOrder, newspapers) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const newspaperRef = newspaperOrder.newspaper;
    if (!newspaperRef) {
        const error = new Error('Newspaper order missing newspaper');
        return wrapError(error);
    }
    const newspaperSnap = newspapers.find(o => o.id === newspaperRef.id);
    if (!newspaperSnap) {
        const error = new Error('Newspaper context missing newspaper');
        return wrapError(error);
    }
    const { response: detailedPublishingSettings, error: publishingSettingsError } = yield productPublishingSettingService.fetchOrCreateDetailedProductPublishingSetting(newspaperOrder.newspaper, product, newspaperOrder.publishingMedium, {
        shouldCreate: false
    });
    if (publishingSettingsError) {
        return wrapError(publishingSettingsError);
    }
    const { filingTypes: allFilingTypes } = detailedPublishingSettings;
    const publishingSettings = Object.assign(Object.assign({}, detailedPublishingSettings), { filingTypes: getValidFilingTypes(allFilingTypes, isPublisher) || [] });
    const newspaperOrderWithDates = Object.assign(Object.assign({}, newspaperOrder), { newspaper: newspaperRef, publishingDates: ((_a = newspaperOrder.publishingDates) === null || _a === void 0 ? void 0 : _a.length)
            ? newspaperOrder.publishingDates
            : [
                getNextPublishingDate(newspaperSnap, publishingSettings.publishingSetting, new Date())
            ] });
    return wrapSuccess({
        newspaperOrder: newspaperOrderWithDates,
        publishingSettings
    });
});
function SelectSchedules({ newspaperOrdersFormData, onNewspaperOrdersFormDataChange, onFilingTypeChange, product, isInitialPlacementFlow, editData, orderModel }) {
    const newspapers = useContext(NewspapersContext);
    const isPublisher = useAppSelector(selectIsPublisher);
    const publishingSettingService = new ProductPublishingSettingsService(getFirebaseContext());
    const [userError, setUserError] = useState('');
    const { value: publishingSettingsForNewspaperOrders, isLoading: publishingSettingsLoading } = useSafeAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            const result = yield asyncMap(newspaperOrdersFormData, newspaperOrder => getNewspaperOrderWithPublishingSettings(product, isPublisher, publishingSettingService, newspaperOrder, newspapers));
            if (result.error) {
                return result;
            }
            const record = {};
            result.response.forEach(({ newspaperOrder, publishingSettings }) => {
                if (!newspaperOrder.publishingMedium || !newspaperOrder.newspaper) {
                    const error = new Error('Missing required newspaper order data');
                    setUserError(error.message);
                    logAndCaptureException(ColumnService.OBITS, error, error.message, {
                        publishingMedium: `${newspaperOrder.publishingMedium}`,
                        newspaperId: `${newspaperOrder.newspaper.id}`,
                        orderId: orderModel.id
                    });
                    return;
                }
                record[newspaperOrder.newspaper.id] = Object.assign(Object.assign({}, record[newspaperOrder.newspaper.id]), { [newspaperOrder.publishingMedium]: publishingSettings });
            });
            onNewspaperOrdersFormDataChange(result.response.map(o => o.newspaperOrder));
            return wrapSuccess(record);
        }),
        initialData: null,
        dependencies: [
            safeStringify(newspaperOrdersFormData.map(no => { var _a; return `${((_a = no.newspaper) === null || _a === void 0 ? void 0 : _a.id, no.publishingMedium)}`; }))
        ]
    });
    if (userError) {
        return _jsx(Alert, { id: "newspaper-error", title: "Error", description: userError });
    }
    if (publishingSettingsLoading || !publishingSettingsForNewspaperOrders) {
        return (_jsx(LoadingState, { context: {
                service: ColumnService.OBITS,
                location: 'Ad placement - Select Schedules',
                tags: {
                    product,
                    adPlacementFlow: 'true',
                    publishingSettingsLoading: publishingSettingsLoading
                        ? 'true'
                        : 'false',
                    newspaperOrdersWithPublishingSettings: publishingSettingsForNewspaperOrders
                        ? 'available'
                        : 'null',
                    orderId: orderModel.id
                }
            } }));
    }
    const newspaperOrders = newspaperOrdersFormData
        .map(no => {
        var _a, _b;
        const newspaperSnap = newspapers.find(n => { var _a; return n.id === ((_a = no.newspaper) === null || _a === void 0 ? void 0 : _a.id); });
        if (!newspaperSnap) {
            const error = new Error('Newspaper order contains newspaper not in context');
            setUserError(error.message);
            logAndCaptureException(ColumnService.OBITS, error, error.message, {
                newspaperId: `${(_a = no.newspaper) === null || _a === void 0 ? void 0 : _a.id}`,
                orderId: orderModel.id
            });
            return;
        }
        if (!((_b = no.publishingDates) === null || _b === void 0 ? void 0 : _b.length)) {
            const error = new Error('Missing publishing dates');
            setUserError(error.message);
            logAndCaptureException(ColumnService.OBITS, error, error.message, {
                newspaperId: `${newspaperSnap.id}`,
                publishingMedium: no.publishingMedium,
                orderId: orderModel.id
            });
            return;
        }
        return Object.assign(Object.assign({}, no), { newspaper: newspaperSnap.ref, publishingDates: no.publishingDates });
    })
        .filter(isDefined);
    const onNewspaperOrderChange = (newspaperOrder) => {
        onNewspaperOrdersFormDataChange(newspaperOrders.map(o => {
            var _a;
            return o.newspaper.id === ((_a = newspaperOrder.newspaper) === null || _a === void 0 ? void 0 : _a.id) &&
                o.publishingMedium === newspaperOrder.publishingMedium
                ? Object.assign(Object.assign({}, o), newspaperOrder) : o;
        }));
    };
    const editableNewspaperIds = Object.values(editData || {})
        .filter(ed => ed.canEdit)
        .map(ed => ed.newspaperId);
    const canEditNewspaperOrder = (newspaperOrder) => {
        if (isInitialPlacementFlow) {
            return true;
        }
        return editableNewspaperIds.some(newspaperId => { var _a; return newspaperId === ((_a = newspaperOrder.newspaper) === null || _a === void 0 ? void 0 : _a.id); });
    };
    const newspaperOrdersWithPublishingSettings = newspaperOrders
        .map(o => ({
        newspaperOrder: o,
        publishingSettings: o.newspaper &&
            o.publishingMedium &&
            publishingSettingsForNewspaperOrders[o.newspaper.id][o.publishingMedium]
    }))
        .filter((o) => isDefined(o.publishingSettings));
    return (_jsx(_Fragment, { children: newspaperOrdersWithPublishingSettings.map(newspaperOrderWithPublishingSettings => (_jsx(PerPublisherSettings, { product: product, newspaperOrderWithPublishingSettings: newspaperOrderWithPublishingSettings, isPublisher: isPublisher, onNewspaperOrderChange: onNewspaperOrderChange, onFilingTypeChange: onFilingTypeChange, validateFilingTypeSelection: (selection) => getValidationMessageForFilingTypeSelection(selection, publishingSettingsForNewspaperOrders, newspaperOrdersFormData), isInitialPlacementFlow: isInitialPlacementFlow, disableEdits: !canEditNewspaperOrder(newspaperOrderWithPublishingSettings.newspaperOrder) }, newspaperOrderWithPublishingSettings.publishingSettings
            .publishingSetting.id))) }));
}
export default SelectSchedules;
