import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Collapse } from '@material-ui/core';
import { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
function AdjudicationAreaLabel({ county }) {
    return (_jsx("p", Object.assign({ className: "text-md text-column-primary-800 mt-4" }, { children: county }), county));
}
function generateCountiesLabels(countyMap) {
    return Object.keys(countyMap)
        .map(key => {
        const county = countyMap[key];
        if (county.places.length === 0)
            return county.name;
        return county.places.map(placeName => `${county.name} - ${placeName}`);
    })
        .flat()
        .join(', ');
}
function AdjudicationCardContentSingleCounty({ counties }) {
    const label = generateCountiesLabels(counties);
    return _jsx(AdjudicationAreaLabel, { county: label }, label);
}
function AdjudicationCardContentCollapsible({ counties }) {
    const [open, setOpen] = useState(false);
    const numOfCounties = Object.keys(counties).length;
    const numOfPlaces = Object.keys(counties).reduce((acc, key) => {
        return acc + counties[key].places.length;
    }, 0);
    const countyHeaderLabel = numOfCounties > 1 ? `${numOfCounties} counties` : '1 county';
    const placesHeaderLabel = numOfPlaces === 0
        ? ''
        : numOfPlaces > 1
            ? `${numOfPlaces} cities`
            : '1 city';
    const headerLabel = placesHeaderLabel.length === 0
        ? `${countyHeaderLabel}`
        : `${countyHeaderLabel} and ${placesHeaderLabel}`;
    return (_jsxs("div", Object.assign({ className: "mt-4" }, { children: [_jsxs("button", Object.assign({ className: "flex align-center font-medium text-md text-column-primary-800", onClick: () => setOpen(!open) }, { children: [_jsx("p", { children: headerLabel }), _jsx("div", Object.assign({ className: "pl-2 self-center" }, { children: open ? (_jsx(ChevronUpIcon, { className: "w-5 h-5" })) : (_jsx(ChevronDownIcon, { className: "w-5 h-5" })) }))] })), _jsx(Collapse, Object.assign({ in: open, timeout: "auto", className: "pt-2" }, { children: _jsx(AdjudicationAreaLabel, { county: generateCountiesLabels(counties) }) }))] })));
}
export function AdjudicationCardContent({ counties }) {
    return Object.keys(counties).length > 1 ? (_jsx(AdjudicationCardContentCollapsible, { counties: counties })) : (_jsx(AdjudicationCardContentSingleCounty, { counties: counties }));
}
