const BRAINWORKS_ADPERKS = 'Adperks (by Brainworks)';
const BRAINWORKS_STRATICA = 'Stratica (by Brainworks)';
const LINEUP_ADPOINT = 'Adpoint (by Lineup)';
const MILES_33_GEMSTONE = 'Gemstone (by Miles 33)';
const NAVIGA_AD = 'Naviga Ad';
const NAVIGA_ADBASE = 'Adbase (by Naviga)';
const NAVIGA_ADBOOKER = 'Adbooker (by Naviga)';
const NAVIGA_AD_MANAGER_PRO = 'Ad Manager Pro (by Naviga)';
const NAVIGA_UNKNOWN = 'Unknown Naviga System';
const NEWZWARE = 'Newzware';
const OGDEN_ANZIO = 'Anzio (by Ogden)';
const SCS_ADMAX = 'AdMAX (by SCS)';
const VISION_DATA_TOTAL_AD = 'Total Ad (by Vision Data)';
export const IntegrationTypeData = {
    apg_adperks: {
        value: 1,
        label: 'APG',
        key: 'apg_adperks',
        system: BRAINWORKS_ADPERKS
    },
    bang_adbase: {
        value: 2,
        label: 'BANG',
        key: 'bang_adbase',
        system: NAVIGA_ADBASE
    },
    champion_amp: {
        value: 3,
        label: 'Champion',
        key: 'champion_amp',
        system: NAVIGA_AD_MANAGER_PRO
    },
    ogden_fnp_naviga: {
        value: 4,
        label: 'Ogden (FNP)',
        key: 'ogden_fnp_naviga',
        system: NAVIGA_AD
    },
    gannett_adpoint: {
        value: 5,
        label: 'Gannett',
        key: 'gannett_adpoint',
        system: LINEUP_ADPOINT
    },
    hometown_scs_admax: {
        value: 6,
        label: 'Hometown News',
        key: 'hometown_scs_admax',
        system: SCS_ADMAX
    },
    lee_naviga: {
        value: 7,
        label: 'Lee (Omaha World Herald)',
        key: 'lee_naviga',
        system: NAVIGA_UNKNOWN
    },
    lee_adpoint: {
        value: 8,
        label: 'Lee',
        key: 'lee_adpoint',
        system: LINEUP_ADPOINT
    },
    mcclatchy_adpoint: {
        value: 9,
        label: 'McClatchy',
        key: 'mcclatchy_adpoint',
        system: LINEUP_ADPOINT
    },
    ogden_fortwayne_naviga: {
        value: 10,
        label: 'Ogden (Fort Wayne)',
        key: 'ogden_fortwayne_naviga',
        system: NAVIGA_UNKNOWN
    },
    swift_adbooker: {
        value: 11,
        label: 'Ogden (Swift)',
        key: 'swift_adbooker',
        system: NAVIGA_ADBOOKER
    },
    ogden_kpc_newzware: {
        value: 12,
        label: 'Ogden (KPC)',
        key: 'ogden_kpc_newzware',
        system: NEWZWARE
    },
    ogden_anzio: {
        value: 13,
        label: 'Ogden (Core)',
        key: 'ogden_anzio',
        system: OGDEN_ANZIO
    },
    scng_adbase: {
        value: 14,
        label: 'SCNG',
        key: 'scng_adbase',
        system: NAVIGA_ADBASE
    },
    cherryroad_admax: {
        value: 15,
        label: 'CherryRoad',
        key: 'cherryroad_admax',
        system: SCS_ADMAX
    },
    seaton_stratica: {
        value: 16,
        label: 'Seaton',
        key: 'seaton_stratica',
        system: BRAINWORKS_STRATICA
    },
    trib_live_gemstone: {
        value: 17,
        label: 'Trib Live',
        key: 'trib_live_gemstone',
        system: MILES_33_GEMSTONE
    },
    wick_vision_data: {
        value: 18,
        label: 'Wick',
        key: 'wick_vision_data',
        system: VISION_DATA_TOTAL_AD
    },
    horizon_vision_data: {
        value: 19,
        label: 'Horizon Publications',
        key: 'horizon_vision_data',
        system: VISION_DATA_TOTAL_AD
    },
    casa_grande_vision_data: {
        value: 20,
        label: 'Casa Grande',
        key: 'casa_grande_vision_data',
        system: VISION_DATA_TOTAL_AD
    },
    southern_adperks: {
        value: 21,
        label: 'Southern',
        key: 'southern_adperks',
        system: BRAINWORKS_ADPERKS
    },
    tbt_adbase: {
        value: 22,
        label: 'TBT',
        key: 'tbt_adbase',
        system: NAVIGA_ADBASE
    },
    forum_stratica: {
        value: 23,
        label: 'Forum',
        key: 'forum_stratica',
        system: BRAINWORKS_STRATICA
    },
    sdut_adbase: {
        value: 24,
        label: 'SDUT',
        key: 'sdut_adbase',
        system: NAVIGA_ADBASE
    },
    dr_media: {
        value: 25,
        label: 'DR Media',
        key: 'dr_media',
        system: NAVIGA_AD_MANAGER_PRO
    },
    tbt_stratica: {
        value: 26,
        label: 'TBT_STRATICA',
        key: 'tbt_stratica',
        system: BRAINWORKS_STRATICA
    }
};
