var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { exists } from 'lib/types';
import { isPublisherOrganization } from 'lib/utils/organizations';
import { isPublisher } from 'lib/utils/users';
import { getModelFromSnapshot } from 'lib/model';
import { getFirebaseContext } from 'utils/firebase';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { Product } from 'lib/enums';
import { getShouldUseAdvancedPagination } from 'routes/pagination/paginationTableUtils';
const getPaginationTabItemsInner = ({ publicNoticesPaginationEnabled, obituariesPaginationEnabled, classifiedsPaginationEnabled }) => [
    {
        id: Product.Notice,
        path: `/pagination?product=${Product.Notice}`,
        label: 'Public Notices',
        enabled: publicNoticesPaginationEnabled
    },
    {
        id: Product.Obituary,
        path: `/pagination?product=${Product.Obituary}`,
        label: 'Obituaries',
        enabled: obituariesPaginationEnabled
    },
    {
        id: Product.Classified,
        path: `/pagination?product=${Product.Classified}`,
        label: 'Classifieds',
        enabled: classifiedsPaginationEnabled
    }
];
export const useGetPaginationTabItems = (activeOrganization, user) => {
    const [paginationTabItems, setPaginationTabItems] = useState([]);
    const getPaginationTabItems = (activeOrganization, user) => __awaiter(void 0, void 0, void 0, function* () {
        // Should only show the pagination tab if the current user is a publisher
        if (!exists(user) ||
            !exists(activeOrganization) ||
            !isPublisherOrganization(activeOrganization) ||
            !isPublisher(user)) {
            return getPaginationTabItemsInner({
                publicNoticesPaginationEnabled: false,
                obituariesPaginationEnabled: false,
                classifiedsPaginationEnabled: false
            });
        }
        const publicNoticesPaginationEnabled = yield getShouldUseAdvancedPagination(activeOrganization, Product.Notice);
        const organizationModel = getModelFromSnapshot(OrganizationModel, getFirebaseContext(), activeOrganization);
        const obituariesPaginationEnabled = organizationModel.hasAdTypeActive(Product.Obituary);
        const classifiedsPaginationEnabled = organizationModel.hasAdTypeActive(Product.Classified);
        // Should only show the pagination tab if the newspaper has customPagination enabled
        // and the current user is a publisher
        return getPaginationTabItemsInner({
            publicNoticesPaginationEnabled,
            obituariesPaginationEnabled,
            classifiedsPaginationEnabled
        });
    });
    useEffect(() => {
        void (() => __awaiter(void 0, void 0, void 0, function* () {
            setPaginationTabItems(yield getPaginationTabItems(activeOrganization, user));
        }))();
    }, [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    return paginationTabItems;
};
