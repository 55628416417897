import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import LoadingState from 'components/LoadingState';
import { centsToDollarsString } from 'lib/helpers';
import OrderSummaryRow from '../OrderSummaryRow';
function PricingData({ price }) {
    return (_jsx("div", Object.assign({ className: "text-sm font-semibold" }, { children: `$${centsToDollarsString(price)}` })));
}
function PricingComparison({ oldTotalInCents, totalInCents, invoiceLoading }) {
    return (_jsxs(_Fragment, { children: [_jsx(OrderSummaryRow, { label: "Old price", data: _jsx("div", Object.assign({ className: "text-column-red-700 line-through" }, { children: _jsx(PricingData, { price: oldTotalInCents }) })) }), _jsx(OrderSummaryRow, { label: "New price", data: _jsx("div", Object.assign({ className: "text-sm font-semibold text-column-green-600" }, { children: invoiceLoading ? (_jsx(LoadingState, { isFullScreen: false })) : (_jsx(PricingData, { price: totalInCents })) })) })] }));
}
export default PricingComparison;
