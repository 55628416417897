var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Alert } from 'lib/components/Alert';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { getFirebaseContext } from 'utils/firebase';
import { logWarn } from 'utils/logger';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import api from 'api';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import AuthActions, { selectUserAuthIsSet } from 'redux/auth';
import { getOrderToken } from 'routes/ads/place/helpers/orderOperations';
import { getOrThrow } from 'lib/utils/refs';
import { VerificationForm } from './VerificationForm';
export default function FuneralHomeVerification() {
    const params = getLocationParams();
    const obituaryId = params.get('obituaryId');
    const accessCode = params.get('accessCode');
    const email = params.get('email');
    const dispatch = useAppDispatch();
    const userAuthIsSet = useAppSelector(selectUserAuthIsSet);
    const authorizeUser = () => __awaiter(this, void 0, void 0, function* () {
        if (userAuthIsSet || !accessCode || !email) {
            return;
        }
        const { token } = yield getOrderToken(accessCode, email, {
            forFuneralHomeVerification: true,
            editVersion: undefined
        });
        dispatch(AuthActions.loginToken(token));
    });
    const { value, isLoading: isLoadingOrder, isError: fetchingOrderError } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (!userAuthIsSet) {
                yield authorizeUser();
                return null;
            }
            if (!obituaryId) {
                return null;
            }
            const obituarySnap = yield getOrThrow(getFirebaseContext().obituariesRef().doc(obituaryId));
            const orderId = obituarySnap.data().order.id;
            if (!orderId) {
                return null;
            }
            const result = yield api.get(`orders/${orderId}/filer-name`);
            if (result.error) {
                throw result.error;
            }
            return {
                orderData: result.response,
                obituarySnap
            };
        }),
        dependencies: [obituaryId, userAuthIsSet, accessCode, email]
    });
    const { orderData, obituarySnap } = value || {};
    const obituaryData = obituarySnap === null || obituarySnap === void 0 ? void 0 : obituarySnap.data();
    if (!obituarySnap || isLoadingOrder) {
        return _jsx(LoadingSpinner, {});
    }
    if (!orderData ||
        (orderData.isIndividualOrder &&
            (!orderData.filerFirstName || !orderData.filerLastName)) ||
        !(obituaryData === null || obituaryData === void 0 ? void 0 : obituaryData.deathVerification.funeralHomeName) ||
        !(obituaryData === null || obituaryData === void 0 ? void 0 : obituaryData.deceasedName) ||
        !(obituaryData === null || obituaryData === void 0 ? void 0 : obituaryData.deceasedBirthDate) ||
        fetchingOrderError) {
        logWarn('Tried to access verification for an obituary missing information.', {
            obituaryId
        });
        return (_jsx(Alert, { id: "missing-verification-information", title: "Missing Information", description: "This obituary is missing information and cannot be verified at this time.", status: "error" }));
    }
    if (obituaryData.deathVerification.verifiedAt) {
        return (_jsx(Alert, { id: "already-verified", title: "Verified", description: "This obituary has been verified.", status: "success" }));
    }
    return (_jsx(VerificationForm, { orderData: orderData, obituarySnap: obituarySnap, email: email }));
}
