var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Modal } from 'lib/components/Modal';
import api from 'api';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import useDebounce from 'lib/frontend/hooks/useDebounce';
import { getFirebaseContext } from 'utils/firebase';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { TextField } from 'lib/components/TextField';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { Alert } from 'lib/components/Alert';
import { ShowAdjudicationAreaOptions } from './AdjudicationAreaOption';
import { AdjudicationSelectedEntries } from './AdjudicationSelectedEntries';
const INPUT_DEBOUNCE_MS = 500;
export default function AdjudicationAreaModal({ adjudicationAreas, selectedAreasGroupedByState, onSave, onClose }) {
    const [selectedAreas, setSelectedAreas] = useState(adjudicationAreas || []);
    const [searchItem, setSearchItem] = useState('');
    const debouncedSearchItem = useDebounce(searchItem, INPUT_DEBOUNCE_MS);
    const { isLoading: loadingAreas, value: loadedAreas, isError } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (debouncedSearchItem === '') {
                return [];
            }
            const data = yield api.post('search/adjudication-areas', {
                search: debouncedSearchItem,
                pageSize: 20
            });
            const { response } = data;
            return response.results;
        }),
        dependencies: [debouncedSearchItem]
    });
    const handleInputChange = (searchTerm) => {
        setSearchItem(searchTerm);
    };
    return (_jsx(Modal, Object.assign({ id: "adjudication-modal", title: "Select adjudication area", size: "xl", primaryAction: {
            buttonText: 'Save',
            type: 'button',
            disabled: selectedAreas.length === 0,
            onClick: () => {
                const mappedRefs = selectedAreas.map(area => getFirebaseContext().adjudicationAreasRef().doc(area.id));
                onSave(mappedRefs);
                onClose();
            }
        }, secondaryActions: [
            {
                buttonText: 'Cancel',
                type: 'button'
            }
        ], onClose: onClose }, { children: _jsxs("div", Object.assign({ className: "flex flex-col gap-4" }, { children: [_jsx(AdjudicationSelectedEntries, { selectedAreasGroupingReference: selectedAreasGroupedByState, selectedAreas: selectedAreas, onAreaRemoved: area => {
                        const index = selectedAreas.findIndex(selectedArea => selectedArea.id === area.id);
                        setSelectedAreas(prevState => {
                            const newState = [...prevState];
                            newState.splice(index, 1);
                            return newState;
                        });
                    } }), _jsx(TextField, { id: 'adjudication-search', labelText: 'Search', placeholder: "Orange County", size: "small", value: searchItem, onChange: handleInputChange, prefix: _jsx("div", Object.assign({ className: "pb-1" }, { children: _jsx(MagnifyingGlassIcon, { className: "w-5 text-column-gray-300", "aria-hidden": "true" }) })) }), _jsxs("div", Object.assign({ className: "overflow-auto rounded-md focus:outline-none font-medium text-column-gray-500 bg-transparent" }, { children: [loadingAreas && (_jsx("div", Object.assign({ className: "m-4" }, { children: _jsx(LoadingSpinner, {}) }))), !loadingAreas && (loadedAreas === null || loadedAreas === void 0 ? void 0 : loadedAreas.length) === 0 && searchItem !== '' && (_jsx("h1", { children: "No entries found" })), loadedAreas && isError && (_jsx(Alert, { id: "adjudication-areas-options-error", status: "error", title: "Error retrieving Adjudication Areas" })), _jsx(ShowAdjudicationAreaOptions, { adjudicationAreas: loadedAreas, onOptionClicked: area => {
                                // only adds options that were not previously selected
                                if (selectedAreas.findIndex(alreadySelected => alreadySelected.id === area.id) === -1) {
                                    setSelectedAreas(prevState => [...prevState, area]);
                                }
                            } })] }))] })) })));
}
