import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ClockIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
import moment from 'moment';
import { capitalize } from 'lodash';
import OrderSummaryRow from './OrderSummaryRow';
function PublishingDateBadge({ date }) {
    return (_jsx(Badge, Object.assign({ startIcon: _jsx(ClockIcon, { className: "h-5 w-5" }), size: "md" }, { children: moment(date).format('ddd DD MMM, YYYY') })));
}
function NewspaperOrderSummary({ newspaper, publishingMedium, publishingDates, filingTypeLabel }) {
    return (_jsx(OrderSummaryRow, { label: _jsxs("span", Object.assign({ className: "flex gap-4" }, { children: [_jsx("span", { children: newspaper.data().name }), _jsx(Badge, Object.assign({ status: "info", size: "md" }, { children: filingTypeLabel })), _jsx(Badge, Object.assign({ size: "md" }, { children: capitalize(publishingMedium) }))] })), data: _jsx("div", Object.assign({ className: "grid grid-cols-1 gap-1" }, { children: publishingDates.map(date => (_jsx(PublishingDateBadge, { date: date }, date))) })) }));
}
export default NewspaperOrderSummary;
