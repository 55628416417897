import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { QuestionMarkCircleIcon, RectangleStackIcon, DocumentMinusIcon, ChartPieIcon, ArrowPathIcon, ScaleIcon, NewspaperIcon } from '@heroicons/react/24/outline';
import { exists } from 'lib/types';
import { OccupationType } from 'lib/enums';
import SyncErrorDrawer from 'routes/notices/table/SyncErrorDrawer';
import { isPublisher } from 'lib/utils/users';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import Notifications from 'components/NotificationsDrawer/Notifications';
import AddCustomerOrganizationNavLink from './AddCustomerOrganizationNavLink';
import SettingsHeaderBarClickableIcon from './SettingsHeaderBarClickableIcon';
import HeaderBarClickableIcon from './HeaderBarClickableIcon';
import { InternalLabels } from './InternalLabels';
import HeaderNavBarLink from './HeaderNavBarLink';
import HeaderNavBarLogo from './HeaderNavBarLogo';
import { ActiveOrganizationSelect } from './ActiveOrganizationSelect';
import { useDetermineShowAutomatedAffidavitsTab } from './useDetermineShowAutomatedAffidavitsTab';
import { useDetermineShowPaymentsTab } from './useDetermineShowPaymentsTab';
import { useGetPaginationTabItems } from './useGetPaginationTabItems';
import HeaderNavBarPopover from './HeaderNavBarPopover';
import { selectOrdersTabItems } from './selectOrdersTabItems';
const HELP_CENTER_LINK = 'https://help.column.us';
export const HEADER_HEIGHT = 80;
export default function AppLayoutHeader({ activeOrganization, user }) {
    const [showSyncErrorDrawer, setShowSyncErrorDrawer] = useState(false);
    const showAutomatedAffidavitsTab = useDetermineShowAutomatedAffidavitsTab(activeOrganization);
    const paginationTabItems = useGetPaginationTabItems(activeOrganization, user);
    const showPaginationTab = !!paginationTabItems.find(item => item.enabled);
    const ordersTabItems = useAppSelector(selectOrdersTabItems);
    const showOrdersTab = !!ordersTabItems.find(item => item.enabled);
    const showPaymentsTab = useDetermineShowPaymentsTab(user);
    const displayCreateJoinOrganizationTab = (user === null || user === void 0 ? void 0 : user.data().occupation) === OccupationType.individual.value;
    const userIsPublisher = !!user && isPublisher(user);
    const enableSyncErrorTable = getBooleanFlag(LaunchDarklyFlags.ENABLE_SYNC_ERROR_TABLE);
    return (_jsxs("header", Object.assign({ className: "h-20 flex items-center px-6 md:px-12 border-b h-20 z-20" }, { children: [_jsx(HeaderNavBarLogo, {}), _jsxs("div", Object.assign({ className: "flex-1 flex items-center flex-row-reverse md:flex-row justify-start gap-x-4" }, { children: [exists(user) && !displayCreateJoinOrganizationTab && (_jsx("div", Object.assign({ className: "md:ml-6" }, { children: _jsx(ActiveOrganizationSelect, {}) }))), _jsxs("div", Object.assign({ className: "hidden md:flex md:items-center md:flex-1 md:ml-6" }, { children: [displayCreateJoinOrganizationTab && (_jsx(AddCustomerOrganizationNavLink, {})), exists(user) && showOrdersTab && (_jsx(HeaderNavBarPopover, { label: "Orders", icon: _jsx(RectangleStackIcon, { className: "h-6 w-6" }), id: "orders-table", items: ordersTabItems })), exists(user) &&
                                user.data().occupation === OccupationType.publishing.value && (_jsx(HeaderNavBarLink, { path: "/reports", label: "Reports", icon: _jsx(ChartPieIcon, { className: "h-6 w-6" }), id: "reports-link" })), exists(user) && showPaymentsTab && (_jsx(HeaderNavBarLink, { path: "/payments", label: 'Invoices', icon: _jsx(DocumentMinusIcon, { className: "h-6 w-6" }), id: "bulk-invoices" })), exists(user) && showAutomatedAffidavitsTab && (_jsx(HeaderNavBarLink, { path: "/affidavits", label: "Affidavits", icon: _jsx(ScaleIcon, { className: "h-6 w-6" }), id: "auto-affidavits" })), exists(user) && showPaginationTab && (_jsx(HeaderNavBarPopover, { label: "Pagination", icon: _jsx(NewspaperIcon, { className: "w-6 h-6" }), id: "pagination", items: paginationTabItems })), _jsx(InternalLabels, {})] })), _jsxs("div", Object.assign({ className: "flex" }, { children: [userIsPublisher && enableSyncErrorTable && (_jsx(HeaderBarClickableIcon, { id: "sync-errors", icon: _jsx(ArrowPathIcon, { className: "w-6 h-6" }), onClick: () => setShowSyncErrorDrawer(!showSyncErrorDrawer), label: "Sync Errors" })), exists(user) && _jsx(Notifications, { user: user }), _jsx(HeaderBarClickableIcon, { id: "help-icon", icon: _jsx(QuestionMarkCircleIcon, { className: "w-6 h-6" }), onClick: () => window.open(HELP_CENTER_LINK, '_blank'), label: "Help Center" }), exists(user) && _jsx(SettingsHeaderBarClickableIcon, { user: user })] }))] })), showSyncErrorDrawer && !!activeOrganization && (_jsx(SyncErrorDrawer, { activeOrganization: activeOrganization, showSyncErrorDrawer: true, setShowSyncErrorDrawer: setShowSyncErrorDrawer }))] })));
}
