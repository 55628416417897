import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
export function DataTableSortIndicator({ sortDirection }) {
    return (_jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsx(ChevronUpIcon, { className: classNames('w-3 -my-1', {
                    'text-column-gray-900': sortDirection === 'asc',
                    'text-column-gray-200': sortDirection !== 'asc'
                }) }), _jsx(ChevronDownIcon, { className: classNames('w-3 -mb-1', {
                    'text-column-gray-900': sortDirection === 'desc',
                    'text-column-gray-200': sortDirection !== 'desc'
                }) })] })));
}
