import { jsx as _jsx } from "react/jsx-runtime";
import { Alert } from 'lib/components/Alert';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { GridInput } from 'lib/components/Card/Grid';
import { ColumnButton } from 'lib/components/ColumnButton';
import { AdjudicationGroupingArea } from './AdjudicationGroupingArea';
import AdjudicationAreaModal from './AdjudicationModal/AdjudicationAreaModal';
export function AdjudicationInfoContent({ loading, error, open, selectedAreasGroupedByState, adjudicationAreasData, onAdjudicationAreasSelectionSaved, onModalStateChanged, displayAddButton }) {
    if (loading)
        return _jsx(LoadingSpinner, {});
    if (open) {
        return (_jsx(AdjudicationAreaModal, { adjudicationAreas: adjudicationAreasData, selectedAreasGroupedByState: selectedAreasGroupedByState, onSave: onAdjudicationAreasSelectionSaved, onClose: () => onModalStateChanged(false) }));
    }
    if (error) {
        return (_jsx(Alert, { id: "adjudication-areas-loading-error", status: "error", title: "Error retrieving Adjudication Areas" }));
    }
    if (displayAddButton) {
        return (_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnButton, { onClick: () => onModalStateChanged(true), buttonText: "Add Adjudication", type: "button", fullWidth: true, rounded: "all", size: "lg" }) })));
    }
    if (!error) {
        return (_jsx("div", Object.assign({ className: "w-full mx-auto max-w-4xl flex flex-col gap-2" }, { children: _jsx(AdjudicationGroupingArea, { selectedAreasGroupedByState: selectedAreasGroupedByState }) })));
    }
    return null;
}
