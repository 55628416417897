var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SnapshotModel, getModelFromRef } from '..';
import { ORDER_OBITUARY_VERIFIED } from '../../types/events';
import { Collections } from '../../constants';
import { isIndividualOrder } from '../../types/order';
import { getOrThrow } from '../../utils/refs';
import { OrderModel } from './orderModel';
export class ObituaryModel extends SnapshotModel {
    get type() {
        return Collections.obituaries;
    }
    get isVerified() {
        const { deathVerification } = this.modelData;
        return !!deathVerification.verifiedAt;
    }
    getOrder() {
        return __awaiter(this, void 0, void 0, function* () {
            return getModelFromRef(OrderModel, this.ctx, this.modelData.order);
        });
    }
    setVerificationStatus(isVerified, verificationData) {
        return __awaiter(this, void 0, void 0, function* () {
            const order = yield this.getOrder();
            const verifiedAtValue = isVerified
                ? this.ctx.fieldValue().serverTimestamp()
                : this.ctx.fieldValue().delete();
            if (isIndividualOrder(order.modelData)) {
                yield this.update({
                    'deathVerification.verifiedAt': verifiedAtValue
                });
            }
            else {
                const advertiserOrganizationSnap = yield getOrThrow(order.modelData.advertiserOrganization);
                const advertiserOrganization = advertiserOrganizationSnap.data();
                yield this.update({
                    deathVerification: {
                        funeralHomeName: advertiserOrganization.name,
                        funeralHomePhone: advertiserOrganization.phone,
                        verifiedAt: verifiedAtValue
                    }
                });
            }
            if (isVerified) {
                const { error: eventCreationError } = yield order.createEvent(ORDER_OBITUARY_VERIFIED, Object.assign({}, verificationData));
                if (eventCreationError) {
                    throw eventCreationError;
                }
            }
        });
    }
}
