import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Editor } from '@tinymce/tinymce-react';
import { useRef, useState } from 'react';
import classNames from 'classnames';
import { useValidationChecks } from 'lib/components/TextField/hooks/useValidationChecks';
function MinimalMce({ toolbarItems, onChange, inline = false, menubar = false, value, plugins = ['paste'], contentPadding = 10, placeholder, minHeight = 20, disabled, id, required }) {
    const editorRef = useRef(null);
    const handleChange = (value) => {
        if (!editorRef.current)
            throw Error();
        onChange(value);
    };
    const inputRef = useRef(null);
    const [showErrors, setShowErrors] = useState(false);
    const { currentValidationMessage } = useValidationChecks({
        value,
        inputRef,
        validationMessages: {},
        setShowErrors
    });
    return (_jsxs("div", Object.assign({ id: id, className: classNames('minimal-mce-editor', {
            'text-column-gray-300': disabled
        }) }, { children: [_jsx("input", { ref: inputRef, hidden: true, required: required, value: value, onChange: () => { } }), _jsx(Editor, { tinymceScriptSrc: "/static/js/tinymce/tinymce.min.js", onInit: (evt, editor) => {
                    editorRef.current = editor;
                }, onEditorChange: handleChange, value: value, toolbar: toolbarItems.map(o => o.join(' ')).join(' | '), inline: inline, init: {
                    menubar,
                    fixed_toolbar_container: '#custom-toolbar-container',
                    paste_as_text: true,
                    placeholder
                }, plugins: plugins, disabled: disabled }), showErrors && currentValidationMessage && (_jsx("span", Object.assign({ className: "text-xs text-column-red-600" }, { children: currentValidationMessage }))), _jsx("style", { children: `
      #${id}.minimal-mce-editor > .mce-content-body {
        padding: ${contentPadding}px;
        min-height: ${minHeight}px;
      }

      /* This makes sure that placeholder formatting is working correctly */
      #${id}.minimal-mce-editor > .mce-content-body::before {
        margin-left: ${contentPadding}px;
        min-height: ${minHeight}px;
      }
      ` })] })));
}
export default MinimalMce;
