var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import api from 'api';
import { logAndCaptureCriticalError } from 'utils';
import { ColumnService } from 'lib/services/directory';
import { useLoading } from 'lib/components/hooks/useLoading';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
export const useCoupons = ({ orderModel, version, onCouponUpdate, shouldDelayFetchCoupons }) => {
    const [error, setError] = useState('');
    const [loading, runWithLoading] = useLoading();
    const { value: coupons, isLoading: couponLoading, invalidateData } = useAsyncEffect({
        fetchData: () => __awaiter(void 0, void 0, void 0, function* () {
            const { response: orderDetail, error: orderDetailError } = yield orderModel.getOrCreateOrderDetail(version);
            if (orderDetailError) {
                setError('Failed to fetch order detail');
                throw orderDetailError;
            }
            const { response: fetchedCoupons, error: couponFetchError } = yield orderDetail.fetchAppliedCoupons();
            if (couponFetchError) {
                setError('Failed to fetch applied coupons');
                throw couponFetchError;
            }
            return fetchedCoupons;
        }),
        dependencies: [orderModel.id, version, shouldDelayFetchCoupons],
        initialData: null,
        errorConfig: {
            service: ColumnService.PAYMENTS,
            message: 'Error fetching coupons'
        }
    });
    const addCoupon = (couponCode) => __awaiter(void 0, void 0, void 0, function* () {
        yield runWithLoading(() => __awaiter(void 0, void 0, void 0, function* () {
            setError('');
            if (!couponCode || couponCode === '') {
                setError('Please enter a coupon code');
                return;
            }
            if (coupons === null || coupons === void 0 ? void 0 : coupons.some(coupon => coupon.modelData.code === couponCode)) {
                setError('Coupon has already been applied');
                return;
            }
            const { error: couponError } = yield api.safePost('orders/apply-coupon', {
                couponCode,
                orderId: orderModel.id,
                version
            });
            if (couponError) {
                if (couponError === 'Coupon not found') {
                    setError(`Coupon code '${couponCode}' not found`);
                    return;
                }
                logAndCaptureCriticalError(ColumnService.PAYMENTS, couponError, 'Error applying coupon', { couponCode, orderId: orderModel.id, version: version.toString() });
                setError('Failed to apply coupon. Please try again.');
                return;
            }
            invalidateData();
            yield onCouponUpdate();
        }));
    });
    const removeCoupon = (couponCode) => __awaiter(void 0, void 0, void 0, function* () {
        yield runWithLoading(() => __awaiter(void 0, void 0, void 0, function* () {
            setError('');
            const { error: couponError } = yield api.safePost('orders/remove-coupon', {
                couponCode,
                orderId: orderModel.id,
                version
            });
            if (couponError) {
                logAndCaptureCriticalError(ColumnService.PAYMENTS, couponError, 'Error removing coupon', { couponCode, orderId: orderModel.id, version: version.toString() });
                setError('Failed to remove coupon. Please try again.');
                return;
            }
            invalidateData();
            yield onCouponUpdate();
        }));
    });
    return {
        coupons,
        addCoupon,
        removeCoupon,
        loading: couponLoading || loading,
        error
    };
};
