var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { MIN_LAST_NAME_LENGTH } from 'routes/register/user/RegisterUserForm';
import { State } from 'lib/enums';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { ColumnButton } from 'lib/components/ColumnButton';
import { TextField } from 'lib/components/TextField';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { Form } from 'lib/components/Form';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import ToastActions from 'redux/toast';
import { useAppDispatch } from 'redux/hooks';
import ChangePassword from './ChangePassword';
import SettingsHeader from '../SettingsHeader';
import ChangeEmail from './ChangeEmail';
import { updateUserInfoSettings } from '../settingsActions';
export default function SettingsForm({ user, userAuth, isPublisher }) {
    var _a, _b;
    const dispatch = useAppDispatch();
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [changePassword, setChangePassword] = useState(false);
    const [changeEmail, setChangeEmail] = useState(false);
    const edited = !columnObjectsAreEqual(userData, user.data());
    const providerId = (_a = userAuth.providerData[0]) === null || _a === void 0 ? void 0 : _a.providerId;
    const updateField = (field, value) => {
        if (field === 'zipCode' && value.length > 5)
            return;
        setUserData(Object.assign(Object.assign({}, userData), { [field]: value || '' }));
    };
    useEffect(() => {
        setUserData(user.data());
    }, [user.id]);
    return (_jsxs("div", Object.assign({ className: "bg-white sm:rounded-lg border shadow mb-4" }, { children: [_jsxs(Form, Object.assign({ onSubmit: (e) => __awaiter(this, void 0, void 0, function* () {
                    e.preventDefault();
                    if (Object.keys(errors).length > 0)
                        return;
                    setLoading(true);
                    yield dispatch(updateUserInfoSettings({ user, updateData: userData }));
                    setLoading(false);
                }) }, { children: [_jsx(SettingsHeader, Object.assign({ header: "User Info", description: "Tell us a bit more about yourself." }, { children: _jsx(ColumnButton, { disabled: loading || !edited, loading: loading, buttonText: "Save", type: "submit", id: "submit", size: "lg", primary: true }) })), _jsxs("div", Object.assign({ className: "grid gap-x-8 gap-y-6 md:grid-cols-2 overflow-visible bg-white border border-column-gray-100 p-12 rounded-md shadow-column-3 m-6" }, { children: [_jsx(TextField, { id: "firstName", value: userData.firstName, onChange: value => updateField('firstName', value), placeholder: "First Name", autoComplete: "on", required: true, labelText: "First name" }), _jsx(TextField, { id: "lastName", value: userData.lastName, onChange: value => {
                                    updateField('lastName', value);
                                    const error = value.length < MIN_LAST_NAME_LENGTH
                                        ? `Must be at least ${MIN_LAST_NAME_LENGTH} characters`
                                        : value.length > 30
                                            ? 'Cannot exceed 30 characters'
                                            : undefined;
                                    if (error)
                                        setErrors(Object.assign(Object.assign({}, errors), { last_name: error }));
                                    else {
                                        const newErrors = errors;
                                        delete newErrors.last_name;
                                        setErrors(newErrors);
                                    }
                                }, placeholder: "Last Name", autoComplete: "on", required: true, labelText: "Last name", errorText: errors.last_name }), !(userData === null || userData === void 0 ? void 0 : userData.organization) && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "col-span-2" }, { children: _jsx(TextField, { id: "organization", value: userData.organizationName, placeholder: 'Organization Name', labelText: "Organization Name", onChange: orgName => updateField('organizationName', orgName) }) })), _jsx(TextField, { id: "address", required: true, autoComplete: "on", value: userData.address, onChange: address => updateField('address', address), placeholder: "Address *", labelText: "Address line 1" }), _jsx(TextField, { id: "address2", autoComplete: "on", value: userData.addressLine2, onChange: address2 => updateField('addressLine2', address2), placeholder: "Address Line 2", labelText: "Address line 2" }), _jsx(TextField, { id: "city", required: true, autoComplete: "on", value: userData.city, onChange: city => updateField('city', city), placeholder: "City *", labelText: "City" }), _jsx(ColumnSelect, { id: "state", labelText: "State", required: true, onChange: value => {
                                            setUserData(Object.assign(Object.assign({}, userData), { state: parseInt(value, 10) }));
                                        }, placeholder: "State *", allowUndefined: true, options: State.items().map(state => ({
                                            value: state.value.toString(),
                                            label: state.label
                                        })), value: ((_b = userData.state) === null || _b === void 0 ? void 0 : _b.toString()) || '' }), _jsx(TextField, { id: "zipCode", required: true, placeholder: "Zip*", type: "postal-code", labelText: "Zip Code", value: userData.zipCode, onChange: zipCode => updateField('zipCode', zipCode) }), _jsx(TextField, { id: "phone", required: true, labelText: "Phone", type: "tel", placeholder: "(000) 000-0000", value: userData.phone, onChange: phone => updateField('phone', phone) })] })), _jsxs("div", { children: [_jsx(TextField, { id: "email", type: "email", value: userData.email, disabled: true, placeholder: "Email", labelText: "Email" }), !isPublisher && (_jsx("div", Object.assign({ className: "flex justify-end mt-2" }, { children: _jsx(ColumnButton, { id: "change-email", type: "button", buttonText: 'Change Email', endIcon: _jsx(PencilSquareIcon, { className: "ml-2 h-5 w-5" }), onClick: () => {
                                                if (providerId !== 'password') {
                                                    dispatch(ToastActions.toastError({
                                                        headerText: 'Your request cannot be completed.',
                                                        bodyText: `Your email is linked with a Google or Microsoft account. Please contact us at help@column.us to change your account's email address.`
                                                    }));
                                                }
                                                else {
                                                    setChangeEmail(true);
                                                }
                                            }, secondary: true, link: true }) })))] }), _jsxs("div", { children: [_jsx(TextField, { id: "password", placeholder: "\u2022\u2022\u2022\u2022\u2022\u2022", disabled: true, labelText: "Password" }), _jsx("div", Object.assign({ className: "flex justify-end mt-2" }, { children: _jsx(ColumnButton, { id: "change-password", type: "button", buttonText: 'Change Password', onClick: () => setChangePassword(true), endIcon: _jsx(PencilSquareIcon, { className: "ml-2 h-5 w-5" }), secondary: true, link: true }) }))] })] }))] })), changePassword && (_jsx(ChangePassword, { user: user, userAuth: userAuth, closeChangePasswordModal: () => {
                    setChangePassword(false);
                } })), changeEmail && (_jsx(ChangeEmail, { user: user, userAuth: userAuth, onCloseChangeEmailModal: () => {
                    setChangeEmail(false);
                } }))] })));
}
