import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useMemo } from 'react';
import { getWordsFromNumber } from 'lib/helpers';
import { getColumnRangeConfigForPublisher } from 'lib/notice/columns';
import { capitalize } from 'lodash';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { TextField } from 'lib/components/TextField';
import { AffinityXSyncStatus } from './helpers';
function AffinityXSyncInputs({ approxHeight, maxHeight, setApproxHeight, setHeightIsValid, numColumns, setNumColumns, pageCount, setPageCount, newspaperSnap, syncStatus }) {
    const disableInputs = syncStatus === AffinityXSyncStatus.SYNC_CANCELLED_EDIT_REQUIRED;
    // TODO: maybe combine with logic used in placement flow?
    const getColumnOptionLabel = (numOfColumns) => {
        if (numOfColumns === 1)
            return 'One column';
        return `${capitalize(getWordsFromNumber(numOfColumns))} columns`;
    };
    const getColumnWidthOptions = () => {
        const { minColumns, maxColumns } = getColumnRangeConfigForPublisher(newspaperSnap, 
        /* isDisplay = */ true);
        const options = [];
        for (let i = minColumns; i <= maxColumns; i++) {
            options.push({
                value: `${i}`,
                label: getColumnOptionLabel(i)
            });
        }
        return options;
    };
    const columnWidthOptions = useMemo(getColumnWidthOptions, [newspaperSnap.id]);
    const getPageCountOptions = () => {
        const options = [];
        for (let i = 1; i <= 50; i++) {
            options.push({
                value: `${i}`,
                label: `${i}`
            });
        }
        return options;
    };
    const pageCountOptions = useMemo(getPageCountOptions, [newspaperSnap.id]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "flex flex-row gap-3 mb-4 w-full" }, { children: [_jsx("div", Object.assign({ className: "flex-1" }, { children: _jsx(ColumnSelect, { id: "affinity-x-column-select", labelText: "", options: columnWidthOptions, value: String(numColumns || ''), allowUndefined: true, onChange: newNumColumns => setNumColumns(parseInt(newNumColumns, 10)), placeholder: "Column width", disabled: disableInputs }) })), _jsx("div", Object.assign({ className: "flex-1" }, { children: _jsx(TextField, { id: "affinity-x-height-input", labelText: "", placeholder: "Height (inches)", type: "number", step: "0.001", min: "0.000", max: String(maxHeight || ''), value: String(approxHeight || ''), onChange: newHeight => setApproxHeight(parseFloat(newHeight)), disabled: disableInputs, setValidityTracker: setHeightIsValid }) }))] })), _jsx("div", Object.assign({ className: "flex flex-row gap-3 mb-4 w-full" }, { children: _jsx("div", Object.assign({ className: "flex-1" }, { children: _jsx(ColumnSelect, { id: "affinity-x-page-select", labelText: "", options: pageCountOptions, value: String(pageCount || ''), allowUndefined: true, onChange: newPageCount => setPageCount(parseInt(newPageCount, 10)), placeholder: "Page Count", disabled: disableInputs }) })) }))] }));
}
export default AffinityXSyncInputs;
