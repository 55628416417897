import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
export function DataTableRowHeader({ children, condensed, alignment = 'left', isTotal = false }) {
    return (_jsx("th", Object.assign({ scope: "row", className: classNames('pl-3 pr-1.5 font-medium', {
            'py-2': !condensed,
            'py-1.5': condensed,
            'text-left': alignment === 'left',
            'text-right': alignment === 'right',
            'font-bold': isTotal
        }) }, { children: children })));
}
