import { jsx as _jsx } from "react/jsx-runtime";
import { ColumnButton } from 'lib/components/ColumnButton';
import { getFirebaseContext } from 'utils/firebase';
const getLabelFromArea = (area) => {
    return `[${area.parentname}] ${area.name}`;
};
function AdjudicationAreaOption({ area, onOptionClicked }) {
    const areaLabel = getLabelFromArea(area);
    return (_jsx("div", Object.assign({ className: "bg-white p-1" }, { children: _jsx(ColumnButton, { type: "button", buttonText: areaLabel, onClick: () => {
                const adjArea = {
                    id: area.id,
                    name: area.name,
                    fipsCode: area.fipscode,
                    parent: area.parentid
                        ? getFirebaseContext().adjudicationAreasRef().doc(area.parentid)
                        : null,
                    type: area.type
                };
                onOptionClicked(adjArea);
            } }, area.id) })));
}
export function ShowAdjudicationAreaOptions({ adjudicationAreas, onOptionClicked }) {
    return (_jsx("div", Object.assign({ className: "h-40" }, { children: _jsx("ul", { children: (adjudicationAreas || []).map(area => (_jsx(AdjudicationAreaOption, { area: area, onOptionClicked: onOptionClicked }, area.id))) }) })));
}
