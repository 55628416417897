import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import classNames from 'classnames';
import ToastActions, { toastSelector } from 'redux/toast';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
/**
 * This component should never be accessed directly but instead should be used via the GlobalToast component
 * dispatched from Redux actions
 *
 * For example, here is how you would fire a toast from a ColumnButton
 *
 * function SampleComponent() {
 *   const dispatch = useAppDispatch();
 *   return (
 *     <ColumnButton
 *       onClick={() => {
 *         dispatch(ToastActions.toastSuccess('Success', 'Button clicked'));
 *       }}
 *     />
 *   );
 * }
 *
 */
function Toast({ headerText, status, bodyText, position = 'left', onClose }) {
    const wrapperClasses = classNames('z-modal absolute bottom-0 flex bg-white rounded-lg shadow-xl m-8 p-6 max-w-lg border border-column-gray-100', {
        'left-0': position === 'left',
        'right-0': position === 'right'
    });
    const iconClasses = classNames('p-3 rounded-full self-start', {
        'bg-column-green-50 text-column-green-400': status === 'success',
        'bg-column-red-50 text-column-red-600': status === 'error',
        'bg-column-yellow-50 text-column-yellow-600': status === 'warning',
        'bg-primary-100 text-primary-500': status === 'info',
        'bg-column-gray-25 text-column-gray-400': !status
    });
    const icon = {
        success: _jsx(CheckCircleIcon, { className: "h-6 w-6" }),
        error: _jsx(XMarkIcon, { className: "h-6 w-6" }),
        warning: _jsx(XMarkIcon, { className: "h-6 w-6" }),
        info: _jsx(CheckCircleIcon, { className: "h-6 w-6" })
    }[status || 'info'];
    return (_jsxs("div", Object.assign({ className: wrapperClasses, role: "dialog", "aria-modal": "true", "aria-labelledby": "toast-headline" }, { children: [_jsx("div", Object.assign({ className: iconClasses }, { children: icon })), _jsxs("div", Object.assign({ className: "mx-4 font-medium" }, { children: [_jsx("p", Object.assign({ id: "toast-headline", className: "text-base leading-6 text-column-gray-500" }, { children: headerText })), _jsx("p", Object.assign({ className: "text-sm text-column-gray-500 py-2" }, { children: bodyText }))] })), _jsxs("button", Object.assign({ onClick: () => onClose(), className: "self-start hover:text-column-gray-500 text-column-gray-400" }, { children: [_jsx("span", Object.assign({ className: "sr-only" }, { children: "Close" })), _jsx(XMarkIcon, { className: "h-5 w-5" })] }))] })));
}
/**
 * Global toast component that displays a toast message at the bottom of the screen.
 */
export default function GlobalToast() {
    const dispatch = useAppDispatch();
    const toastValue = useAppSelector(toastSelector);
    useEffect(() => {
        if (toastValue.bodyText) {
            const timeout = setTimeout(() => {
                dispatch(ToastActions.clearToast());
            }, toastValue.timeout);
            return () => clearTimeout(timeout);
        }
    }, [toastValue.bodyText]);
    if (!toastValue.bodyText)
        return null;
    return (_jsx(Toast, { bodyText: toastValue.bodyText, headerText: toastValue.headerText, status: toastValue.status, onClose: () => dispatch(ToastActions.clearToast()) }));
}
