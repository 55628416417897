import { getClosestFuturePublishingDay } from 'lib/utils/deadlines';
import moment from 'moment';
const getNextPublishingDate = (newspaper, publishingSetting, date) => {
    const { deadlines, deadlineOverrides } = publishingSetting.modelData;
    const { iana_timezone: newspaperTimezone } = newspaper.data();
    const nextPotentialPublishingDate = moment(date).add(1, 'days').toDate();
    const nextPublishingDate = getClosestFuturePublishingDay(deadlines || [], deadlineOverrides || {}, newspaperTimezone, {}, newspaper, nextPotentialPublishingDate);
    return moment(nextPublishingDate).format('YYYY-MM-DD');
};
export default getNextPublishingDate;
