var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Product } from 'lib/enums';
import { PublicationIssueStatus } from 'lib/types/publicationIssue';
import moment from 'moment-timezone/moment-timezone';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { exists } from 'lib/types';
import { logAndCaptureMessage } from 'utils';
import { getInheritedProperty } from 'lib/utils/inheritance';
export const ALL_PUBLICATION_DATES = 'All publication dates';
export const TODAY = 'Today';
export const TOMORROW = 'Tomorrow';
export const TODAY_AND_TOMORROW = 'Today and tomorrow';
export const PAGINATION_ISSUE_DATE_FILTERS = [
    ALL_PUBLICATION_DATES,
    TODAY,
    TOMORROW,
    TODAY_AND_TOMORROW
];
export const DEFAULT_PAGINATION_ISSUE_FILTER = {
    deadline: undefined,
    publicationDate: undefined
};
export const PublicationIssueStatusLabels = {
    [PublicationIssueStatus.DISABLED]: 'Disabled',
    [PublicationIssueStatus.PENDING]: 'Awaiting Review',
    [PublicationIssueStatus.CHANGES_REQUESTED]: 'Changes Requested',
    [PublicationIssueStatus.READY_FOR_PAGINATION]: 'Ready for Pagination',
    [PublicationIssueStatus.AWAITING_APPROVAL]: 'Awaiting Approval',
    [PublicationIssueStatus.APPROVED]: 'Approved',
    [PublicationIssueStatus.PRINT_READY]: 'Print Ready',
    [PublicationIssueStatus.ARCHIVED]: 'Archived'
};
const getStartAndEndDatesFromIssueTableFilterOption = (filter) => {
    // TODO does timezone matter for this
    if (filter === TODAY) {
        return {
            from: moment().startOf('day').toDate(),
            to: moment().endOf('day').toDate()
        };
    }
    if (filter === TOMORROW) {
        return {
            from: moment().add(1, 'day').startOf('day').toDate(),
            to: moment().add(1, 'day').endOf('day').toDate()
        };
    }
    if (filter === TODAY_AND_TOMORROW) {
        return {
            from: moment().startOf('day').toDate(),
            to: moment().add(1, 'day').endOf('day').toDate()
        };
    }
    return { from: undefined, to: undefined };
};
export const getDatesForIssueQueryFromTableFilter = (filter) => {
    const { deadline, publicationDate } = filter;
    const deadlineStartAndEnd = getStartAndEndDatesFromIssueTableFilterOption(deadline);
    const publicationDateStartAndEnd = getStartAndEndDatesFromIssueTableFilterOption(publicationDate);
    return {
        deadlineTimestampFrom: deadlineStartAndEnd.from,
        deadlineTimestampTo: deadlineStartAndEnd.to,
        publicationDateFrom: publicationDateStartAndEnd.from
            ? moment(publicationDateStartAndEnd.from).format('YYYY-MM-DD')
            : undefined,
        publicationDateTo: publicationDateStartAndEnd.to
            ? moment(publicationDateStartAndEnd.to).format('YYYY-MM-DD')
            : undefined
    };
};
export const filterHasChanges = ({ deadline: previousDeadline, publicationDate: previousPublicationDate }, { deadline: newDeadline, publicationDate: newPublicationDate }) => {
    return (previousDeadline !== newDeadline ||
        previousPublicationDate !== newPublicationDate);
};
export const numberOfChanges = (filter) => {
    let numFilters = 0;
    if (filter.deadline !== DEFAULT_PAGINATION_ISSUE_FILTER.deadline) {
        ++numFilters;
    }
    if (filter.publicationDate !== DEFAULT_PAGINATION_ISSUE_FILTER.publicationDate) {
        ++numFilters;
    }
    return numFilters;
};
export const PENDING_TAB = {
    label: 'Upcoming',
    enabled: true,
    id: 'pending-tab'
};
export const READY_FOR_PAGINATION_TAB = {
    label: 'Ready for Pagination',
    enabled: true,
    id: 'ready-for-pagination-tab'
};
export const AWAITING_APPROVAL_TAB = {
    label: 'Awaiting Approval',
    enabled: true,
    id: 'awaiting-approval-tab'
};
export const APPROVED_TAB = {
    label: 'Approved',
    enabled: true,
    id: 'approved-tab'
};
export const PRINT_READY_TAB = {
    label: 'Print Ready',
    enabled: true,
    id: 'print-ready-tab'
};
export const ARCHIVED_TAB = {
    label: 'Archived',
    enabled: true,
    id: 'archived-tab'
};
export const DISABLED_TAB = {
    label: 'Disabled',
    enabled: true,
    id: 'disabled-tab'
};
export const ADVANCED_PAGINATION_TABS = [
    PENDING_TAB,
    READY_FOR_PAGINATION_TAB,
    AWAITING_APPROVAL_TAB,
    APPROVED_TAB,
    PRINT_READY_TAB,
    ARCHIVED_TAB
];
export const SIMPLIFIED_PAGINATION_TABS = [
    PENDING_TAB,
    APPROVED_TAB,
    ARCHIVED_TAB
];
export const PAGINATION_TABS_TO_STATUSES = {
    [PENDING_TAB.id]: [PublicationIssueStatus.PENDING],
    [READY_FOR_PAGINATION_TAB.id]: [
        PublicationIssueStatus.READY_FOR_PAGINATION,
        PublicationIssueStatus.CHANGES_REQUESTED
    ],
    [AWAITING_APPROVAL_TAB.id]: [PublicationIssueStatus.AWAITING_APPROVAL],
    [APPROVED_TAB.id]: [PublicationIssueStatus.APPROVED],
    [PRINT_READY_TAB.id]: [PublicationIssueStatus.PRINT_READY],
    [ARCHIVED_TAB.id]: [PublicationIssueStatus.ARCHIVED],
    [DISABLED_TAB.id]: [PublicationIssueStatus.DISABLED]
};
export const getAdvancedPaginationOnForProduct = (product, organization) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    const planSettings = yield getInheritedProperty(organization.ref, 'planSettings');
    switch (product) {
        case Product.Notice:
            return !!((_a = planSettings === null || planSettings === void 0 ? void 0 : planSettings.features) === null || _a === void 0 ? void 0 : _a.customPagination);
        case Product.Obituary:
            return !!((_b = planSettings === null || planSettings === void 0 ? void 0 : planSettings.features) === null || _b === void 0 ? void 0 : _b.customPaginationObits);
        case Product.Classified:
            return !!((_c = planSettings === null || planSettings === void 0 ? void 0 : planSettings.features) === null || _c === void 0 ? void 0 : _c.customPaginationClassifieds);
        default:
            logAndCaptureMessage('Unknown product determining pagination settings', {
                product,
                organizationId: organization.id
            });
            return false;
    }
});
export const getShouldUseAdvancedPagination = (publisher, product) => __awaiter(void 0, void 0, void 0, function* () {
    /**
     * TODO(Ari): Once we implement the "simplified" version of the
     * pagination table for notices, we should not return true automatically
     * here.
     */
    const advancedPaginationEnabled = product === Product.Notice
        ? true
        : getBooleanFlag(LaunchDarklyFlags.ENABLE_ADVANCED_PAGINATION, false);
    const orgHasAdvancedPaginationEnabledForProduct = exists(publisher) &&
        (yield getAdvancedPaginationOnForProduct(product, publisher));
    return advancedPaginationEnabled && orgHasAdvancedPaginationEnabledForProduct;
});
export const getTableHeaderText = (tabId, product) => {
    switch (tabId) {
        case PENDING_TAB.id:
            return {
                title: PENDING_TAB.label,
                subtitle: product === Product.Notice
                    ? 'Track all publication issues awaiting review for invoice creation. Issues with zero notices will not be shown here as they are auto-approved.'
                    : 'Track all publication issues awaiting review. Issues with orders will not be shown here as they are auto-approved.'
            };
        case READY_FOR_PAGINATION_TAB.id:
            return {
                title: READY_FOR_PAGINATION_TAB.label,
                subtitle: 'Track all files pending pagination.'
            };
        case AWAITING_APPROVAL_TAB.id:
            return {
                title: AWAITING_APPROVAL_TAB.label,
                subtitle: 'Track all files awaiting approval with active publication dates.'
            };
        case APPROVED_TAB.id:
            return {
                title: APPROVED_TAB.label,
                subtitle: 'Track all approved files with active publication dates.'
            };
        case PRINT_READY_TAB.id:
            return {
                title: PRINT_READY_TAB.label,
                subtitle: 'Track all files ready to print.'
            };
        case ARCHIVED_TAB.id:
            return {
                title: ARCHIVED_TAB.label,
                subtitle: 'Track all approved files with publication dates in the past.'
            };
        default:
            return {
                title: DISABLED_TAB.label,
                subtitle: 'This tab is visible to Column users only.'
            };
    }
};
/**
 * Sort by deadlineTimestamp asc in the pagination table
 * Future TODO: may make this sort configurable through UI
 */
export const comparePublicationIssuesSort = (pubIssue1, pubIssue2, paginationTab) => {
    var _a, _b;
    const deadline1 = ((_a = pubIssue1.section) === null || _a === void 0 ? void 0 : _a.modelData.deadlineTimestamp) ||
        pubIssue1.publicationIssue.modelData.deadlineTimestamp;
    const deadline2 = ((_b = pubIssue2.section) === null || _b === void 0 ? void 0 : _b.modelData.deadlineTimestamp) ||
        pubIssue2.publicationIssue.modelData.deadlineTimestamp;
    if (!deadline1) {
        return 1;
    }
    if (!deadline2) {
        return -1;
    }
    // show the latest deadline in the archived tab, show the earliest in all others
    if (paginationTab === ARCHIVED_TAB) {
        return deadline2.toMillis() - deadline1.toMillis();
    }
    return deadline1.toMillis() - deadline2.toMillis();
};
