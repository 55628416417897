import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { capitalize } from 'lodash';
import NewspaperOrgImage from '../../components/PublisherImage';
function PublisherCard({ newspaper, validPublishingMediums, publishingMediums, onPublishingMediumsChange }) {
    return (_jsxs("div", Object.assign({ className: "w-full bg-white border border-column-gray-200 rounded-md p-4" }, { children: [_jsx("div", Object.assign({ className: classNames('flex items-center h-9 max-w-36') }, { children: _jsx(NewspaperOrgImage, { newspaper: newspaper, className: "max-h-full" }) })), _jsx("div", Object.assign({ className: "grid gap-4 divide-y divide-column-gray-100" }, { children: validPublishingMediums.map(medium => {
                    const id = `${newspaper.id}-${medium}`;
                    return (_jsxs("label", Object.assign({ htmlFor: id, className: "flex align-vertical pt-4" }, { children: [_jsx("input", { id: id, type: "checkbox", onChange: () => {
                                    onPublishingMediumsChange(medium);
                                }, checked: publishingMediums.includes(medium) }), _jsx("span", Object.assign({ className: "px-2 text-column-gray-500 font-medium text-sm" }, { children: capitalize(medium) }))] }), medium));
                }) }))] })));
}
export default PublisherCard;
