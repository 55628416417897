var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { getErrorReporter } from '../../utils/errors';
import { ColumnService } from '../../services/directory';
export default function useAsyncEffect({ fetchData, dependencies, initialData = null, errorConfig }) {
    const [value, setValue] = useState(initialData);
    const [status, setStatus] = useState('loading');
    const [invalid, setInvalid] = useState(0);
    useEffect(() => {
        let current = true;
        const fetch = () => __awaiter(this, void 0, void 0, function* () {
            setStatus('loading');
            try {
                const data = yield fetchData();
                if (current) {
                    setValue(data);
                    setStatus('idle');
                }
            }
            catch (err) {
                getErrorReporter().logAndCaptureError((errorConfig === null || errorConfig === void 0 ? void 0 : errorConfig.service) || ColumnService.UNKNOWN, err, (errorConfig === null || errorConfig === void 0 ? void 0 : errorConfig.message) || 'Error in useAsyncEffect', errorConfig === null || errorConfig === void 0 ? void 0 : errorConfig.tags);
                if (current) {
                    setStatus('error');
                }
            }
        });
        void fetch();
        return () => {
            current = false;
        };
    }, [...dependencies, invalid]);
    return {
        value,
        isLoading: status === 'loading',
        isError: status === 'error',
        invalidateData: () => setInvalid(curr => curr + 1)
    };
}
