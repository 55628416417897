import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { columnObjectsAreEqual } from 'lib/utils/stringify';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { removeUndefinedFields } from 'lib/helpers';
import { CardGridLayout, GridInput } from 'lib/components/Card/Grid';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization, selectUser } from 'redux/auth';
import { exists } from 'lib/types';
import { userHasPermission } from 'utils/permissions';
import { Permissions } from 'lib/permissions/roles';
import { affidavitsAreManagedByColumn } from 'lib/affidavits';
import { AffidavitTemplateSelect } from '../filingTypes/filingTypeUpdateForm/AffidavitTemplateSelect';
const columnManagesSigningForCustomer = ({ affidavitReconciliationSettings }) => {
    if ((affidavitReconciliationSettings === null || affidavitReconciliationSettings === void 0 ? void 0 : affidavitReconciliationSettings.affidavitsManagedByColumn) === undefined) {
        return 'undefined';
    }
    return affidavitReconciliationSettings.affidavitsManagedByColumn
        ? 'true'
        : 'false';
};
const getColumnManagesSigningOptions = () => {
    return [
        {
            value: 'undefined',
            label: 'Use default setting (Column manages signing)'
        },
        {
            value: 'true',
            label: 'Always have Column sign affidavits for this customer'
        },
        {
            value: 'false',
            label: 'Never have Column sign affidavits for this customer'
        }
    ];
};
const getCustomerWetSignatureRequirementOptions = () => {
    return [
        {
            value: 'undefined',
            label: 'Use default requirement from newspaper'
        },
        {
            value: 'true',
            label: 'Always require wet signatures for this customer'
        },
        {
            value: 'false',
            label: 'Never require wet signatures for this customer'
        }
    ];
};
const getWetSignatureRequirementValueFromCustomer = (customerData) => {
    const { notarizationVendor } = customerData.affidavitReconciliationSettings || {};
    if (notarizationVendor === undefined) {
        return 'undefined';
    }
    return notarizationVendor === 'manual' ? 'true' : 'false';
};
const getCustomerInStateNotaryRequirementOptions = () => {
    return [
        {
            value: 'undefined',
            label: 'Use default requirement from newspaper'
        },
        {
            value: 'true',
            label: 'Always require an in-state notary for this customer'
        },
        {
            value: 'false',
            label: 'Never require an in-state notary for this customer'
        }
    ];
};
const getInStateNotaryRequirementValueFromCustomer = (customerData) => {
    const { requiresInStateNotary } = customerData.affidavitReconciliationSettings || {};
    if (requiresInStateNotary === undefined) {
        return 'undefined';
    }
    return requiresInStateNotary === true ? 'true' : 'false';
};
export default function ColumnManagedAffidavitSettingsCard({ onUpdateCustomer, updatedCustomer }) {
    var _a;
    const user = useAppSelector(selectUser);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const hasPermission = exists(user) &&
        userHasPermission(user, Permissions.SETTINGS_AUTOMATED_AFFIDAVITS);
    const publisherUsesAA = exists(activeOrganization) &&
        affidavitsAreManagedByColumn(activeOrganization);
    if (!hasPermission || !publisherUsesAA) {
        return null;
    }
    return (_jsxs(CardGridLayout, Object.assign({ header: { title: 'Column Managed Affidavit Settings', description: '' } }, { children: [_jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { id: "column-manages-signing", labelText: "Should Column sign affidavits for this customer?", itemsListDescription: "If enabled, affidavits will be included in Column's automated affidavits service.", onChange: newValue => {
                        const newCustomer = Object.assign({}, updatedCustomer);
                        const affidavitsManagedByColumn = newValue === 'undefined' ? undefined : newValue === 'true';
                        const newReconciliationSettings = removeUndefinedFields(Object.assign(Object.assign({}, updatedCustomer.affidavitReconciliationSettings), { affidavitsManagedByColumn, notarizationRequired: affidavitsManagedByColumn }));
                        if (columnObjectsAreEqual(newReconciliationSettings, {})) {
                            delete newCustomer.affidavitReconciliationSettings;
                        }
                        else {
                            newCustomer.affidavitReconciliationSettings = newReconciliationSettings;
                        }
                        onUpdateCustomer(newCustomer);
                    }, options: getColumnManagesSigningOptions(), value: columnManagesSigningForCustomer(updatedCustomer) }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { id: "wet-signature-requirement", labelText: "Should this customer require a wet signature?", onChange: newValue => {
                        const newCustomer = Object.assign({}, updatedCustomer);
                        const notarizationVendor = newValue === 'undefined'
                            ? undefined
                            : newValue === 'true'
                                ? 'manual'
                                : 'notarize';
                        const newReconciliationSettings = removeUndefinedFields(Object.assign(Object.assign({}, updatedCustomer.affidavitReconciliationSettings), { notarizationVendor }));
                        newCustomer.affidavitReconciliationSettings = newReconciliationSettings;
                        onUpdateCustomer(newCustomer);
                    }, options: getCustomerWetSignatureRequirementOptions(), value: getWetSignatureRequirementValueFromCustomer(updatedCustomer) }) })), _jsx(GridInput, Object.assign({ fullWidth: true }, { children: _jsx(ColumnSelect, { id: "wet-signature-requirement", labelText: "Should this customer require an in-state notary?", onChange: newValue => {
                        const newCustomer = Object.assign({}, updatedCustomer);
                        const requiresInStateNotary = newValue === 'undefined' ? undefined : newValue === 'true';
                        const newReconciliationSettings = removeUndefinedFields(Object.assign(Object.assign({}, updatedCustomer.affidavitReconciliationSettings), { requiresInStateNotary }));
                        newCustomer.affidavitReconciliationSettings = newReconciliationSettings;
                        onUpdateCustomer(newCustomer);
                    }, options: getCustomerInStateNotaryRequirementOptions(), value: getInStateNotaryRequirementValueFromCustomer(updatedCustomer) }) })), _jsx(AffidavitTemplateSelect, { labelText: "What affidavit template should we use for Column managed affidavits?", value: (_a = updatedCustomer.affidavitReconciliationSettings) === null || _a === void 0 ? void 0 : _a.managedAffidavitTemplate, onChange: managedAffidavitTemplate => onUpdateCustomer(Object.assign(Object.assign({}, updatedCustomer), { affidavitReconciliationSettings: Object.assign(Object.assign({}, updatedCustomer.affidavitReconciliationSettings), { managedAffidavitTemplate }) })) })] })));
}
