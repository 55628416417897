import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import MultiDateSelector from 'routes/ads/place/components/MultiDateSelector';
import { Alert } from 'lib/components/Alert';
import { InputAccessories } from 'lib/components/InputAccessories';
import moment from 'moment';
import { getDeadlineText } from 'routes/placeScroll/ConfirmScheduleForm';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getDateForDateStringInTimezone } from 'lib/utils/dates';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { Badge } from 'lib/components/Badge';
import { capitalize } from 'lodash';
import NewspaperOrgImage from '../../components/PublisherImage';
import MultiStepHeader from '../../components/MultiStepHeader';
import getNextPublishingDate from './getNextPublishingDate';
function SelectSchedule({ newspaperOrder, newspaper, publishingSetting, onNewspaperOrderChange, product, disableEdits }) {
    var _a;
    const { iana_timezone: newspaperTimezone } = newspaper.data();
    const { deadlines = [], deadlineOverrides = {} } = (_a = publishingSetting.modelData) !== null && _a !== void 0 ? _a : {};
    const [selectedDates, setSelectedDates] = useState(newspaperOrder.publishingDates);
    // This data is used as a temporary prop to leverage our deadline helper functions for notices
    const deadlineConfig = selectedDates.length
        ? getDeadlineText({
            publicationDate: getDateForDateStringInTimezone({
                dayString: selectedDates[0],
                timezone: newspaperTimezone
            }),
            deadlines,
            deadlineOverrides,
            newspaperTimezone,
            newspaper,
            isPublisher: false,
            placement: {}
        })
        : null;
    // update orderData when selectedDates change
    useEffect(() => {
        onNewspaperOrderChange(Object.assign(Object.assign({}, newspaperOrder), { publishingDates: selectedDates }));
    }, [selectedDates]);
    const handleAddMoreDatesClick = () => {
        const nextPublishingDate = getNextPublishingDate(newspaper, publishingSetting, selectedDates.length
            ? moment(selectedDates[selectedDates.length - 1]).toDate()
            : new Date());
        const updatedSelectedDates = [...selectedDates, nextPublishingDate].sort();
        setSelectedDates(updatedSelectedDates);
    };
    const productTypeName = PRODUCT_TO_NAME[product].singular.toLowerCase();
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "w-max" }, { children: _jsx(Badge, { children: capitalize(newspaperOrder.publishingMedium || PublishingMedium.Print) }) })), _jsx(MultiStepHeader, Object.assign({ title: `Schedule Dates for ${newspaper.data().name}`, description: `Provide schedule details to ensure your ${productTypeName} is published correctly.` }, { children: _jsx("div", Object.assign({ className: "hidden md:block max-w-48 py-3" }, { children: _jsx(NewspaperOrgImage, { newspaper: newspaper, className: "w-full" }) })) })), deadlineConfig && (_jsx("div", Object.assign({ className: "pb-4" }, { children: _jsx(Alert, { id: "newspaper-schedule-deadline", title: deadlineConfig.text, status: deadlineConfig.status, icon: _jsx(ExclamationCircleIcon, { className: "h-5 w-5" }) }) }))), _jsx("div", { children: _jsx(InputAccessories, Object.assign({ id: "schedule-ad", labelText: `When should your ${productTypeName} be published?` }, { children: _jsx("div", Object.assign({ className: 'grid grid-cols-2 gap-x-6 gap-y-3' }, { children: _jsx(MultiDateSelector, { selectedDates: selectedDates, onSelectedDatesChange: setSelectedDates, newspaper: newspaper, publishingSetting: publishingSetting, onAddMoreDatesClick: handleAddMoreDatesClick, disabled: disableEdits }) })) })) })] }));
}
export default SelectSchedule;
