import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DataTableRowHeader } from './cells/DataTableRowHeader';
import { DataTableCell } from './cells/DataTableCell';
export function DataTableTotalsRow({ columns, columnAlignment, condensed = false, totals: footers }) {
    var _a;
    if (!footers) {
        return null;
    }
    const [first, ...rest] = columns;
    const firstAlignment = (_a = columnAlignment === null || columnAlignment === void 0 ? void 0 : columnAlignment[first]) !== null && _a !== void 0 ? _a : 'left';
    return (_jsxs("tr", Object.assign({ className: "text-sm font-semibold bg-column-gray-50 border-t border-column-gray-200" }, { children: [!footers[first] && (_jsx(DataTableRowHeader, Object.assign({ alignment: firstAlignment, condensed: condensed, isTotal: true }, { children: "Totals" }))), footers[first] && (_jsx(DataTableCell, Object.assign({ alignment: firstAlignment, condensed: condensed, isTotal: true }, { children: footers[first] }))), rest.map(key => {
                var _a;
                const alignment = columnAlignment === null || columnAlignment === void 0 ? void 0 : columnAlignment[key];
                return (_jsx(DataTableCell, Object.assign({ alignment: alignment, condensed: condensed, isTotal: true }, { children: (_a = footers[key]) !== null && _a !== void 0 ? _a : '' }), key));
            })] })));
}
