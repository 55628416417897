var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BadRequestError, NotFoundError } from '../../errors/ColumnErrors';
import { wrapError, wrapSuccess } from '../../types/responses';
import { SnapshotModel } from '..';
import { Collections } from '../../constants';
import { safeGetModelArrayFromRefs } from '../getModel';
import { CouponModel } from './couponModel';
import { safeUpdateModel } from '../safeHandlers';
import { getErrorReporter } from '../../utils/errors';
import { ColumnService } from '../../services/directory';
export class OrderDetailModel extends SnapshotModel {
    get type() {
        return Collections.orderDetails;
    }
    fetchAppliedCoupons() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.modelData.appliedCoupons) {
                return wrapSuccess([]);
            }
            return safeGetModelArrayFromRefs(CouponModel, this.ctx, this.modelData.appliedCoupons);
        });
    }
    isCouponApplied(couponRef) {
        var _a;
        if (!this.modelData.appliedCoupons) {
            return false;
        }
        return (_a = this.modelData.appliedCoupons) === null || _a === void 0 ? void 0 : _a.some(coupon => coupon.id === couponRef.id);
    }
    addCouponToList(couponRef) {
        return __awaiter(this, void 0, void 0, function* () {
            const appliedCoupons = this.modelData.appliedCoupons || [];
            if (appliedCoupons.some(coupon => coupon.id === couponRef.id)) {
                const err = new BadRequestError('Coupon already in list');
                getErrorReporter().logAndCaptureError(ColumnService.PAYMENTS, err, 'Failed to add coupon to list', {
                    couponId: couponRef.id
                });
                return wrapError(err);
            }
            appliedCoupons.push(couponRef);
            const { error } = yield safeUpdateModel(this, { appliedCoupons });
            if (error) {
                return wrapError(error);
            }
            return wrapSuccess(undefined);
        });
    }
    removeCouponFromList(couponRef) {
        return __awaiter(this, void 0, void 0, function* () {
            const appliedCoupons = this.modelData.appliedCoupons || [];
            if (!appliedCoupons.some(coupon => coupon.id === couponRef.id)) {
                const err = new NotFoundError('Coupon not found in list');
                getErrorReporter().logAndCaptureError(ColumnService.PAYMENTS, err, 'Failed to remove coupon from list', {
                    couponId: couponRef.id
                });
                return wrapError(err);
            }
            const newAppliedCoupons = appliedCoupons.filter(coupon => coupon.id !== couponRef.id);
            const { error } = yield safeUpdateModel(this, {
                appliedCoupons: newAppliedCoupons
            });
            if (error) {
                return wrapError(error);
            }
            return wrapSuccess(undefined);
        });
    }
}
