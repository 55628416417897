import { selectHasClassifiedsActive, selectHasObituariesActive, selectHasPublicNoticesActive } from 'redux/auth';
import { createSelector } from 'reselect';
export const selectOrdersTabItems = createSelector([
    selectHasPublicNoticesActive,
    selectHasObituariesActive,
    selectHasClassifiedsActive
], (publicNoticesOrdersEnabled, obituariesOrdersEnabled, classifiedsOrdersEnabled) => [
    {
        id: 'public-notices',
        path: '/notices',
        label: 'Public Notices',
        enabled: publicNoticesOrdersEnabled
    },
    {
        id: 'obits',
        path: '/obituaries',
        label: 'Obituaries',
        enabled: obituariesOrdersEnabled
    },
    {
        id: 'classifieds',
        path: '/classifieds',
        label: 'Classifieds',
        enabled: classifiedsOrdersEnabled
    }
]);
