var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { OrganizationType } from 'lib/enums';
import { removeUndefinedFields } from 'lib/helpers';
import { ObituaryService } from 'lib/services/obituaryService';
import { getFirebaseContext } from 'utils/firebase';
import { isObituaryModel } from 'lib/model/objects/adModel';
import { wrapError, wrapSuccess } from 'lib/types/responses';
import { getErrorReporter } from 'lib/utils/errors';
import { ColumnService } from 'lib/services/directory';
import { getModelFromRef } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
import { MINIMUM_ORDER } from '../../steps/PersonalDetail';
class ObituaryPlacementFlowHelper {
    constructor() {
        this.context = getFirebaseContext();
        this.obituaryService = new ObituaryService(this.context);
    }
    createAd(user) {
        return __awaiter(this, void 0, void 0, function* () {
            const orgRef = user.data().activeOrganization;
            if (!orgRef) {
                return wrapError(new Error(`User must have an active organization to create an obituary: ${user.id}`));
            }
            const activeOrganizationModel = yield getModelFromRef(OrganizationModel, getFirebaseContext(), orgRef);
            const extraInfo = activeOrganizationModel &&
                activeOrganizationModel.modelData.organizationType ===
                    OrganizationType.funeral_home.value
                ? {
                    advertiserOrganization: activeOrganizationModel.ref,
                    advertiser: user.ref
                }
                : Object.assign({}, MINIMUM_ORDER);
            const obituaryModel = yield this.obituaryService.create(removeUndefinedFields(Object.assign({ user: user.ref, authorizedOrganization: activeOrganizationModel.ref }, extraInfo)));
            return wrapSuccess(obituaryModel.modelData.order);
        });
    }
    getAdModel(orderModel) {
        return __awaiter(this, void 0, void 0, function* () {
            const adResult = yield orderModel.getAdByVersion();
            if (adResult.error) {
                return adResult;
            }
            const adModel = adResult.response;
            if (!isObituaryModel(adModel)) {
                const errorMessage = 'Not an obituary';
                const error = Error(errorMessage);
                getErrorReporter().logAndCaptureError(ColumnService.OBITS, error, errorMessage, { orderId: orderModel.id });
                return wrapError(error);
            }
            return wrapSuccess(adModel);
        });
    }
}
export default ObituaryPlacementFlowHelper;
