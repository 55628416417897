import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { DataTableBody } from './DataTableBody';
import { DataTableCaption } from './DataTableCaption';
import { DataTableHeader } from './DataTableHeader';
import { DataTablePagination } from './DataTablePagination';
import { DataTableTotalsRow } from './DataTableTotalsRow';
/**
 * Data tables are used to organize and display all information from a data set.
 * While a data visualization represents part of data set, a data table lets
 * publishers view details from the entire set. This helps publishers and
 * internal teams compare and analyze the data.
 *
 * ## Do's and don'ts
 *
 * ### Do
 * - Show values across multiple categories and measures.
 * - Allow for sorting, filtering, and searching for specific data.
 * - Help users visualize and scan data quickly.
 * - Link to more detailed information, when appropriate.
 * - Include a summary row to surface key insights, like totals or averages.
 *
 * ### Don't
 * - Truncate content in a cell, as it can make the data harder to read.
 * - Add extraneous data or clutter that prevents easy scanning of the data.
 * - Include actions or interactive elements in the table itself.
 * - Include calculations within the summary row.
 *
 * ## Content guidelines
 *
 * ### Alignment
 * - Numerical data should be right-aligned.
 * - Text data should be left-aligned.
 * - Do not center align data.
 *
 * ### Column headers:
 * - Are informative and descriptive.
 * - Also concise and scannable.
 * - Include units of measurement, to avoid repetition in the data cells.
 * - **Use sentence case (first word capitalized, rest lowercase).**
 *
 * ### Row headers:
 * - Identify the row by some form of unique identifier.
 * - Optionally can link to more detailed information in card view.
 * - **Use sentence case (first word capitalized, rest lowercase).**
 *
 * ### Data cells:
 * - Include only the most relevant data.
 * - Match formatting for their column (e.g., consistent decimal places).
 * - Exclude units of measurement if the column header already includes them.
 * - **Use sentence case (first word capitalized, rest lowercase).**
 *
 * ### Summary row (optional):
 * - Summarize the whole data set, by column, (e.g., a total of numeric data for a given column).
 * - Only appear for columns that are summarizable.
 *
 * ## TODO (Features not yet implemented):
 * - Add keyword search, column filtering
 * - Add sticky header and first column for larger tables
 * - Add mobile view
 */
export function DataTable(props) {
    return (_jsx("div", Object.assign({ className: classNames('relative', { 'mt-8': props.labelText }) }, { children: _jsxs("div", Object.assign({ className: "border border-column-gray-200 rounded-lg overflow-auto shadow" }, { children: [_jsxs("table", Object.assign({ id: props.id, className: "static w-full text-column-gray-500 table-auto" }, { children: [_jsx(DataTableCaption, Object.assign({}, props)), _jsx(DataTableHeader, Object.assign({}, props)), _jsx(DataTableBody, Object.assign({}, props)), props.totalPosition !== 'top' && (_jsx("tfoot", { children: _jsx(DataTableTotalsRow, Object.assign({}, props)) }))] })), _jsx(DataTablePagination, Object.assign({}, props))] })) })));
}
