var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Popover } from 'lib/components/Popover';
import { AutocompleteInput } from './AutocompleteInput';
import { AutocompletePanel } from './AutocompletePanel';
export function Autocomplete(_a) {
    var { id, options, value, onChange, loading, optionsGroups, selectedOptionsValues, showCheckBoxForSelectedItems } = _a, textFieldProps = __rest(_a, ["id", "options", "value", "onChange", "loading", "optionsGroups", "selectedOptionsValues", "showCheckBoxForSelectedItems"]);
    const [query, setQuery] = useState(null);
    const selectedOption = options.find(option => option.value === value);
    const allOptions = optionsGroups
        ? optionsGroups.map(og => og.options).flat()
        : options;
    const selectedOptions = selectedOptionsValues
        ? allOptions.filter(option => selectedOptionsValues.includes(option.value))
        : undefined;
    const filteredOptions = query
        ? allOptions.filter(option => option.label.toLowerCase().includes(query.toLowerCase()))
        : allOptions;
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const highlightedOption = filteredOptions[highlightedIndex];
    useEffect(() => {
        resetQuery();
    }, [selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.label]);
    function onQueryChange(query) {
        setQuery(query);
        setHighlightedIndex(0);
    }
    function selectOption(option) {
        const selectedOptionIndex = allOptions.indexOf(option);
        setHighlightedIndex(selectedOptionIndex);
        onChange(option.value);
        resetQuery();
    }
    function selectHighlightedOption() {
        if (highlightedOption) {
            selectOption(highlightedOption);
        }
    }
    function onInputFocus() {
        if (selectedOption) {
            const selectedOptionIndex = options.indexOf(selectedOption);
            setHighlightedIndex(selectedOptionIndex);
        }
        else {
            setHighlightedIndex(0);
        }
    }
    function onInputBlur() {
        if (highlightedOption && query) {
            selectHighlightedOption();
        }
        else {
            const fieldIsClear = query === '';
            fieldIsClear ? onChange('') : resetQuery();
        }
    }
    function hightlightNextOption() {
        setHighlightedIndex(prevIndex => Math.min(prevIndex + 1, filteredOptions.length - 1));
    }
    function hightlightPreviousOption() {
        setHighlightedIndex(prevIndex => Math.max(prevIndex - 1, 0));
    }
    function resetQuery() {
        setQuery(null);
    }
    return (_jsx(Popover, Object.assign({ id: id, popoverType: "listbox", fullWidth: true, activator: _jsx(AutocompleteInput, Object.assign({ id: id, value: query !== null ? query : (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.label) || '', onChange: onQueryChange, onArrowDown: hightlightNextOption, onArrowUp: hightlightPreviousOption, onEnter: selectHighlightedOption, onFocus: onInputFocus, onBlur: onInputBlur }, textFieldProps, { placeholder: loading ? 'Loading...' : textFieldProps.placeholder })) }, { children: _jsx(AutocompletePanel, { loading: loading, filteredOptions: filteredOptions, selectedOptions: selectedOptions || (selectedOption ? [selectedOption] : undefined), highlightedIndex: highlightedIndex, selectOption: selectOption, optionsGroups: optionsGroups, showCheckBoxForSelectedItems: showCheckBoxForSelectedItems }) })));
}
