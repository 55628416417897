import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import AdjudicationCardWithHeader from './AdjudicationCardWithHeader';
import { AdjudicationCardContent } from './AdjudicationCardContent';
export function AdjudicationGroupingArea({ selectedAreasGroupedByState }) {
    if (!selectedAreasGroupedByState)
        return null;
    return (_jsx(_Fragment, { children: Object.keys(selectedAreasGroupedByState).map(state => {
            const { counties } = selectedAreasGroupedByState[state];
            if (Object.keys(counties).length === 0)
                return null;
            return (_jsx("div", { children: _jsx(AdjudicationCardWithHeader, Object.assign({ header: {
                        title: _jsx("h1", { children: selectedAreasGroupedByState[state].name })
                    } }, { children: _jsx(AdjudicationCardContent, { counties: counties }) }), state) }, state));
        }) }));
}
