import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { ColumnButton } from 'lib/components/ColumnButton';
export function DataTablePagination({ condensed, pagination, page, totalPages, onNextPage, onPrevPage }) {
    if (!pagination) {
        return null;
    }
    return (_jsxs("div", Object.assign({ className: classNames('px-3 bg-column-gray-50 border-t border-column-gray-200', 'font-medium text-sm text-column-gray-400', 'flex justify-between items-center', {
            'py-2': !condensed,
            'py-1.5': condensed
        }) }, { children: [_jsx(ColumnButton, { type: "button", "aria-label": "previous page", onClick: onPrevPage, disabled: page === 1, startIcon: _jsx(ChevronLeftIcon, { className: "w-4 h-4" }), link: true }), _jsxs("div", { children: ["Page ", page, " of ", totalPages] }), _jsx(ColumnButton, { type: "button", "aria-label": "next page", onClick: onNextPage, disabled: page === totalPages, startIcon: _jsx(ChevronRightIcon, { className: "w-4 h-4" }), link: true })] })));
}
