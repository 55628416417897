var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { apiPost } from 'api/typed';
import { Alert } from 'lib/components/Alert';
import { DataTable } from 'lib/components/DataTable';
import { Form } from 'lib/components/Form';
import { useLoading } from 'lib/components/hooks/useLoading';
import { Modal } from 'lib/components/Modal';
import { dbToUICurrency } from 'lib/pricing/ui';
import { useState } from 'react';
import { selectActiveOrganization, selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
export function BulkPaymentCardInputForm({ onClose, onContinue, invoices }) {
    const stripe = useStripe();
    const elements = useElements();
    const user = useAppSelector(selectUser);
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const sumInvoices = (firstN) => invoices
        .slice(0, firstN)
        .reduce((acc, invoice) => acc + invoice.nettotal, 0);
    const formId = 'bulk-pay-invoices-modal-form';
    const [errorMessage, setErrorMessage] = useState('');
    const [submitLoading, submitWithLoading] = useLoading();
    const handleSubmit = () => __awaiter(this, void 0, void 0, function* () {
        setErrorMessage('');
        if (!stripe || !elements || !user) {
            setErrorMessage('Unable to load payment processing functions');
            return;
        }
        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            setErrorMessage('Unable to load card element');
            return;
        }
        const { error: errorCreatingPaymentMethod, paymentMethod } = yield stripe.createPaymentMethod({
            type: 'card',
            card: cardElement
        });
        if (errorCreatingPaymentMethod) {
            setErrorMessage(errorCreatingPaymentMethod.message ||
                'An error occurred processing payment');
            return;
        }
        const { response: ledgerItemId, error: errorPayingInvoices } = yield apiPost('ledger/bulk-pay-invoices', {
            userId: user.id,
            organizationId: activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id,
            stripePaymentMethodId: paymentMethod.id,
            invoiceIds: invoices.map(invoice => invoice.id)
        });
        if (errorPayingInvoices !== null) {
            setErrorMessage(errorPayingInvoices || 'An error occurred processing payment');
            return;
        }
        onContinue(ledgerItemId);
    });
    return (_jsx(Modal, Object.assign({ id: "bulk-pay-invoices-modal", onClose: onClose, title: "Pay Invoices", primaryAction: {
            formId,
            loading: !stripe || !elements || submitLoading,
            buttonText: 'Pay Invoices',
            type: 'submit'
        }, size: "3xl" }, { children: _jsx(Form, Object.assign({ id: formId, onSubmit: () => submitWithLoading(handleSubmit) }, { children: _jsxs("div", Object.assign({ className: "m-4 flex flex-col gap-4" }, { children: [_jsx(DataTable, { id: "bulk-payment-invoice-list", labelText: "Review selected invoices", columns: ['invoice', 'notice', 'publisher', 'amount'], headers: {
                            invoice: 'Invoice',
                            notice: 'Notice',
                            publisher: 'Publisher',
                            amount: 'Amount'
                        }, columnAlignment: {
                            amount: 'right'
                        }, data: invoices.map(invoice => {
                            var _a;
                            return ({
                                invoice: invoice.invoicenumber,
                                notice: (_a = invoice.noticename) !== null && _a !== void 0 ? _a : 'Legal Notice',
                                publisher: invoice.publishername,
                                amount: `$${dbToUICurrency(invoice.nettotal).toFixed(2)}`
                            });
                        }), totals: {
                            invoice: 'Total',
                            amount: `$${dbToUICurrency(sumInvoices(invoices.length)).toFixed(2)}`
                        } }), _jsxs("div", { children: [_jsxs("div", Object.assign({ className: "pb-2 text-sm font-medium text-column-gray-500" }, { children: ["Enter payment information", _jsx("span", Object.assign({ className: "text-column-gray-400" }, { children: " *" }))] })), _jsx("div", Object.assign({ className: "border border-column-gray-200 rounded-lg shadow p-3" }, { children: _jsx(CardElement, { options: { hidePostalCode: true } }) }))] }), errorMessage && (_jsx(Alert, { id: "bulk-payment-error", title: errorMessage, status: "error", icon: _jsx(ExclamationCircleIcon, { className: "w-6 h-6" }) }))] })) })) })));
}
