export var AdsTableColumns;
(function (AdsTableColumns) {
    AdsTableColumns["DECEASED_NAME"] = "Deceased Name";
    AdsTableColumns["AD_DEADLINE"] = "Ad Deadline";
    AdsTableColumns["CUSTOMER_NAME"] = "Customer Name";
    AdsTableColumns["NEWSPAPER_NAME"] = "Newspaper Name";
    AdsTableColumns["CATEGORY"] = "Category";
    AdsTableColumns["PUBLICATION_DATE"] = "Publication Date";
    AdsTableColumns["CUSTOMER_TYPE"] = "Customer Type";
    AdsTableColumns["VERIFICATION"] = "Verification";
    AdsTableColumns["PUBLICATION"] = "Publication";
    AdsTableColumns["STATUS"] = "Status";
    AdsTableColumns["LISTING_NAME"] = "Listing Name";
})(AdsTableColumns || (AdsTableColumns = {}));
