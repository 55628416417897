import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { DataTableSortIndicator } from './DataTableSortIndicator';
import { ColumnButton } from '../../ColumnButton';
export function DataTableColumnHeader({ column, children, condensed, alignment = 'left', onSortColumn, sortDirection }) {
    return (_jsx("th", Object.assign({ className: classNames('px-1.5 first:pl-3 last:pr-3 font-medium', {
            'py-3': !condensed,
            'py-1.5': condensed
        }), scope: "col" }, { children: _jsxs("div", Object.assign({ className: classNames('flex items-center gap-2', {
                'justify-start': alignment === 'left',
                'justify-end': alignment === 'right'
            }) }, { children: [sortDirection && (_jsx(ColumnButton, { startIcon: _jsx(DataTableSortIndicator, { sortDirection: sortDirection }), type: "button", onClick: onSortColumn, buttonText: _jsx("div", Object.assign({ className: "text-sm" }, { children: children })), "aria-label": `sort by ${column}`, link: true })), !sortDirection && children] })) })));
}
