var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { Tooltip } from 'lib/components/Tooltip';
import FormSwitch from 'routes/settings/publisher/FormSwitch';
function ShowLogoSwitch({ adData, onAdChange, advertiserLogo }) {
    const handleSwitchChange = () => __awaiter(this, void 0, void 0, function* () {
        const updatedAdData = Object.assign(Object.assign({}, adData), { funeralHomeLogo: adData.funeralHomeLogo ? '' : advertiserLogo });
        onAdChange(updatedAdData);
    });
    return (_jsxs("div", Object.assign({ className: classNames('flex items-center justify-between h-12 p-4 mt-4 rounded-md bg-white w-full lg:w-1/2 mx-auto', {
            'border border-primary-500': !!adData.funeralHomeLogo,
            'border border-column-gray-200': !adData.funeralHomeLogo
        }) }, { children: [_jsxs("div", Object.assign({ className: classNames('text-sm font-medium flex items-center', {
                    'text-primary-500': !!adData.funeralHomeLogo,
                    'text-column-gray-300': !adData.funeralHomeLogo
                }) }, { children: [_jsx(Tooltip, Object.assign({ helpText: "If enabled, the funeral home logo will appear in the obituary. Click view proof to see logo placement." }, { children: _jsx(QuestionMarkCircleIcon, { className: "h-5 w-5" }) })), _jsx("p", Object.assign({ className: "pl-2" }, { children: "Show funeral home logo" }))] })), _jsx(FormSwitch, { checked: !!adData.funeralHomeLogo, onChange: handleSwitchChange })] })));
}
export default ShowLogoSwitch;
