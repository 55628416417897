import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { handleError } from 'redux/errors.slice';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { useAppDispatch } from 'redux/hooks';
import { ColumnService } from 'lib/services/directory';
export const BLOCK_SUBMIT_ID = 'block-submit';
const styles = () => createStyles({
    loadingContainer: {
        width: '100%',
        height: '80vh'
    }
});
function LoadingState({ classes, isFullScreen = true, message, gifToReplaceSpinner, timeout = 30, onTimeout, context }) {
    const dispatch = useAppDispatch();
    useEffect(() => {
        const timer = setTimeout(() => {
            var _a;
            // Give the caller a chance to log, etc.
            if (onTimeout) {
                onTimeout();
            }
            const msg = (context === null || context === void 0 ? void 0 : context.location)
                ? `[Time out error] Operation timed out at [${context.location}]`
                : '[Time out error] Operation timed out, location unknown';
            const tags = Object.assign({ location: (_a = context === null || context === void 0 ? void 0 : context.location) !== null && _a !== void 0 ? _a : 'unknown' }, context === null || context === void 0 ? void 0 : context.tags);
            dispatch(handleError({
                error: msg,
                tags,
                service: (context === null || context === void 0 ? void 0 : context.service) || ColumnService.UNKNOWN
            }));
        }, timeout * 1000);
        return () => clearTimeout(timer);
    }, []);
    return (_jsxs(Grid, Object.assign({ container: true, justify: "center", alignItems: "center", direction: "column", xs: 12, className: isFullScreen ? classes.loadingContainer : '', item: true }, { children: [_jsx("div", Object.assign({ className: isFullScreen ? 'mt-8' : '' }, { children: _jsx(LoadingSpinner, { gifToReplaceSpinner: gifToReplaceSpinner }) })), message && (_jsx("div", Object.assign({ className: "text-column-gray-400 mt-3 text-sm font-medium" }, { children: message }))), _jsx("input", { type: "hidden", id: BLOCK_SUBMIT_ID, value: "" })] })));
}
export default withStyles(styles)(LoadingState);
