import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Product } from 'lib/enums';
import { captureTaglessEscapedHTML, escapeHTML, stripHtmlTags } from 'lib/helpers';
import MinimalMce from '../MinimalMce';
const titlePlaceholderByProduct = {
    [Product.Classified]: 'Title',
    [Product.Notice]: 'Title',
    [Product.Obituary]: 'Name of loved one'
};
function TitleEditor({ adData, onChange, disableEditing, product }) {
    return (_jsx(MinimalMce, { toolbarItems: [['undo', 'redo']], onChange: value => onChange({
            title: captureTaglessEscapedHTML(stripHtmlTags(value))
        }), inline: true, value: escapeHTML((adData === null || adData === void 0 ? void 0 : adData.title) || ''), id: "ad-title-editor", disabled: disableEditing, placeholder: titlePlaceholderByProduct[product], required: true }));
}
export default TitleEditor;
