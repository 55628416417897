export var ColumnService;
(function (ColumnService) {
    /** Column Admin */
    ColumnService["ADMIN"] = "admin";
    /** Static file storage */
    ColumnService["FILE_STORAGE"] = "file-storage";
    /** File transformation (cropping, upscaling, resizing,..) */
    ColumnService["FILE_TRANSFORMATION"] = "file-transformation";
    /** Our primary Firestore database */
    ColumnService["DATABASE"] = "database";
    /** Automated affidavits */
    ColumnService["AFFIDAVITS"] = "affidavits";
    /** AFFinity X Service */
    ColumnService["AFFINITY_X"] = "affinity-x";
    /** inApp notifications */
    ColumnService["APPLICATION_NOTIFICATIONS"] = "application-notifications";
    /** Automated notice placement */
    ColumnService["AUTOMATED_NOTICE_PLACEMENT"] = "automated-notice-placement";
    /** Bulk invoicing (v1 and v2) */
    ColumnService["BULK_INVOICES"] = "bulk-invoices";
    /** Deadline scheduling and syncing */
    ColumnService["DEADLINES"] = "deadlines";
    /** Elastic */
    ColumnService["ELASTIC"] = "elastic";
    /** Check Reconciliation, ACH reconciliation, etc */
    ColumnService["FINANCIAL_RECONCILIATION"] = "financial-reconciliation";
    /** Authentication and user management */
    ColumnService["AUTH_AND_USER_MANAGEMENT"] = "authentication-and-user-management";
    /** Affidavits, invoices/receipts, proofs */
    ColumnService["FILE_GENERATION"] = "file-generation";
    /** Inbound FTP to our FileMage server */
    ColumnService["FTP"] = "ftp";
    /** Secret access/management for our backend */
    ColumnService["FUNCTIONS_CONFIG"] = "functions-config";
    /** InDesign Service */
    ColumnService["INDESIGN"] = "indesign";
    /** General integrations infrastructure */
    ColumnService["INTEGRATIONS"] = "integrations";
    /** Lee Adpoint integration */
    ColumnService["INTEGRATIONS_LEE_ADPOINT"] = "integrations-lee-adpoint";
    /** SCNG Naviga integration */
    ColumnService["INTEGRATIONS_SCNG"] = "integrations-scng";
    /** Launch Darkly Service */
    ColumnService["LAUNCH_DARKLY_SERVICE"] = "launch-darkly-service";
    /** Classifieds & Obituaries */
    ColumnService["OBITS"] = "obits";
    /** All payment processing (Stripe, Elavon, Payway, Dwolla, etc.) */
    ColumnService["PAYMENTS"] = "payments";
    /** Custom pagination (i.e. code that's tied to /pagination) */
    ColumnService["PAGINATION"] = "pagination";
    /** Registered Agents API */
    ColumnService["REGISTERED_AGENTS"] = "registered-agents";
    /** Support Bot Context */
    ColumnService["SUPPORT_BOT"] = "support-bot";
    /** All other issues */
    ColumnService["UNKNOWN"] = "unknown";
    /** Placing notices on the web */
    ColumnService["WEB_PLACEMENT"] = "web-placement";
    /** Organization and user settings features */
    ColumnService["SETTINGS_MANAGEMENT"] = "settings-management";
    /** Display sites (public notices, obits and classifieds) */
    ColumnService["DISPLAY_SITES"] = "display-sites";
    /** CI and deployment */
    ColumnService["CI_CD"] = "ci-and-deployment";
    /** General migration errors */
    ColumnService["DATA_MIGRATION"] = "data-migration";
    /** Automated bulk download at deadline */
    ColumnService["AUTOMATED_BULK_DOWNLOAD"] = "auto-bulk-download";
    /** Firestore document listeners (onWrite, onUpdate,...) */
    ColumnService["FIRESTORE_LISTENERS"] = "firestore-listeners";
    /** Organization management includes duplicating organizations, adding users and adding upload ID */
    ColumnService["ORGANIZATION_MANAGEMENT"] = "organization-management";
    /** Template styles management */
    ColumnService["TEMPLATE_STYLES_MANAGEMENT"] = "template-styles-management";
    /** Automated uploads to external state-wide sites */
    ColumnService["EXTERNAL_UPLOADS"] = "external-uploads";
})(ColumnService || (ColumnService = {}));
