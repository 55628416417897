var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment-timezone';
import { getDeadlineOverrideKeyFromDate } from '../types/deadlines';
import { firestoreTimestampOrDateToDate, firstNoticePublicationDate, getNoticeTypeFromNoticeData } from '../helpers';
import { applyMinutesOffsetAndGetDate, getDateForDateStringInTimezone } from './dates';
import { Day, NoticeType } from '../enums';
import { getOrThrow } from './refs';
import { getFirestoreTimestampsFromDateString } from '../date';
import { wrapSuccess, wrapError } from '../types/responses';
import { getErrorReporter } from './errors';
export const DEFAULT_DEADLINES = [
    {
        dayEnum: Day.sunday.value,
        publish: false,
        deadline: {
            dayEnum: Day.sunday.value,
            time: '12:00'
        }
    },
    {
        dayEnum: Day.monday.value,
        publish: true,
        deadline: {
            dayEnum: Day.monday.value,
            time: '12:00'
        }
    },
    {
        dayEnum: Day.tuesday.value,
        publish: false,
        deadline: {
            dayEnum: Day.tuesday.value,
            time: '12:00'
        }
    },
    {
        dayEnum: Day.wednesday.value,
        publish: false,
        deadline: {
            dayEnum: Day.wednesday.value,
            time: '12:00'
        }
    },
    {
        dayEnum: Day.thursday.value,
        publish: false,
        deadline: {
            dayEnum: Day.thursday.value,
            time: '12:00'
        }
    },
    {
        dayEnum: Day.friday.value,
        publish: false,
        deadline: {
            dayEnum: Day.friday.value,
            time: '12:00'
        }
    },
    {
        dayEnum: Day.saturday.value,
        publish: false,
        deadline: {
            dayEnum: Day.saturday.value,
            time: '12:00'
        }
    }
];
export const disableNonPublishingDays = (day, publishingDayEnumValues, overrides) => {
    const publishingDayOverride = overrides && overrides[getDeadlineOverrideKeyFromDate(day)];
    if (publishingDayOverride) {
        return !publishingDayOverride.publish;
    }
    return !publishingDayEnumValues.includes(day.getDay() + 1);
};
export const getRelevantDeadline = (publicationDate, deadlines, overrides) => {
    const publishingDayOverride = overrides && overrides[getDeadlineOverrideKeyFromDate(publicationDate)];
    if (publishingDayOverride) {
        return publishingDayOverride;
    }
    const m = moment(publicationDate).clone();
    const publicationDayOfWeek = m.day();
    // enums days of week are off by one from moment days of week
    const relevantDeadline = deadlines.find(day => day.dayEnum === publicationDayOfWeek + 1);
    return relevantDeadline;
};
export const getDeadlineTimeForRegularDeadline = (publicationDate, relevantDeadline, newspaperTimezone) => {
    const deadlineDayOfWeek = relevantDeadline.deadline.dayEnum - 1;
    const m = moment(publicationDate).clone();
    const publicationDayOfWeek = m.day();
    let startOfDeadlineDay;
    // e.g. publication on Sunday (day 0), deadline on Saturday (day 6)
    if (publicationDayOfWeek - deadlineDayOfWeek < 0) {
        startOfDeadlineDay = moment(publicationDate)
            .clone()
            .tz(newspaperTimezone)
            .subtract(1, 'week')
            .add(deadlineDayOfWeek - publicationDayOfWeek, 'd')
            .startOf('day');
    }
    else {
        // e.g. publication on Monday (day 1), deadline on Sunday (day 0)
        startOfDeadlineDay = moment(publicationDate)
            .clone()
            .tz(newspaperTimezone)
            .subtract(publicationDayOfWeek - deadlineDayOfWeek, 'day')
            .startOf('day');
    }
    if (relevantDeadline.weeks) {
        startOfDeadlineDay = startOfDeadlineDay.subtract(relevantDeadline.weeks, 'weeks');
    }
    const deadlineHoursFromStartOfDay = parseInt(relevantDeadline.deadline.time.split(':')[0], 10);
    const deadlineMinutes = parseInt(relevantDeadline.deadline.time.split(':')[1], 10);
    const deadlineTimeForDeadline = startOfDeadlineDay
        .add(deadlineHoursFromStartOfDay, 'hours')
        .add(deadlineMinutes, 'minutes');
    return deadlineTimeForDeadline;
};
export const getDeadlineTimeForPaper = (publicationDate, deadlines, deadlineOverrides, newspaperTimezone, notice, newspaper) => {
    const relevantDeadline = getRelevantDeadline(publicationDate, deadlines, deadlineOverrides);
    let deadlineTimeForPaper = getDeadlineTimeForRegularDeadline(publicationDate, relevantDeadline, newspaperTimezone);
    const noticeType = getNoticeTypeFromNoticeData(notice, newspaper);
    if (noticeType === null || noticeType === void 0 ? void 0 : noticeType.deadlineOffsetHours) {
        deadlineTimeForPaper = deadlineTimeForPaper.subtract(noticeType.deadlineOffsetHours, 'hours');
    }
    const isDisplayOrPostedWithoutFormatting = notice.noticeType === NoticeType.display_ad.value ||
        notice.postWithoutFormatting;
    if (isDisplayOrPostedWithoutFormatting && (relevantDeadline === null || relevantDeadline === void 0 ? void 0 : relevantDeadline.displayOffset)) {
        deadlineTimeForPaper = deadlineTimeForPaper.subtract(relevantDeadline === null || relevantDeadline === void 0 ? void 0 : relevantDeadline.displayOffset, 'hours');
    }
    return deadlineTimeForPaper.clone();
};
export const getIsAfterPublishingDeadline = (publicationDate, deadlines, deadlineOverrides, newspaperTimezone, notice, newspaper, deadlineBufferSettings) => {
    if (!deadlines || !publicationDate)
        return true;
    const currentTimeForPaper = moment(Date.now()).tz(newspaperTimezone);
    const deadlineTimeForPaper = getDeadlineTimeForPaper(publicationDate, deadlines, deadlineOverrides, newspaperTimezone, notice, newspaper);
    const effectiveDeadline = deadlineBufferSettings
        ? applyMinutesOffsetAndGetDate(deadlineTimeForPaper, deadlineBufferSettings)
        : deadlineTimeForPaper;
    return currentTimeForPaper.isAfter(effectiveDeadline);
};
export const getMsAfterDeadlineSimplified = (notice, newspaper, date) => {
    // If no date is specified, choose the first publication date
    const publicationDate = date
        ? firestoreTimestampOrDateToDate(date)
        : firstNoticePublicationDate(notice);
    const { deadlines, deadlineOverrides = {}, iana_timezone } = newspaper.data();
    if (!deadlines || !publicationDate)
        return Infinity;
    const currentTimeForPaper = moment(Date.now()).tz(iana_timezone);
    const deadlineTimeForPaper = getDeadlineTimeForPaper(publicationDate, deadlines, deadlineOverrides, iana_timezone, notice.data(), newspaper);
    const msAfterDeadline = currentTimeForPaper.diff(deadlineTimeForPaper);
    return msAfterDeadline;
};
export const getIsAfterPublishingDeadlineSimplified = (notice, newspaper, date) => {
    return getMsAfterDeadlineSimplified(notice, newspaper, date) > 0;
};
export const isNoticeAfterPublicationDeadline = (notice) => __awaiter(void 0, void 0, void 0, function* () {
    const newspaperSnap = yield getOrThrow(notice.data().newspaper);
    const firstPublication = notice.data().publicationDates[0];
    const { deadlines, deadlineOverrides = {}, iana_timezone } = newspaperSnap.data();
    if (!deadlines)
        throw new Error('No deadlines found for newspaper');
    return getIsAfterPublishingDeadline(firstPublication.toDate(), deadlines, deadlineOverrides, iana_timezone, notice.data(), newspaperSnap);
});
export const publishingDayEnumValuesFromDeadlines = (deadlines) => {
    if (!deadlines)
        return [];
    return deadlines.filter(day => day.publish).map(day => day.dayEnum);
};
export const getNewspaperPublishedOnDate = (deadlines, deadlineOverrides, dateToTest) => {
    var _a, _b;
    if (!deadlines)
        throw new Error('No deadlines found for newspaper');
    const publishingDayEnumValues = publishingDayEnumValuesFromDeadlines(deadlines).sort();
    // Since Moment days are 0-indexed, we compare that Moment day + 1 to the Day enum value
    const isRegularPublishingDay = publishingDayEnumValues.includes(moment(dateToTest).day() + 1);
    const overrideKey = getDeadlineOverrideKeyFromDate(dateToTest);
    const hasPositiveOverride = ((_a = deadlineOverrides[overrideKey]) === null || _a === void 0 ? void 0 : _a.publish) === true;
    const hasNegativeOverride = ((_b = deadlineOverrides[overrideKey]) === null || _b === void 0 ? void 0 : _b.publish) === false;
    const publishedOnRegularDay = isRegularPublishingDay && !hasNegativeOverride;
    return hasPositiveOverride || publishedOnRegularDay;
};
/**
 * Determine if a day of the week is allowed based on a notice types restricted
 * publication days.
 */
export const isAllowedPublicationDay = (date, noticeType) => {
    const { restrictedPublicationDays } = noticeType !== null && noticeType !== void 0 ? noticeType : {};
    if (!(restrictedPublicationDays === null || restrictedPublicationDays === void 0 ? void 0 : restrictedPublicationDays.length)) {
        return true;
    }
    // restrictedPublicationDays uses our Day enums so it is 1-indexed. However
    // JS days of the week are 0-indexed so we have to adjust
    return restrictedPublicationDays.includes(date.getDay() + 1);
};
// MAX_WEEKS_TO_TRY is changed here to 20 instead of 3 because in some circumstances, the original start date
// of a notice + 3 weeks is before the current date. Thus, when a user tries to add run dates on notices that
// started more than 3 weeks ago, the edit flow stops working. The fix here is to change MAX_WEEKS_TO_TRY to
// a much larger number (in this case, 20 weeks)
const MAX_WEEKS_TO_TRY = 20;
export const getClosestFuturePublishingDay = (deadlines, deadlineOverrides, newspaperTimezone, notice, newspaper, initialStartDate = new Date()) => {
    let startDate = initialStartDate;
    const noticeType = getNoticeTypeFromNoticeData(notice, newspaper);
    if (noticeType === null || noticeType === void 0 ? void 0 : noticeType.deadlineOffsetHours) {
        startDate = moment(startDate)
            .add(noticeType.deadlineOffsetHours, 'hours')
            .toDate();
    }
    /**
     * Returns the first available date in the week on which the notice can be
     * published. If there is no available day, returns null.
     */
    const findFirstAvailableDateInWeek = (firstAvailableDate) => {
        // Note this array is 0-indexed because that is how moment counts
        // days of the week. However our Day enum is 1-indexed.
        const nextWeekDays = [0, 1, 2, 3, 4, 5, 6].map(ind => moment(firstAvailableDate).day(ind));
        const nextWeekNonRestrictedDays = nextWeekDays.filter(d => isAllowedPublicationDay(d.toDate(), noticeType));
        // Determine which days are actual publishing days.
        // An actual publishing day is either a day which explicitly has a publish=true
        // override or is a scheduled day without a publish=false override
        const possiblePublishingDays = nextWeekNonRestrictedDays.filter(d => getNewspaperPublishedOnDate(deadlines, deadlineOverrides, d.toDate()));
        // Consider each of the possible publishing days for next week
        for (const publishingDay of possiblePublishingDays) {
            // ignore deadlines that are before the start date
            if (publishingDay.isBefore(moment(startDate))) {
                continue;
            }
            const deadlinePassed = getIsAfterPublishingDeadline(publishingDay.toDate(), deadlines, deadlineOverrides, newspaperTimezone, notice, newspaper);
            if (!deadlinePassed) {
                return publishingDay;
            }
        }
        return null;
    };
    let nextPublishingDay = null;
    for (let i = 0; i < MAX_WEEKS_TO_TRY; i++) {
        nextPublishingDay = findFirstAvailableDateInWeek(moment(startDate).add(i, 'weeks').toDate());
        if (nextPublishingDay)
            break;
    }
    if (!nextPublishingDay)
        throw new Error(`Unable to find next publishing day for paper: ${newspaper.data().name} (${newspaper.id})`);
    const nextDate = nextPublishingDay.toDate();
    nextDate.setHours(12, 0, 0, 0);
    return nextDate;
};
export const getClosestPastPublishingDayForNewspaper = (newspaperSnap, options = {
    maxWeeks: 2,
    startingDate: moment().toDate()
}) => {
    for (let i = 0; i < options.maxWeeks * 7; i++) {
        const dateToTest = moment(options.startingDate).subtract(i, 'd').toDate();
        const { deadlines, deadlineOverrides = {} } = newspaperSnap.data();
        const newspaperPublishedOnDate = getNewspaperPublishedOnDate(deadlines, deadlineOverrides, dateToTest);
        if (newspaperPublishedOnDate) {
            return dateToTest;
        }
    }
    // If no past date was found in the given time frame, return null.
    return null;
};
export const dateObjectToDay = (date) => {
    const m = moment(date);
    return m.format('MMMM Do');
};
export const getDeadlineString = (deadlines, deadlineOverrides, publicationDate, notice, newspaper, deadlineBufferSettings) => {
    const { iana_timezone } = newspaper.data();
    if (!deadlines)
        return null;
    const deadlineTimeForPaper = getDeadlineTimeForPaper(publicationDate, deadlines, deadlineOverrides, iana_timezone, notice, newspaper);
    const effectiveDeadline = deadlineBufferSettings
        ? applyMinutesOffsetAndGetDate(deadlineTimeForPaper, deadlineBufferSettings)
        : deadlineTimeForPaper;
    return moment(effectiveDeadline).format('dddd, MMMM Do, h:mm a z');
};
export const dateObjectToDayEnum = (date) => moment(date).day() + 1;
/**
 * Gets the latest timestamp of a deadline from an array of deadlines
 */
export const getLatestDeadlineTimestampForDeadlines = (deadlines) => {
    return deadlines.reduce((latest, deadline) => {
        const deadlineData = deadline.data();
        const deadlineTimestamp = deadlineData ? deadlineData.scheduleTime : null;
        if (!latest ||
            (deadlineTimestamp && deadlineTimestamp.toMillis() > latest.toMillis())) {
            return deadlineTimestamp;
        }
        return latest;
    }, null);
};
/**
 * Translates a date string in YYYY-MM-DD format to a start and end timestamp and returns
 * all deadlines that fall within that range for a given organization
 */
export const getDeadlineRecordsForPublicationDate = (ctx, publicationDate, orgRef) => __awaiter(void 0, void 0, void 0, function* () {
    const orgSnap = yield getOrThrow(orgRef);
    const orgTimezone = orgSnap.data().iana_timezone;
    const { startTimestamp, endTimestamp } = getFirestoreTimestampsFromDateString(ctx, publicationDate, orgTimezone);
    const deadlines = yield ctx
        .organizationDeadlinesRef(orgRef)
        .where('publicationDate', '>=', startTimestamp)
        .where('publicationDate', '<=', endTimestamp)
        .get();
    return deadlines.docs;
});
/**
 * This function is used primarily to create the `scheduleTime` we need to store on documents in the
 * `deadlines` subcollection in firestore.
 * @param publicationDateMoment The publication date for which we need to find the deadline. Should
 * be a `moment` date already pinned to the newspaper's timezone.
 * @param deadlineSettings The deadline settings relevant to the publication date. Should come from
 * the newspaper's `deadlines` or `deadlineOverrides`.
 * @param offsetInHours For handling display ad- and notice type-specific offsets.
 * @returns a `moment` date for the deadline in the same timezone as the publication date provided.
 */
export const getDeadlineTimeForPublicationDate = (publicationDateMoment, deadlineSettings, offsetInHours) => {
    const { deadline: { dayEnum, time }, weeks: weekOffset } = deadlineSettings;
    // Our dayEnum is 1-indexed, but moment uses 0-indexed values for day of week.
    const day = dayEnum - 1;
    const [hours, minutes] = time.split(':').map(numStr => parseInt(numStr, 10));
    const deadlineMoment = publicationDateMoment
        .clone()
        .set({ day, hours, minutes });
    if (deadlineMoment.isAfter(publicationDateMoment, 'day')) {
        deadlineMoment.subtract(1, 'week');
    }
    if (weekOffset) {
        deadlineMoment.subtract(weekOffset, 'weeks');
    }
    if (offsetInHours) {
        deadlineMoment.subtract(offsetInHours, 'hours');
    }
    return deadlineMoment;
};
export const getProductDeadlineTimeForPaper = (newspaper, product, publishingMedium, publicationDate) => __awaiter(void 0, void 0, void 0, function* () {
    const { iana_timezone: newspaperTimezone } = newspaper.modelData;
    const { response: productNewspaperSettings, error: fetchSettingsError } = yield newspaper.maybeFetchPublishingSettingFromProductMedium({
        product,
        publishingMedium
    });
    if (fetchSettingsError) {
        return wrapError(fetchSettingsError);
    }
    if (!productNewspaperSettings) {
        getErrorReporter().logInfo('No deadline settings found for newspaper and product', {
            newspaperId: newspaper.id,
            product
        });
        return wrapSuccess(null);
    }
    const { deadlines = [], deadlineOverrides = {} } = productNewspaperSettings.modelData;
    const firstPublishingDate = getDateForDateStringInTimezone({
        dayString: publicationDate,
        timezone: newspaperTimezone,
        time: '09:00'
    });
    const deadlineSettings = getRelevantDeadline(firstPublishingDate, deadlines, deadlineOverrides);
    return wrapSuccess({
        deadlineSettings,
        deadlineMoment: getDeadlineTimeForPaper(firstPublishingDate, deadlines, deadlineOverrides, newspaperTimezone, {}, newspaper)
    });
});
