var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { push } from 'connected-react-router';
import api from 'api';
import { OrganizationType } from 'lib/enums';
import { APP_ENTRYPOINT, POST_REGISTRATION_ENTRYPOINT } from 'redux/router';
import AuthActions from 'redux/auth';
import ToastActions from 'redux/toast';
import { logAndCaptureException } from 'utils';
import Firebase from 'EnoticeFirebase';
import { ColumnService } from 'lib/services/directory';
import PublisherDetailsForm, { DEFAULT_PUBLISHER } from './PublisherDetailsForm';
import { organizationAlreadyExists } from '../helpers';
export default function RegisterOrganizationNewspaper() {
    const dispatch = useAppDispatch();
    const [publisherData, setPublisherData] = useState(DEFAULT_PUBLISHER);
    const createPublisher = () => __awaiter(this, void 0, void 0, function* () {
        var _a;
        try {
            const { name } = publisherData;
            const orgAlreadyExists = yield organizationAlreadyExists(OrganizationType.newspaper.value, name);
            if (orgAlreadyExists) {
                dispatch(ToastActions.toastError({
                    headerText: `Newspaper account for ${name} already created`,
                    bodyText: 'If your colleague has already created an account please contact them for an invite'
                }));
                return;
            }
        }
        catch (error) {
            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, error, 'Failed to check if organization already exists');
            dispatch(ToastActions.toastError({
                headerText: 'Unable to create organization',
                bodyText: 'Please try again, or contact support if the problem continues'
            }));
            return;
        }
        const { error: errorCreatingOrganization } = yield api.post(`organizations/${OrganizationType.newspaper.value}/create`, publisherData);
        if (errorCreatingOrganization) {
            dispatch(ToastActions.toastError({
                headerText: 'Unable to create organization',
                bodyText: 'Please double check information and try again, or contact support if the problem continues'
            }));
            return;
        }
        try {
            yield ((_a = Firebase.auth().currentUser) === null || _a === void 0 ? void 0 : _a.getIdToken(true));
            dispatch(AuthActions.register());
            // Introduce a small delay, so onOrganizationCreate has time to create default rates
            yield new Promise(res => {
                setTimeout(() => res(), 540);
            });
            dispatch(push(APP_ENTRYPOINT));
        }
        catch (error) {
            logAndCaptureException(ColumnService.AUTH_AND_USER_MANAGEMENT, error, 'Failed to complete user registration after organization creation');
            dispatch(ToastActions.toastError({
                headerText: `Unable to complete registration`,
                bodyText: 'We successfully created your organization, but there was an issue completing your registration. Please contact support for assistance resolving the issue.'
            }));
        }
    });
    return (_jsx("div", Object.assign({ className: "max-w-4xl mx-auto mt-24" }, { children: _jsx(PublisherDetailsForm, { onUpdatePublisherData: newPublisherData => setPublisherData(newPublisherData), onGoBack: () => dispatch(push(POST_REGISTRATION_ENTRYPOINT)), onCreatePublisher: createPublisher, publisherData: publisherData }) })));
}
