import { jsx as _jsx } from "react/jsx-runtime";
import { centsToDollarsString } from 'lib/helpers';
import { Alert } from 'lib/components/Alert';
import LoadingState from 'components/LoadingState';
import PricingComparison from './PricingComparison';
import OrderSummaryRow from '../OrderSummaryRow';
function Pricing({ showComparison, oldTotalInCents, totalInCents, invoiceLoading }) {
    if (totalInCents === undefined ||
        (showComparison && oldTotalInCents === null)) {
        return _jsx(Alert, { id: "pricing-alert", title: "Missing total" });
    }
    if (showComparison && oldTotalInCents !== null) {
        return (_jsx(PricingComparison, { oldTotalInCents: oldTotalInCents, totalInCents: totalInCents, invoiceLoading: invoiceLoading }));
    }
    return (_jsx(OrderSummaryRow, { label: "Total Price", data: invoiceLoading ? (_jsx(LoadingState, { isFullScreen: false })) : (`$${centsToDollarsString(totalInCents)}`) }));
}
export default Pricing;
