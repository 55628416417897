var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-await-in-loop */
import moment from 'moment';
import { getNoticeType, maybeGetXMLSyncExportSettings, removeUndefinedFields } from '../../helpers';
import { SnapshotModel, getModelFromRef, getModelFromSnapshot } from '..';
import { Collections } from '../../constants';
import { ConfirmationStatus, NoticeStatusType, NoticeType, OrganizationStatus } from '../../enums';
import { getOrThrow } from '../../utils/refs';
import * as affidavitHelpers from '../../affidavits';
import { confirmNotice } from '../../notice/confirmation';
import { wrapError, wrapSuccess } from '../../types/responses';
import { getRunModelsFromQuery } from '../../services/runService';
import { createDesignNotesUpdatedEvent } from '../../utils/events';
import { AffidavitTemplateModel } from './affidavitTemplateModel';
import { getAffidavitSettingsForNotice } from '../../pricing/affidavits';
import { getCustomerForNotice } from '../../notice/customer';
import { noticeIsSubmitted } from '../../notice/helpers';
import { getErrorReporter } from '../../utils/errors';
import { getNoticeAAC } from '../../types/affidavits/convertARS';
import { CustomerModel } from './customerModel';
import { OrganizationModel } from './organizationModel';
import { ColumnService } from '../../services/directory';
export class UserNoticeModel extends SnapshotModel {
    constructor() {
        super(...arguments);
        this.type = Collections.userNotices;
        // TODO: replace with model once one exists?
        this.publisher = null;
        this.affidavitNotarizationPreconditionStatuses = null;
    }
    getPublisher() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.publisher) {
                this.publisher = yield getModelFromRef(OrganizationModel, this.ctx, this.modelData.newspaper);
            }
            return this.publisher;
        });
    }
    /**
     * Checks whether the notice is being created for the first time or not based on whether
     * it has some properties.
     *
     * @return {boolean} Returns true if the notice is new, otherwise false.
     */
    isNew() {
        return !this.modelData.noticeStatus;
    }
    isDisplay() {
        return this.modelData.noticeType === NoticeType.display_ad.value;
    }
    getPublisherManagedAffidavitTemplate() {
        return __awaiter(this, void 0, void 0, function* () {
            const fromNotice = yield AffidavitTemplateModel.fromSourceRef(this.ctx, this, moment().format('MM/DD/YYYY'), this.modelData.newspaper);
            if (fromNotice)
                return fromNotice;
            const user = yield getOrThrow(this.modelData.filer);
            const fromUser = yield AffidavitTemplateModel.fromSourceRef(this.ctx, user, user.data().name, this.modelData.newspaper);
            if (fromUser)
                return fromUser;
            const rate = yield getOrThrow(this.modelData.rate);
            const fromRate = yield AffidavitTemplateModel.fromSourceRef(this.ctx, rate, rate.data().description, this.modelData.newspaper);
            if (fromRate)
                return fromRate;
            const publisher = yield getOrThrow(this.modelData.newspaper);
            const noticeType = getNoticeType(this, publisher, {
                skipDisplayType: true
            });
            const fromNoticeType = yield AffidavitTemplateModel.fromNoticeType(this.ctx, noticeType, publisher);
            if (fromNoticeType)
                return fromNoticeType;
            return yield AffidavitTemplateModel.fromSourceRef(this.ctx, publisher, publisher.data().name, publisher.ref);
        });
    }
    getColumnManagedAffidavitTemplate() {
        return __awaiter(this, void 0, void 0, function* () {
            const settings = yield getAffidavitSettingsForNotice(this.ctx, this);
            if (!settings) {
                return;
            }
            if (!settings.affidavitsManagedByColumn) {
                throw new Error('Affidavit settings are not managed by column');
            }
            const fromNotice = yield AffidavitTemplateModel.fromSourceRefNotarized(this.ctx, this, moment().format('MM/DD/YYYY'), this.modelData.newspaper);
            if (fromNotice)
                return fromNotice;
            const customerSnap = yield getCustomerForNotice(this.ctx, this);
            if (customerSnap) {
                const customer = getModelFromSnapshot(CustomerModel, this.ctx, customerSnap);
                const customerName = `${customerSnap.data().firstName} ${customerSnap.data().lastName}`;
                const fromCustomer = yield AffidavitTemplateModel.fromSourceRefNotarized(this.ctx, customer, customerName, this.modelData.newspaper);
                if (fromCustomer)
                    return fromCustomer;
            }
            const publisherSnap = yield getOrThrow(this.modelData.newspaper);
            const publisher = getModelFromSnapshot(OrganizationModel, this.ctx, publisherSnap);
            const noticeType = getNoticeType(this, publisher);
            const fromNoticeType = yield AffidavitTemplateModel.fromNoticeTypeNotarized(this.ctx, noticeType, publisher);
            if (fromNoticeType)
                return fromNoticeType;
            return yield AffidavitTemplateModel.fromSourceRefNotarized(this.ctx, publisher, publisher.data().name, publisher.ref);
        });
    }
    getAffidavitTemplate(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return options.isColumnManaged
                ? this.getColumnManagedAffidavitTemplate()
                : this.getPublisherManagedAffidavitTemplate();
        });
    }
    createDraft({ asUser }) {
        return __awaiter(this, void 0, void 0, function* () {
            const originalData = Object.assign({}, this.modelData);
            delete originalData.drafts;
            const draftFields = Object.assign(Object.assign({}, originalData), { original: this.ref, owner: asUser });
            delete draftFields.editedAt;
            delete draftFields.lastEditedBy;
            delete draftFields.proofStoragePath;
            const newDraftRef = this.ctx.userDraftsRef().doc();
            yield newDraftRef.set(draftFields);
            const drafts = this.modelData.drafts || [];
            drafts.push(newDraftRef);
            yield this.update({ drafts });
            return newDraftRef;
        });
    }
    /**
     * Updates a notice with draft data.
     *
     * @param {Object} options - The options for updating the notice.
     * @param {ERef<ENoticeDraft>} options.draftRef - The reference to the draft notice.
     * @param {NoticeServiceAgent} options.agent - The agent responsible for the update.
     *
     * @returns {Promise<ResponseOrError<ENotice>>} - A promise that resolves to the updated notice or an error.
     */
    updateWithDraftData({ draftRef, agent }) {
        return __awaiter(this, void 0, void 0, function* () {
            const draftSnap = yield getOrThrow(draftRef);
            const draft = draftSnap.data();
            if (draft.inactive) {
                return {
                    response: null,
                    error: new Error('Draft is inactive')
                };
            }
            yield this.addDraftFilesToNotice(draftRef);
            yield this.addDraftMailToNotice(draftRef);
            const submissionTime = this.ctx.timestamp();
            const newspaper = yield getOrThrow(draftSnap.data().newspaper);
            const cleanedDraft = yield this.cleanDraftNoticeProperties(draft);
            const noticeObject = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, cleanedDraft), (newspaper.data().organizationStatus ===
                OrganizationStatus.in_implementation.value
                ? { testNotice: true }
                : {})), { noticeStatus: [
                    NoticeStatusType.affidavit_approved.value,
                    NoticeStatusType.affidavit_submitted.value
                ].includes(this.modelData.noticeStatus)
                    ? this.modelData.noticeStatus
                    : NoticeStatusType.pending.value, confirmedBy: agent.isPublisher
                    ? agent.user
                    : this.modelData.confirmedBy || null, confirmedAt: this.modelData.confirmedAt || submissionTime, confirmed: true, confirmedReceiptTime: agent.isPublisher ? submissionTime : null }), (this.isNew() ? {} : { editedAt: submissionTime })), (this.isNew() ? {} : { lastEditedBy: agent.user }));
            this.updateWithObjectData(noticeObject);
            // if design notes were edited, create a corresponding event
            if (draft.designNotes) {
                yield createDesignNotesUpdatedEvent(this.ctx, this.ref, draft.designNotes);
            }
            return wrapSuccess(this);
        });
    }
    updateWithObjectData(noticeObject) {
        Object.assign(this.modelData, noticeObject);
    }
    publish() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.update(this.modelData);
                return wrapSuccess(null);
            }
            catch (e) {
                return wrapError(e);
            }
        });
    }
    /**
     * Updates the account number on the notice if the publisher has exportSettings
     *
     * @param {string} accountNumber - The new account number to update.
     * @return {Promise<boolean>} A Promise that resolves to a boolean value indicating whether the notice was updated or not.
     */
    maybeUpdateAccountNumberOnNotice(accountNumber) {
        return __awaiter(this, void 0, void 0, function* () {
            const newspaper = yield this.getPublisher();
            const exportSettings = yield maybeGetXMLSyncExportSettings(newspaper);
            if (exportSettings) {
                const existingSyncData = this.modelData.syncData || { format: null };
                const updatedSyncData = Object.assign(Object.assign(Object.assign({}, existingSyncData), (exportSettings.format ? { format: exportSettings.format } : {})), { syncCustomerId: accountNumber });
                yield this.update({ syncData: updatedSyncData });
                return true;
            }
            return false;
        });
    }
    getAffidavitNotarizationPreconditionStatuses() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.affidavitNotarizationPreconditionStatuses) {
                this.affidavitNotarizationPreconditionStatuses = yield affidavitHelpers.getNoticePreconditionStatuses({
                    ctx: this.ctx,
                    notice: this
                });
            }
            return this.affidavitNotarizationPreconditionStatuses;
        });
    }
    areAffidavitsEnabled() {
        return __awaiter(this, void 0, void 0, function* () {
            const newspaperSnap = yield this.getPublisher();
            return !affidavitHelpers.isAffidavitDisabled(this.modelData, newspaperSnap);
        });
    }
    getRuns(options = {
        includeDisabled: false,
        includeCancelled: true,
        sortOrder: 'asc'
    }) {
        return __awaiter(this, void 0, void 0, function* () {
            const runQuery = this.ctx
                .runsRef()
                .where('notice', '==', this.ref)
                .orderBy('publicationDate', options.sortOrder);
            return yield getRunModelsFromQuery(this.ctx, runQuery, options);
        });
    }
    get isConfirmed() {
        return (Boolean(this.modelData.confirmedReceipt) ||
            this.modelData.confirmationStatus === ConfirmationStatus.Confirmed);
    }
    get isCancelled() {
        return this.modelData.noticeStatus === NoticeStatusType.cancelled.value;
    }
    get isPending() {
        return this.modelData.noticeStatus === NoticeStatusType.pending.value;
    }
    get isSubmitted() {
        return noticeIsSubmitted(this);
    }
    get confirmationStatus() {
        var _a;
        return ((_a = this.modelData.confirmationStatus) !== null && _a !== void 0 ? _a : (this.isConfirmed
            ? ConfirmationStatus.Confirmed
            : ConfirmationStatus.Pending));
    }
    confirm(confirmedBy) {
        return __awaiter(this, void 0, void 0, function* () {
            yield confirmNotice(this.ctx, {
                notice: this,
                user: confirmedBy
            });
        });
    }
    markReviewing() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.update({
                confirmationStatus: ConfirmationStatus.AwaitingConfirmation
            });
        });
    }
    /**
     * Adds the draft files from a notice draft to a notice by copying them
     * and deleting them from the draft.
     *
     * @param {ERef<ENoticeDraft>} draftRef - The reference to the draft notice.
     *
     * @return {Promise<void>} - A promise that resolves when the draft files have been added to the notice.
     */
    addDraftFilesToNotice(draftRef) {
        return __awaiter(this, void 0, void 0, function* () {
            const draftFilesCollectionRef = this.ctx.userNoticeFilesRef(draftRef);
            const draftFilesCollectionSnap = yield draftFilesCollectionRef.get();
            const noticeFilesCollectionRef = this.ctx.userNoticeFilesRef(this.ref);
            const noticeFilesCollectionSnap = yield noticeFilesCollectionRef.get();
            if (noticeFilesCollectionSnap.docs) {
                for (const noticeFileSnap of noticeFilesCollectionSnap.docs) {
                    yield noticeFileSnap.ref.delete();
                }
            }
            if (draftFilesCollectionSnap.docs) {
                for (const draftFileSnap of draftFilesCollectionSnap.docs) {
                    yield noticeFilesCollectionRef.add(draftFileSnap.data());
                    getErrorReporter().logInfo('Added draft file to notice files. Deleting draft...', {
                        draftId: draftFileSnap.id
                    });
                    yield draftFileSnap.ref.delete();
                }
            }
        });
    }
    /**
     * Adds the draft mails from a notice draft to a notice by copying them
     * and deleting them from the draft.
     *
     * @param {ERef<ENoticeDraft>} draftRef - The reference to the notice draft.
     * @return {Promise<void>} - A promise that resolves when the operation is complete.
     */
    addDraftMailToNotice(draftRef) {
        return __awaiter(this, void 0, void 0, function* () {
            const draftMailCollectionRef = draftRef.collection(Collections.mail);
            const draftMailCollectionSnap = yield draftMailCollectionRef.get();
            const noticeMailCollectionRef = this.ref.collection(Collections.mail);
            const noticeMailCollectionSnap = yield noticeMailCollectionRef.get();
            if (noticeMailCollectionSnap.docs) {
                for (const doc of noticeMailCollectionSnap.docs) {
                    const mRef = doc.ref;
                    yield mRef.delete();
                }
            }
            if (draftMailCollectionSnap.docs) {
                for (const doc of draftMailCollectionSnap.docs) {
                    yield noticeMailCollectionRef.add(doc.data());
                    const mRef = doc.ref;
                    yield mRef.delete();
                }
            }
        });
    }
    /**
     * Clean properties associated with a draft notice in preparation of publication
     *
     * @param {ENoticeDraft} noticeObject - The draft notice object to be cleaned.
     * @returns {Promise<ENoticeDraft>} - The cleaned draft notice object.
     */
    cleanDraftNoticeProperties(noticeObject) {
        return __awaiter(this, void 0, void 0, function* () {
            const UpdatedNoticeObject = noticeObject;
            delete UpdatedNoticeObject.anonymousFilerId;
            delete UpdatedNoticeObject.inactive;
            delete UpdatedNoticeObject.original;
            delete UpdatedNoticeObject.confirming;
            delete UpdatedNoticeObject.unusedDisplay;
            delete UpdatedNoticeObject.generatedAffidavitURL;
            delete UpdatedNoticeObject.generatedAffidavitStoragePath;
            delete UpdatedNoticeObject.invoice;
            if (UpdatedNoticeObject.displayParams) {
                delete UpdatedNoticeObject.displayParams.imgs;
                delete UpdatedNoticeObject.displayParams.err;
            }
            return UpdatedNoticeObject;
        });
    }
    /**
     * Clears affidavit url and path on notice which forces regeneration.
     *
     * @return {Promise<void>} - A promise that resolves when the affidavit is regenerated successfully.
     */
    clearAffidavitOnNotice() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.update({
                generatedAffidavitURL: '',
                generatedAffidavitStoragePath: ''
            });
        });
    }
    updateAutomatedAffidavitConfiguration(affidavitReconciliationSettings) {
        return __awaiter(this, void 0, void 0, function* () {
            const newAffidavitReconciliationSettings = removeUndefinedFields(Object.assign(Object.assign({}, this.modelData.affidavitReconciliationSettings), affidavitReconciliationSettings));
            const publisher = yield this.getPublisher();
            const { response: automatedAffidavitConfiguration, error } = yield getNoticeAAC(this.ctx, publisher, newAffidavitReconciliationSettings);
            if (error) {
                getErrorReporter().logAndCaptureError(ColumnService.AFFIDAVITS, error, 'Failed to get automated affidavit configuration from affidavit reconciliation settings for notice', { noticeId: this.id });
                return yield this.update({
                    affidavitReconciliationSettings: newAffidavitReconciliationSettings
                });
            }
            yield this.update(removeUndefinedFields({
                affidavitReconciliationSettings: newAffidavitReconciliationSettings,
                automatedAffidavitConfiguration
            }));
        });
    }
}
