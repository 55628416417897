var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert } from 'lib/components/Alert';
import { isAdvertiserOrder } from 'lib/types/order';
import { ObituaryFilingTypeNames } from 'lib/types/obituary';
import { NotFoundError, wrapErrorAsColumnError } from 'lib/errors/ColumnErrors';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { ColumnService } from 'lib/services/directory';
import LayoutSelector from './LayoutSelector';
import { TopActionBar } from './TopActionBar';
import FormattedEditorWrapper from './FormattedEditorWrapper';
import ExtraFieldInputs from './ExtraFieldInputs';
import ShowLogoSwitch from './ShowLogoSwitch';
function DraftContent({ adData, onAdChange, consolidatedOrderPricing, order, version, onUpdateNewspaperOrdersFormData, newspaperOrdersFormData, priceLoading, priceIsStale, canEditContent }) {
    // TODO: Pass layout in depending on which newspaperOrder we're on
    const { layout, filingType } = newspaperOrdersFormData[0];
    const advertiserOrder = isAdvertiserOrder(order.modelData);
    const { value: advertiserLogo, isError: failedToLoad } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (!advertiserOrder) {
                return '';
            }
            const { response: advertiserOrg, error: getAdvertiserOrgError } = yield order.getAdvertiserOrganization();
            if (getAdvertiserOrgError) {
                throw wrapErrorAsColumnError(getAdvertiserOrgError, NotFoundError);
            }
            return (advertiserOrg === null || advertiserOrg === void 0 ? void 0 : advertiserOrg.modelData.icon) || '';
        }),
        errorConfig: {
            service: ColumnService.OBITS,
            message: 'Failed to retrieve advertiser logo',
            tags: {
                orderId: order === null || order === void 0 ? void 0 : order.id
            }
        },
        dependencies: [order.id]
    });
    if (!layout) {
        return (_jsx(Alert, { id: "missing-layout", description: "Layout is missing in draft content step. Please refresh the page." }));
    }
    if (!filingType) {
        return (_jsx(Alert, { id: "missing-filing-type", description: "Publishing category is missing in draft content step. Please refresh the page." }));
    }
    if (failedToLoad) {
        return (_jsx(Alert, { id: "failed-to-load", description: "Failed to load advertiser logo." }));
    }
    const onLayoutChange = (newLayout) => {
        var _a;
        const newNewspaperOrdersFormData = newspaperOrdersFormData.map(newspaperOrder => (Object.assign(Object.assign({}, newspaperOrder), { layout: newLayout })));
        onUpdateNewspaperOrdersFormData(newNewspaperOrdersFormData);
        // If the new layout supports fewer photos than are currently on the order, we should remove the extra photos
        // also make sure to remove the crop data from the images as it may now be invalid
        const newAdData = Object.assign(Object.assign({}, adData), { orderImages: (_a = adData.orderImages) === null || _a === void 0 ? void 0 : _a.slice(0, newLayout.photos).map(image => {
                const { crop } = image, rest = __rest(image, ["crop"]);
                return Object.assign({}, rest);
            }) });
        onAdChange(newAdData);
    };
    const renderLogoSwitch = advertiserOrder &&
        advertiserLogo &&
        adData.filingTypeName === ObituaryFilingTypeNames.Obituary;
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "flex flex-col w-full bg-white items-center border-b border-column-gray-100 p-4" }, { children: [_jsx(TopActionBar, { adData: adData, consolidatedOrderPricing: consolidatedOrderPricing, order: order, version: version, newspaperOrdersFormData: newspaperOrdersFormData, priceLoading: priceLoading, priceIsStale: priceIsStale }), _jsx(LayoutSelector, { onLayoutChange: onLayoutChange, newspaperOrder: newspaperOrdersFormData[0], layout: layout, selectionDisabled: !canEditContent }), renderLogoSwitch && (_jsx(ShowLogoSwitch, { adData: adData, advertiserLogo: advertiserLogo, onAdChange: onAdChange }))] })), _jsx("div", Object.assign({ className: "p-2" }, { children: _jsx(ExtraFieldInputs, { adData: adData, onAdChange: onAdChange }) })), _jsxs("div", Object.assign({ className: "flex flex-wrap justify-center p-2" }, { children: [_jsx("div", Object.assign({ className: "flex justify-center w-full h-12" }, { children: _jsx("div", { id: "custom-toolbar-container" }) })), _jsx(FormattedEditorWrapper, { newspaperOrdersFormData: newspaperOrdersFormData, onAdChange: onAdChange, adData: adData, layout: layout, disableEditing: !canEditContent, product: order.modelData.product, filingType: filingType })] }))] }));
}
export default DraftContent;
