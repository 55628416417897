var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getErrorReporter } from '../utils/errors';
import { safeStringify } from '../utils/stringify';
import { RefSerializer } from './refs';
import { TimestampSerializer } from './timestamps';
import { ComboSerializer } from './comboSerializer';
import { DateSerializer } from './dates';
import { isObject } from './typeCheckers';
import { getOrThrow } from '../utils/refs';
import { objectHasFieldValues } from './fieldValues';
import { ColumnService } from '../services/directory';
const COMBO_SERIALIZER = new ComboSerializer([
    new RefSerializer(),
    new TimestampSerializer(),
    new DateSerializer()
]);
/**
 * Parse a string to JSON while replacing serialized EModel values.
 */
const parseModel = (ctx, val) => {
    return JSON.parse(val, (key, val) => {
        if (COMBO_SERIALIZER.canDeserialize(val)) {
            return COMBO_SERIALIZER.deserialize(ctx, val);
        }
        return val;
    });
};
/**
 * Determine if a value can be serialized to JSON without the help of one of
 * our special serializers.
 */
const assertNativelySerializable = (val) => {
    // Simple primitive values are always serializable
    if (!isObject(val)) {
        return;
    }
    // We don't want any class objects
    if (val.constructor.toString().startsWith('class ')) {
        throw new Error(`Unknown class object: ${val.constructor.name}`);
    }
    // Allow known types that work in both Firestore and JSON.
    // Notable exclusions: Function, RegExp, Date
    if (!['String', 'Number', 'Boolean', 'Array', 'Object'].includes(val.constructor.name)) {
        throw new Error(`Unsupported constructor: ${val.constructor.name}`);
    }
};
/**
 * Serialize EModel data into a wire-compatible format.
 */
const serializeModel = (data) => {
    return safeStringify(data, {
        replaceOptions: {
            replacer: {
                shouldReplace: val => {
                    // First check if we can/should do custom serialization
                    if (COMBO_SERIALIZER.canSerialize(val)) {
                        return true;
                    }
                    // Otherwise, we need to make sure this value is serializable
                    assertNativelySerializable(val);
                    return false;
                },
                replace: val => COMBO_SERIALIZER.serialize(val)
            }
        }
    });
};
export class SnapshotModel {
    constructor(ctx, data, options) {
        /** Needed to satisfy ESnapshotExists */
        this.exists = true;
        /** @deprecated TODO: We should deprecate this field from ESnapshotExists */
        this.proto = undefined;
        /** Whether the model is no longer valid (e.g. record has been deleted) */
        this.died = false;
        /** Whether we want to allow updates to the record in our code */
        this.readOnly = false;
        this.ctx = ctx;
        let newData;
        if (data.snap) {
            this.id = data.snap.id;
            this.path = data.snap.ref.path;
            newData = data.snap.data();
        }
        else if (data.serialized) {
            this.id = data.serialized.id;
            this.path = data.serialized.path;
            // We always convert SerializedData to model data through stringification.
            // This is slower, but it guarantees serializability and also makes use of
            // JSON's internal recursive replacer/parser.
            newData = parseModel(ctx, JSON.stringify(data.serialized.data));
        }
        else {
            throw new Error('Must pass one of data.snap or data.serialized');
        }
        // Validate data in case we have bad info in our db or serialization
        try {
            this.validateObject(newData);
        }
        catch (e) {
            getErrorReporter().logAndCaptureError(ColumnService.DATABASE, e, `Validation failed in model constructor`, {
                path: this.path
            });
            throw e;
        }
        this._modelData = newData;
        // Setup options
        this.readOnly = !!(options === null || options === void 0 ? void 0 : options.readOnly);
    }
    get ref() {
        return this.refProxy(this.ctx.doc(this.path));
    }
    /** @deprecated Use modelData() getter instead for better type checking */
    data() {
        return this.modelData;
    }
    /**
     * This getter method allows us to use modelData as an instance prop that works
     * better with Typescript's type checking
     */
    get modelData() {
        if (this.died) {
            throw new Error(`Cannot access data on dead model: ${this.path}`);
        }
        return this._modelData;
    }
    toSerialized() {
        // We always convert model data to serialized data through stringification.
        // This is slower, but it guarantees serializability and also makes use of
        // JSON's internal recursive replacer/parser.
        const dataString = serializeModel(this.modelData);
        if (dataString === undefined) {
            throw new Error('Serialization returned undefined!');
        }
        const data = JSON.parse(dataString);
        return {
            __type: this.type,
            id: this.id,
            path: this.path,
            data
        };
    }
    /**
     * Refresh the data in the model from the database. This is useful when changing the
     * model data with a back-end function and needing to update its instance on the FE
     */
    refreshData() {
        return __awaiter(this, void 0, void 0, function* () {
            this._modelData = (yield getOrThrow(this.ref)).data();
        });
    }
    /**
     * This method is called on each read and write. The data param is the full data object, even in an update
     * operation. When extending a class, override this method with your specific object's validation logic.
     *
     * WARNING: If you're not certain the data in the database is 100% in-tact, or if you want the app to continue
     * processing, log a warning in this function and always return null. Otherwise, return an error string
     * indicating the validation that failed (or return the Zod error message if you're using Zod).
     */
    validateObject(data) {
        if (data === undefined) {
            throw new Error('No data passed to write function');
        }
    }
    /**
     * Create a proxy that allows us to intercept firebase calls to perform
     * our own operations e.g. updating the model data
     */
    refProxy(ref) {
        return new Proxy(ref, {
            get: (target, property, receiver) => {
                if (this.died) {
                    throw new Error(`Entered proxy on dead model: ${this.path}`);
                }
                if (property === 'update') {
                    if (this.readOnly) {
                        throw new Error(`Update failed. Writing disabled on model ${this.path}`);
                    }
                    return this.update.bind(this);
                }
                if (property === 'set') {
                    if (this.readOnly) {
                        throw new Error(`Set failed. Writing disabled on model ${this.path}`);
                    }
                    return this.set.bind(this);
                }
                if (property === 'delete') {
                    if (this.readOnly) {
                        throw new Error(`Delete failed. Writing disabled on model ${this.path}`);
                    }
                    return this.delete.bind(this);
                }
                return Reflect.get(target, property, receiver);
            }
        });
    }
    writeData(data, isUpdate) {
        return __awaiter(this, void 0, void 0, function* () {
            const shouldRefreshData = objectHasFieldValues(data);
            // Construct the full data object so it can be validated as a whole
            const newDataFull = isUpdate ? Object.assign(Object.assign({}, this.modelData), data) : data;
            try {
                this.validateObject(newDataFull);
            }
            catch (e) {
                getErrorReporter().logAndCaptureError(ColumnService.DATABASE, e, `Validation failed in writeData method. Aborting write`, {
                    path: this.path
                });
                throw e;
            }
            const ref = this.ctx.doc(this.path);
            if (isUpdate) {
                yield ref.update(data);
            }
            else {
                yield ref.set(data);
            }
            if (shouldRefreshData) {
                const snap = yield getOrThrow(this.ref);
                this._modelData = snap.data();
            }
            else {
                this._modelData = newDataFull;
            }
        });
    }
    update(requestedData) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = Object.assign(Object.assign({}, requestedData), { modifiedAt: this.ctx.timestamp() });
            yield this.writeData(data, true);
        });
    }
    set(requestedData) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = Object.assign(Object.assign({}, requestedData), { modifiedAt: this.ctx.timestamp() });
            yield this.writeData(data, false);
        });
    }
    delete() {
        return __awaiter(this, void 0, void 0, function* () {
            const ref = this.ctx.doc(this.path);
            yield ref.delete();
            this.died = true;
        });
    }
}
export const getModelFromSnapshot = (ModelKlass, ctx, snap) => {
    return new ModelKlass(ctx, { snap });
};
export const getModelFromRef = (ModelKlass, ctx, ref) => __awaiter(void 0, void 0, void 0, function* () {
    const snap = yield getOrThrow(ref);
    return getModelFromSnapshot(ModelKlass, ctx, snap);
});
export const getModelFromId = (ModelKlass, ctx, collection, id) => __awaiter(void 0, void 0, void 0, function* () {
    const ref = collection.doc(id);
    return getModelFromRef(ModelKlass, ctx, ref);
});
export const getModelsFromQuery = (ModelKlass, ctx, query) => {
    return query.docs.map(doc => getModelFromSnapshot(ModelKlass, ctx, doc));
};
export const getModelFromSerialized = (ModelKlass, ctx, serialized) => {
    return new ModelKlass(ctx, { serialized });
};
