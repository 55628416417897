import { z } from 'zod';
import { TimezoneType } from '../enums';
import { EEditionStatus, EEditionUploadMethod } from './eedition';
export const HEADER_SESSION_ID_KEY = 'x-column-sessionid';
export const HEADER_COLUMN_APP = 'x-column-app';
export const IntegrationsVisionDataOcrRequestSchema = z.object({
    screenshotUrl: z.string().nonempty(),
    target: z.union([
        z.object({
            type: z.literal('text'),
            search: z.string().nonempty()
        }),
        z.object({
            type: z.literal('ad-number')
        }),
        z.object({
            type: z.literal('locked-ad')
        })
    ])
});
export const LedgerBulkPayInvoicesRequestSchema = z.object({
    invoiceIds: z.array(z.string()).min(2),
    userId: z.string().min(20),
    organizationId: z.string().min(20).optional(),
    stripePaymentMethodId: z.string().startsWith('pm_')
});
export const AdminAffidavitsGetEEditionTrackerDataRequestSchema = z.object({
    parentIds: z.array(z.string()).nullable(),
    publicationDateFrom: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
    publicationDateTo: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
    expectedUploadMethod: z.nativeEnum(EEditionUploadMethod).nullable(),
    eeditionStatus: z
        .union([z.nativeEnum(EEditionStatus), z.literal('missing')])
        .nullable(),
    verificationStatus: z.boolean().nullable()
});
export const LedgerReceiptRequestSchema = z.object({
    ledgerItemId: z.string().min(20)
});
export const OrganizationsCreateRequestSchema = z.object({
    name: z.string().nonempty(),
    circulationSize: z.number().int().positive(),
    county: z.string().nonempty(),
    address: z.string().nonempty(),
    city: z.string().nonempty(),
    state: z.number().int().positive(),
    zipCode: z.string().nonempty(),
    phone: z.string().nonempty(),
    iana_timezone: z.enum([
        TimezoneType['America/Chicago'].logical,
        ...TimezoneType.items().map(item => item.logical)
    ])
});
