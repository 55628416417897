import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { Badge } from 'lib/components/Badge';
import { capitalize } from 'lodash';
import { NewspapersContext } from 'routes/ads/place/contexts/NewspapersContext';
import { Alert } from 'lib/components/Alert';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
import NewspaperOrgImage from '../../../../components/PublisherImage';
function NewspaperOrderInfoRow({ filingTypeName, newspaperOrder, inPopover }) {
    var _a;
    const newspapers = useContext(NewspapersContext);
    const newspaper = newspapers.find(o => { var _a; return o.id === ((_a = newspaperOrder.newspaper) === null || _a === void 0 ? void 0 : _a.id); });
    if (!newspaper) {
        const error = new Error('Newspaper order contains newspaper not in context');
        logAndCaptureException(ColumnService.OBITS, error, error.message, {
            newspaperId: `${(_a = newspaperOrder.newspaper) === null || _a === void 0 ? void 0 : _a.id}`
        });
        return (_jsx(Alert, { id: "newspaper-context-error-alert", description: error.message }));
    }
    return (_jsxs("div", Object.assign({ className: "flex flex-row items-center gap-3" }, { children: [_jsx(NewspaperOrgImage, { newspaper: newspaper, className: "max-w-32 max-h-32" }), _jsx(Badge, { children: filingTypeName }), _jsx(Badge, { children: capitalize(newspaperOrder.publishingMedium) }), inPopover && _jsx("div", { className: "w-8" })] })));
}
export default NewspaperOrderInfoRow;
