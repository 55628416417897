import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Badge } from 'lib/components/Badge';
import moment from 'moment';
import { capitalizeWord } from 'lib/utils/strings';
import { NoSymbolIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { logAndCaptureMessage } from 'utils';
import { capitalize } from 'lodash';
import { AdsTableRowPublicationDateColumn } from './AdsTableRowPublicationDateColumn';
import { CustomerTypeString, VerificationFilterString } from './filters/helpers';
import { getBadgeStatusForNewspaperOrderStatus } from './helpers';
import { AdsTableColumns } from './types';
export default function AdsTableRow({ item, columns }) {
    const searchableOrderRecordToFormattedAd = (ad) => {
        var _a;
        const deceasedName = ad.deceasedname;
        // Since we haven'd defined classified props yet, the field we use for the listing name may change
        const listingName = ad.title;
        const adDeadline = (_jsx(Badge, { children: moment(Number(ad.addeadline)).format('MMM D, YYYY') }));
        // if the obit was placed by a funeral home, we want to show that funeral home as the customer
        // if the ad was placed by an individual, the funeral home name does not refer to the customer,
        // (i.e., the person paying for the order) so we want to use the filer's name instead
        const customerName = ad.hasadvertiserorg
            ? (_a = ad.funeralhomename) !== null && _a !== void 0 ? _a : ''
            : ad.filerfirstname && ad.filerlastname
                ? `${ad.filerfirstname} ${ad.filerlastname}`
                : '';
        const category = (_jsxs("div", Object.assign({ className: "flex gap-2" }, { children: [_jsx(Badge, { children: ad.filingtype }), ad.publishingmedium && (_jsx(Badge, { children: capitalize(ad.publishingmedium) }))] })));
        const publicationDates = (_jsx(AdsTableRowPublicationDateColumn, { publishingdates: ad.publishingdates }));
        const customerType = (_jsx(Badge, { children: ad.hasadvertiserorg
                ? CustomerTypeString.FUNERAL_HOME
                : CustomerTypeString.INDIVIDUAL }));
        const verification = (_jsx(Badge, Object.assign({ startIcon: ad.isverified ? (_jsx(CheckCircleIcon, { className: "w-3 h-3" })) : (_jsx(NoSymbolIcon, { className: "w-3 h-3" })) }, { children: ad.isverified
                ? VerificationFilterString.COMPLETE
                : VerificationFilterString.INCOMPLETE })));
        const publication = _jsx(Badge, { children: ad.publishername });
        const status = (_jsx(Badge, Object.assign({ status: getBadgeStatusForNewspaperOrderStatus(ad.newspaperorderstatus) }, { children: capitalizeWord(ad.newspaperorderstatus) })));
        const newspaperName = ad.publishername;
        const row = columns.reduce((accRow, column) => {
            switch (column) {
                case AdsTableColumns.DECEASED_NAME:
                    return Object.assign(Object.assign({}, accRow), { deceasedName });
                case AdsTableColumns.AD_DEADLINE:
                    return Object.assign(Object.assign({}, accRow), { adDeadline });
                case AdsTableColumns.CUSTOMER_NAME:
                    return Object.assign(Object.assign({}, accRow), { customerName });
                case AdsTableColumns.NEWSPAPER_NAME:
                    return Object.assign(Object.assign({}, accRow), { newspaperName });
                case AdsTableColumns.CATEGORY:
                    return Object.assign(Object.assign({}, accRow), { category });
                case AdsTableColumns.PUBLICATION_DATE:
                    return Object.assign(Object.assign({}, accRow), { publicationDates });
                case AdsTableColumns.CUSTOMER_TYPE:
                    return Object.assign(Object.assign({}, accRow), { customerType });
                case AdsTableColumns.VERIFICATION:
                    return Object.assign(Object.assign({}, accRow), { verification });
                case AdsTableColumns.PUBLICATION:
                    return Object.assign(Object.assign({}, accRow), { publication });
                case AdsTableColumns.STATUS:
                    return Object.assign(Object.assign({}, accRow), { status });
                case AdsTableColumns.LISTING_NAME:
                    return Object.assign(Object.assign({}, accRow), { listingName });
                default:
                    logAndCaptureMessage('Unexpected column in ads table', { column });
                    return accRow;
            }
        }, {});
        return row;
    };
    // Construct table rows data
    const formattedItem = searchableOrderRecordToFormattedAd(item);
    return (_jsx(_Fragment, { children: Object.values(formattedItem).map((value, index) => (_jsx("td", { children: value !== null && value !== void 0 ? value : '' }, index))) }));
}
