var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { NoticeType } from 'lib/enums';
import { cdnIfy } from 'lib/helpers';
import { ColumnButton } from 'lib/components/ColumnButton';
import BoundedProgress from 'components/BoundedProgress';
import { isFileUploadQuestionInputValue } from 'lib/utils/madlib';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import api from 'api';
import { logAndCaptureException } from 'utils';
import { ColumnService } from 'lib/services/directory';
/**
 * Sometimes typeform incorrectly sets multiple file values with the same link on the notice.
 * This function dedupes the notice files so that we don't show multiple download buttons for the same file.
 */
export const dedupeNoticeFiles = (noticeFiles) => {
    return noticeFiles.reduce((acc, noticeFile) => {
        const { linkToOriginalFile } = noticeFile.data();
        if (linkToOriginalFile &&
            acc.find(existingFile => existingFile.data().linkToOriginalFile === linkToOriginalFile)) {
            return acc;
        }
        return [...acc, noticeFile];
    }, []);
};
export function NoticePreviewDownloadButtons({ notice, noticeFiles, isPublisher }) {
    var _a, _b;
    const { jpgStoragePath, pdfStoragePath, idmlStoragePath, rtfStoragePath, idmlURL, rtfURL, pdfURL, noticeType } = notice.data();
    // If there are no paths set, the notice is still generating files
    const isGeneratingFiles = [
        jpgStoragePath,
        pdfStoragePath,
        idmlStoragePath,
        rtfStoragePath
    ].every(path => !path);
    const isDisplay = noticeType === NoticeType.display_ad.value;
    if (isGeneratingFiles && !isDisplay && !pdfURL) {
        return _jsx(GeneratingNoticeFilesLoader, {});
    }
    const getLinkToFileFromOldSchema = (oldSchema) => {
        return Object.values(oldSchema || []).find(value => value.indexOf('http') !== -1);
    };
    // Links to files uploaded (display ad flows)
    const dedupedNoticeFiles = dedupeNoticeFiles(noticeFiles || []);
    const noticeFileLinks = dedupedNoticeFiles === null || dedupedNoticeFiles === void 0 ? void 0 : dedupedNoticeFiles.map(noticeFile => {
        const linkFromOldSchemaMetadata = getLinkToFileFromOldSchema(noticeFile.data().oldSchemaMetadata);
        const href = noticeFile.data().linkToUploadedFile || linkFromOldSchemaMetadata;
        if (!href) {
            return undefined;
        }
        return {
            href,
            label: 'Original File'
        };
    }).filter((x) => !!x);
    // Links to files from Madlib forms
    const madlibLinks = Object.values((_b = (_a = notice.data().madlibData) === null || _a === void 0 ? void 0 : _a.questionTemplateData) !== null && _b !== void 0 ? _b : {})
        .filter(isFileUploadQuestionInputValue)
        .map(v => ({
        href: v.linkToUploadedFile,
        label: 'Attachment'
    }));
    const attachmentLinks = [...noticeFileLinks, ...madlibLinks];
    const useColumnCDN = getBooleanFlag(LaunchDarklyFlags.ENABLE_COLUMN_CDN);
    return (_jsxs("div", Object.assign({ className: "grid grid-cols-2 gap-2" }, { children: [_jsx(ColumnButton, { size: "xl", secondary: true, disabled: !pdfStoragePath, onClick: () => __awaiter(this, void 0, void 0, function* () {
                    const { response, error: downloadUrlError } = yield api.get(`documents/pdf/${notice.id}/download`);
                    if (downloadUrlError) {
                        logAndCaptureException(ColumnService.FILE_GENERATION, downloadUrlError, 'Failed to download PDF');
                    }
                    else {
                        openUrlNewWindow(response.url);
                    }
                }), buttonText: "PDF", type: "button", fullWidth: true }), isPublisher && (_jsx(ColumnButton, { size: "xl", secondary: true, disabled: !idmlStoragePath, onClick: () => {
                    if (idmlURL || idmlStoragePath) {
                        openUrlNewWindow(idmlURL || cdnIfy(idmlStoragePath, { useColumnCDN }));
                    }
                }, buttonText: "IDML", type: "button", fullWidth: true })), !isDisplay && (_jsx(ColumnButton, { size: "xl", secondary: true, disabled: !rtfStoragePath, onClick: () => {
                    if (rtfURL || rtfStoragePath) {
                        openUrlNewWindow(rtfURL || cdnIfy(rtfStoragePath, { useColumnCDN }));
                    }
                }, buttonText: "RTF", type: "button", fullWidth: true })), attachmentLinks.map((link, i) => (_jsx(ColumnButton, { size: "xl", secondary: true, onClick: () => {
                    return openUrlNewWindow(link.href);
                }, buttonText: link.label, type: "button", fullWidth: true }, `${i}-original-file`)))] })));
}
function GeneratingNoticeFilesLoader() {
    return (_jsx(BoundedProgress, { timeout: 30000, InProgress: _jsxs("h4", Object.assign({ className: "text-2xl text-white mb-4" }, { children: ["Generating Files", _jsx("div", { className: "inline-block align-middle mx-4 loader ease-linear rounded-full border-4 text-center border-t-4 border-white-500 h-6 w-6" })] })), Fallback: _jsx("p", Object.assign({ className: "text-white mb-2" }, { children: "There was an issue generating files. Wait 5 minutes, and visit this page again to retry file generation. If the problem persists, contact help@column.us." })) }));
}
const openUrlNewWindow = (url) => window.open(url, '_blank');
