var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Product } from '../enums';
import { getModelFromRef } from '../model';
import { ClassifiedModel } from '../model/objects/classifiedModel';
import { safeGetModelArrayFromQuery } from '../model/getModel';
import { wrapError, wrapSuccess } from '../types/responses';
import { getErrorReporter } from '../utils/errors';
import { ColumnService } from './directory';
import { OrderService } from './orderService';
import { ClassifiedFilingTypeNames } from '../types/classified';
export class ClassifiedService {
    constructor(context) {
        this.context = context;
        this.orderService = new OrderService(this.context);
    }
    create(orderInfo) {
        return __awaiter(this, void 0, void 0, function* () {
            const initialVersion = this.context.timestamp().toMillis();
            const orderRef = yield this.orderService.create(orderInfo, Product.Classified, initialVersion);
            const classifiedRef = yield this.context.classifiedsRef().add({
                // set a sane default category for classifieds so we always have a value
                filingTypeName: ClassifiedFilingTypeNames.AnnouncementsEvents,
                order: orderRef,
                orderVersion: initialVersion,
                createdAt: this.context.timestamp()
            });
            return getModelFromRef(ClassifiedModel, this.context, classifiedRef);
        });
    }
    getByOrderAndVersion(orderRef, version) {
        return __awaiter(this, void 0, void 0, function* () {
            const query = this.context
                .classifiedsRef()
                .where('order', '==', orderRef)
                .where('orderVersion', '==', version)
                .limit(2);
            const queryResult = yield safeGetModelArrayFromQuery(ClassifiedModel, this.context, query);
            if (queryResult.error) {
                return queryResult;
            }
            const adsForOrder = queryResult.response;
            if (adsForOrder.length !== 1) {
                const errorMessage = `Expected 1 classified for order and version; instead got ${adsForOrder.length > 1 ? 'multiple' : '0'}`;
                const error = Error(errorMessage);
                if (adsForOrder.length > 1) {
                    getErrorReporter().logAndCaptureError(ColumnService.OBITS, error, errorMessage, {
                        orderId: orderRef.id,
                        version: `${version}`
                    });
                }
                return wrapError(error);
            }
            return wrapSuccess(adsForOrder[0]);
        });
    }
    cloneForEditFlow(antecedentClassified, newVersion) {
        return __awaiter(this, void 0, void 0, function* () {
            const newClassifiedRef = yield this.context.classifiedsRef().add(Object.assign(Object.assign({}, antecedentClassified.modelData), { orderVersion: newVersion, createdAt: this.context.timestamp() }));
            getErrorReporter().logInfo('Created new classified for edit flow', {
                antecedentClassifiedId: antecedentClassified.id,
                newClassifiedId: newClassifiedRef.id,
                newVersion: `${newVersion}`
            });
        });
    }
}
