var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ColumnButton } from 'lib/components/ColumnButton';
import { useState } from 'react';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { exists } from 'lib/types';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { Alert } from 'lib/components/Alert';
import { getFirebaseContext } from 'utils/firebase';
import { removeUndefinedFields } from 'lib/helpers';
import { ADJUDICATION_AREA_SET } from 'lib/types/events';
import { selectUser } from 'redux/auth';
import { ColumnService } from 'lib/services/directory';
import { getErrorReporter } from 'lib/utils/errors';
import { useActiveOrganizationListener } from 'hooks/useActiveOrganizationListener';
import { fetchAdjudicationAreas, getAdjudicationAreasJoinedString, groupAdjudicationAreasByParent, shouldDisplayAddButton } from './helper';
import AdjudicationSectionHeader from './AdjudicationSectionHeader';
import { AdjudicationInfoContent } from './AdjudicationInfoContent';
export default function AdjudicationInfo() {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const user = useAppSelector(selectUser);
    const activeOrganization = useActiveOrganizationListener();
    const adjudicationAreasIdJoinedString = getAdjudicationAreasJoinedString(activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data());
    const [selectedAreasGroupedByState, setAreasGroupedByState] = useState({});
    const { value: adjudicationAreasWithId, isLoading: loadingAreas, isError: areasError } = useAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            if (!exists(activeOrganization)) {
                return;
            }
            const adjudicationAreasWithId = yield fetchAdjudicationAreas(activeOrganization);
            const groupedAreasByState = yield groupAdjudicationAreasByParent(adjudicationAreasWithId);
            setAreasGroupedByState(groupedAreasByState);
            return adjudicationAreasWithId;
        }),
        dependencies: [adjudicationAreasIdJoinedString],
        errorConfig: {
            message: 'Failed to load adjudication areas',
            service: ColumnService.SETTINGS_MANAGEMENT,
            tags: {
                activeOrganizationId: (activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id) || ''
            }
        }
    });
    const handleModalUpdate = (adjudicationAreas) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        try {
            if (!exists(activeOrganization)) {
                return;
            }
            if (!user) {
                getErrorReporter().logAndCaptureError(ColumnService.SETTINGS_MANAGEMENT, null, 'Failed to save adjudication areas - missing user', {
                    activeOrganizationId: (activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id) || ''
                });
                return;
            }
            yield getFirebaseContext()
                .eventsRef()
                .add(removeUndefinedFields({
                type: ADJUDICATION_AREA_SET,
                publisher: activeOrganization.ref,
                createdAt: getFirebaseContext().timestamp(),
                data: {
                    before: (_a = activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.adjudicationAreas,
                    after: adjudicationAreas,
                    changedBy: user === null || user === void 0 ? void 0 : user.ref
                }
            }));
            yield activeOrganization.ref.update({
                adjudicationAreas
            });
            dispatch(ToastActions.toastSuccess({
                headerText: 'Success',
                bodyText: 'Adjudication areas updated successfully.'
            }));
        }
        catch (err) {
            getErrorReporter().logAndCaptureError(ColumnService.SETTINGS_MANAGEMENT, err, 'Failed to save adjudication areas', {
                activeOrganizationId: (activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id) || ''
            });
            dispatch(ToastActions.toastError({
                headerText: 'Error',
                bodyText: 'Something wrong happened - adjudication areas were not updated.'
            }));
        }
    });
    if (!selectedAreasGroupedByState)
        return null;
    const displayAddButton = shouldDisplayAddButton(activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data());
    const displayEditButton = !displayAddButton;
    return (_jsx("div", Object.assign({ className: "w-full p-4 mx-auto max-w-4xl" }, { children: _jsxs("div", Object.assign({ className: "bg-white border border-column-gray-100 shadow-column-3 rounded-md p-8 flex flex-col gap-4" }, { children: [_jsx(AdjudicationSectionHeader, Object.assign({ header: "Adjudication Area" }, { children: displayEditButton && (_jsx(ColumnButton, { onClick: () => setOpen(true), buttonText: "Edit", type: "button", rounded: "all" })) })), _jsx(Alert, { id: "eedition-drawer-missing-upload-alert", status: "info", icon: _jsx(InformationCircleIcon, { className: "w-5 h-5" }), title: "Where do you publish public notices?", description: "Let customers know how to find and submit notices to your\n              publication." }), _jsx(AdjudicationInfoContent, { loading: loadingAreas, error: areasError, open: open, selectedAreasGroupedByState: selectedAreasGroupedByState, adjudicationAreasData: adjudicationAreasWithId, onAdjudicationAreasSelectionSaved: handleModalUpdate, onModalStateChanged: (open) => setOpen(open), displayAddButton: displayAddButton })] })) })));
}
