var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TabGroup from 'lib/components/Tabs';
import { useAppSelector } from 'redux/hooks';
import { selectActiveOrganization } from 'redux/auth';
import { exists } from 'lib/types';
import { ColumnSelect } from 'lib/components/ColumnSelect';
import { PRODUCT_TO_NAME } from 'lib/enums/Product';
import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { useMemo, useState } from 'react';
import SettingsPage from 'routes/settings/SettingsPage';
import { userHasPermission } from 'utils/permissions';
import { Permissions } from 'lib/permissions/roles';
import Header from 'components/Header';
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { PublishingMedium } from 'lib/enums/PublishingMedium';
import { Alert } from 'lib/components/Alert';
import { LoadingSpinner } from 'lib/components/LoadingSpinner';
import { ProductPublishingSettingsService } from 'lib/services/productPublishingSettingsService';
import { getFirebaseContext } from 'utils/firebase';
import { ColumnService } from 'lib/services/directory';
import RegularProductDeadlines from '../deadlines/RegularProductDeadlines';
import ProductScheduleChanges from '../deadlines/ProductScheduleChanges';
import RatesSettings from './RatesSettings';
import ProductLineFilingTypeSettings from './FilingTypeSettings';
import AdTemplateTable from '../designAndLayoutSettings/adTemplateTable';
import SiteSettings from './SiteSettings';
export const PRODUCT_SETTINGS_REGULAR_DEADLINES = {
    label: 'Regular Deadlines',
    enabled: true,
    id: 'regular-deadlines-tab'
};
export const PRODUCT_SETTINGS_SCHEDULE_CHANGES = {
    label: 'Schedule Changes',
    enabled: true,
    id: 'schedule-changes-tab'
};
export const PRODUCT_SETTINGS_RATES = {
    label: 'Rates',
    enabled: true,
    id: 'rates-tab'
};
export const PRODUCT_SETTINGS_CATEGORIES = {
    label: 'Categories',
    enabled: true,
    id: 'categories-tab'
};
export const PRODUCT_SETTINGS_DESIGN_AND_LAYOUT = {
    label: 'Design and Layout',
    enabled: true,
    id: 'design-and-layout-tab'
};
export const PRODUCT_SETTINGS_DISPLAY_SITE = {
    label: 'Display Site',
    enabled: true,
    id: 'display-site-tab'
};
export const PRODUCT_SETTINGS_TABS = [
    PRODUCT_SETTINGS_REGULAR_DEADLINES,
    PRODUCT_SETTINGS_SCHEDULE_CHANGES,
    PRODUCT_SETTINGS_RATES,
    PRODUCT_SETTINGS_CATEGORIES,
    PRODUCT_SETTINGS_DESIGN_AND_LAYOUT,
    PRODUCT_SETTINGS_DISPLAY_SITE
];
export default function ProductSettings({ productLine, user }) {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const [publishingMedium, setPublishingMedium] = useState(PublishingMedium.Print);
    const showPublishingMediumsSelect = getBooleanFlag(LaunchDarklyFlags.ENABLE_PUBLISHING_MEDIUMS_SETTINGS);
    // Map of user permissions to product settings tabs
    const userPermissionsMap = useMemo(() => {
        return {
            [PRODUCT_SETTINGS_REGULAR_DEADLINES.id]: userHasPermission(user, Permissions.SETTINGS_DEADLINES),
            [PRODUCT_SETTINGS_SCHEDULE_CHANGES.id]: userHasPermission(user, Permissions.SETTINGS_DEADLINES),
            [PRODUCT_SETTINGS_RATES.id]: userHasPermission(user, Permissions.SETTINGS_RATES),
            [PRODUCT_SETTINGS_CATEGORIES.id]: userHasPermission(user, Permissions.SETTINGS_ORDER_TYPES),
            [PRODUCT_SETTINGS_DESIGN_AND_LAYOUT.id]: userHasPermission(user, Permissions.SETTINGS_DESIGN_AND_LAYOUT),
            [PRODUCT_SETTINGS_DISPLAY_SITE.id]: userHasPermission(user, Permissions.SETTINGS_PRODUCT_SITE)
        };
    }, [user, productLine]);
    // Filter out tabs that the user does not have permission to view
    const permissionedTabs = PRODUCT_SETTINGS_TABS.filter(tab => {
        return userPermissionsMap[tab.id];
    });
    const [activeSettingsTab, setActiveSettingsTab] = useState(permissionedTabs[0]);
    const productPublishingSettingService = new ProductPublishingSettingsService(getFirebaseContext());
    const { value: detailedPublishingSetting, isLoading: detailedPublishingSettingLoading, isError: detailedPublishingSettingError } = useSafeAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () {
            return yield productPublishingSettingService.fetchOrCreateDetailedProductPublishingSetting(activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.ref, productLine, publishingMedium, {
                shouldCreate: true
            });
        }),
        initialData: null,
        dependencies: [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id, productLine, publishingMedium],
        errorConfig: {
            service: ColumnService.OBITS,
            message: 'Error loading product settings',
            tags: {
                organizationId: (activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id) || 'unknown',
                productLine
            }
        }
    });
    if (!exists(activeOrganization) || detailedPublishingSettingLoading) {
        return _jsx(LoadingSpinner, {});
    }
    if (!detailedPublishingSetting || detailedPublishingSettingError) {
        return (_jsx(Alert, Object.assign({ status: "error", id: "product-setting-load-error" }, { children: "Failed to load product settings" })));
    }
    const { publishingSetting, filingTypes } = detailedPublishingSetting;
    const componentMap = {
        [PRODUCT_SETTINGS_REGULAR_DEADLINES.id]: (_jsx(RegularProductDeadlines, { productLine: productLine, publishingSetting: publishingSetting })),
        [PRODUCT_SETTINGS_SCHEDULE_CHANGES.id]: (_jsx(ProductScheduleChanges, { activeOrganization: activeOrganization, productLine: productLine, publishingSetting: publishingSetting })),
        [PRODUCT_SETTINGS_RATES.id]: (_jsx(RatesSettings, { activeOrganization: activeOrganization, productLine: productLine })),
        [PRODUCT_SETTINGS_CATEGORIES.id]: (_jsx(ProductLineFilingTypeSettings, { productLine: productLine, activeOrganization: activeOrganization, filingTypes: filingTypes })),
        [PRODUCT_SETTINGS_DESIGN_AND_LAYOUT.id]: (_jsx(AdTemplateTable, { activeOrganization: activeOrganization, productLine: productLine, publishingMedium: publishingMedium })),
        [PRODUCT_SETTINGS_DISPLAY_SITE.id]: (_jsx(SiteSettings, { activeOrganization: activeOrganization, productLine: productLine }))
    };
    const productName = PRODUCT_TO_NAME[productLine];
    return (_jsxs(SettingsPage, { children: [_jsx(Header, { productLine: productLine }), _jsxs("div", Object.assign({ className: "p-6 border-b" }, { children: [_jsxs("h2", Object.assign({ className: "font-medium text-xl text-column-gray-500" }, { children: [productName.plural, " Settings"] })), showPublishingMediumsSelect && (_jsx("div", Object.assign({ className: "mt-4" }, { children: _jsx(ColumnSelect, { id: 'publishing-medium-select', labelText: 'Publishing Medium', value: publishingMedium, options: [
                                { value: PublishingMedium.Print, label: 'Print' },
                                { value: PublishingMedium.Online, label: 'Online' },
                                { value: PublishingMedium.EEdition, label: 'E-Edition' }
                            ], onChange: setPublishingMedium }) })))] })), _jsx(TabGroup, { onClickTab: setActiveSettingsTab, activeTab: activeSettingsTab, tabs: permissionedTabs, id: 'product-settings-tab-group' }), userPermissionsMap[activeSettingsTab.id]
                ? componentMap[activeSettingsTab.id]
                : null] }));
}
