import { jsx as _jsx } from "react/jsx-runtime";
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Badge } from 'lib/components/Badge';
const getAreaLabelFromMap = (area, selectedAreasGroupingReference) => {
    var _a;
    if (area.type === 'county') {
        const parentId = (_a = area.parent) === null || _a === void 0 ? void 0 : _a.id;
        if (parentId && selectedAreasGroupingReference[parentId])
            return `[${selectedAreasGroupingReference[parentId].name}] ${area.name}`;
        return area.name;
    }
    if (area.type === 'place') {
        // California is the only state where we have places available for selection
        return `[California] ${area.name}`;
    }
    return area.name;
};
export function AdjudicationSelectedBadge({ selectedArea, onBadgeClicked, selectedAreasGroupingReference }) {
    const label = getAreaLabelFromMap(selectedArea, selectedAreasGroupingReference);
    return (_jsx("div", Object.assign({ className: "cursor-pointer" }, { children: _jsx(Badge, Object.assign({ startIcon: _jsx(XMarkIcon, { className: "w-3" }), status: "info", size: "md", onClick: () => {
                onBadgeClicked(selectedArea);
            } }, { children: label })) })));
}
