import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { DataTableColumnHeader } from './cells/DataTableColumnHeader';
export function DataTableHeader({ columns, columnAlignment, headers, condensed = false, onSort, sortableColumns, initialSort }) {
    const [sort, setSort] = useState(initialSort);
    const sortColumn = (key) => {
        var _a;
        if (!onSort || !sortableColumns) {
            return;
        }
        const primarySort = (_a = sortableColumns[key]) !== null && _a !== void 0 ? _a : 'asc';
        const secondarySort = primarySort === 'asc' ? 'desc' : 'asc';
        if ((sort === null || sort === void 0 ? void 0 : sort.key) !== key) {
            setSort({ key, direction: primarySort });
            onSort(key, primarySort);
            return;
        }
        if (sort.direction === primarySort) {
            setSort({ key, direction: secondarySort });
            onSort(key, secondarySort);
            return;
        }
        setSort(undefined);
        onSort(key, 'none');
    };
    return (_jsx("thead", { children: _jsx("tr", Object.assign({ className: "text-sm" }, { children: columns.map(column => {
                const cell = headers[column];
                const alignment = columnAlignment === null || columnAlignment === void 0 ? void 0 : columnAlignment[column];
                const sortable = sortableColumns === null || sortableColumns === void 0 ? void 0 : sortableColumns[column];
                return (_jsx(DataTableColumnHeader, Object.assign({ column: column, alignment: alignment, condensed: condensed, onSortColumn: sortable && (() => sortColumn(column)), sortDirection: sortable && ((sort === null || sort === void 0 ? void 0 : sort.key) === column ? sort.direction : 'none') }, { children: cell }), column));
            }) })) }));
}
