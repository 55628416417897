var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getOrThrow } from '../utils/refs';
import { safeAsync } from '../safeWrappers';
import { removeUndefinedFields } from '../helpers';
export const eventRefDocumentTypes = {
    advertiserOrganization: 'Advertiser Organization',
    invite: 'Invite',
    invoice: 'Invoice',
    newspaper: 'Newspaper',
    notice: 'Notice',
    publicationIssue: 'Publication Issue',
    user: 'User'
};
export const documentTypeToCollection = (documentType) => {
    switch (documentType) {
        case 'invoice':
            return 'invoices';
        case 'notice':
            return 'usernotices';
        case 'newspaper':
            return 'organizations';
        case 'user':
            return 'users';
        case 'advertiserOrganization':
            return 'organizations';
        case 'publicationIssue':
            return 'publicationIssues';
        case 'invite':
            return 'invites';
        default:
            throw new Error(`Unknown document type: ${documentType}`);
    }
};
const documentTypeToQueryProp = (documentType) => {
    switch (documentType) {
        case 'invoice':
            return 'data.invoice';
        case 'notice':
            return 'notice';
        case 'newspaper':
            return 'newspaper';
        case 'user':
            return 'user';
        case 'advertiserOrganization':
            return 'organization';
        case 'publicationIssue':
            return 'publicationIssue';
        case 'invite':
            return 'invite';
        default:
            throw new Error(`Unknown document type: ${documentType}`);
    }
};
export class EventService {
    constructor(context) {
        this.context = context;
    }
    getEventsForDocument(query) {
        return __awaiter(this, void 0, void 0, function* () {
            const { documentId, documentType } = query;
            const queryProp = documentTypeToQueryProp(documentType);
            const collectionName = documentTypeToCollection(documentType);
            const ref = this.context.doc(`${collectionName}/${documentId}`);
            yield getOrThrow(ref);
            const eventQuery = this.context.eventsRef().where(queryProp, '==', ref);
            const queryResults = yield eventQuery.get();
            return queryResults.docs;
        });
    }
    createOrderEvent(order, type, data) {
        return __awaiter(this, void 0, void 0, function* () {
            return safeAsync(() => __awaiter(this, void 0, void 0, function* () {
                return this.context.eventsRef().add({
                    createdAt: this.context.fieldValue().serverTimestamp(),
                    order,
                    type,
                    data: removeUndefinedFields(data)
                });
            }))();
        });
    }
}
