var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ProductPublishingSettingsService } from '../services/productPublishingSettingsService';
import { wrapError, wrapSuccess } from '../types/responses';
export const getPublisherOrgTemplatesQueries = (ctx, org) => {
    const ownedTemplatesQuery = ctx
        .adTemplatesRef()
        .where('organization', '==', org);
    const allowedTemplatesQuery = ctx
        .adTemplatesRef()
        .where('publisherOrganizations', 'array-contains', org);
    return {
        ownedTemplatesQuery,
        allowedTemplatesQuery
    };
};
export const getPublisherOrgTemplates = (ctx, org) => __awaiter(void 0, void 0, void 0, function* () {
    const { ownedTemplatesQuery, allowedTemplatesQuery } = getPublisherOrgTemplatesQueries(ctx, org);
    const ownedTemplates = yield ownedTemplatesQuery.get();
    const allowedTemplates = yield allowedTemplatesQuery.get();
    return [...ownedTemplates.docs, ...allowedTemplates.docs];
});
export const isTemplateShared = (template) => {
    var _a;
    return !!((_a = template.publisherOrganizations) === null || _a === void 0 ? void 0 : _a.length);
};
// Fetch the template of the given product line from the organization publishing settings
export const getPublisherOrgOrderTemplate = (ctx, org, productLine, publishingMedium) => __awaiter(void 0, void 0, void 0, function* () {
    const productPublishingSettingService = new ProductPublishingSettingsService(ctx);
    const { response, error } = yield productPublishingSettingService.fetchOrCreateProductPublishingSetting(org, productLine, publishingMedium);
    if (error) {
        return wrapError(error);
    }
    const { response: publishingSetting, error: fetchPublishingSettingError } = yield response.fetchPublishingSetting();
    if (fetchPublishingSettingError) {
        return wrapError(fetchPublishingSettingError);
    }
    return wrapSuccess(publishingSetting.modelData.adTemplate);
});
