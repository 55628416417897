import { getBooleanFlag } from 'utils/flags';
import { LaunchDarklyFlags } from 'lib/types/launchDarklyFlags';
import { createReducer, createActions } from 'reduxsauce';
import { createSelector } from 'reselect';
import { getSubdomain } from 'utils/urls';
import { getLocationParams } from 'lib/frontend/utils/browser';
import { getFirebaseContext } from 'utils/firebase';
import { OccupationType, OrganizationType, Product } from 'lib/enums';
import { exists } from 'lib/types';
import { getModelFromSnapshot } from 'lib/model';
import { OrganizationModel } from 'lib/model/objects/organizationModel';
/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
    anonymousLogin: [],
    logout: [],
    setUser: ['user'],
    startAuth: [],
    endAuth: [],
    resetPassword: ['email'],
    setUserAuth: ['userAuth'],
    loginToken: ['token'],
    register: [],
    setOrganization: ['organization'],
    setActiveOrganization: ['activeOrganization'],
    setAvailableOrganizations: ['availableOrganizations'],
    showAllOrgsNotices: ['showAllOrgsNotices'],
    setOrgContext: ['orgContext'],
    logoutSuccess: [],
    setAuthError: ['error'],
    setShowPasswordReset: ['showPasswordReset'],
    setTemporaryPassword: ['temporaryPassword'],
    setImpersonating: ['impersonating'],
    setClaimLogin: ['isClaimLogin'],
    addOrderIdClaim: ['orderIdClaim']
});
export const AuthTypes = Types;
export default Creators;
export const INITIAL_STATE = {
    previousUser: null,
    user: null,
    userAuth: null,
    isPublisher: false,
    organization: null,
    activeOrganization: null,
    availableOrganizations: [],
    alwaysAllowAffidavitDownload: false,
    error: '',
    orgContext: null,
    showPasswordReset: false,
    loading: true,
    impersonating: false,
    isClaimLogin: false,
    orderIdClaims: []
};
export const authSelector = (state) => state.auth;
export const selectIsPublisher = createSelector([authSelector], auth => auth.isPublisher);
export const selectTemporaryPassword = createSelector([authSelector], auth => auth.temporaryPassword);
export const selectIsColumnRep = createSelector([authSelector], auth => { var _a; return !!((_a = auth.user) === null || _a === void 0 ? void 0 : _a.data().isColumnRep); });
export const selectUser = createSelector([authSelector], auth => auth.user);
export const selectAuthLoading = createSelector([authSelector], auth => auth.loading);
export const selectAuthError = createSelector([authSelector], auth => auth.error);
export const selectUserAuth = createSelector([authSelector], auth => auth.userAuth);
export const selectActiveOrganization = createSelector([authSelector], auth => auth.activeOrganization);
export const selectUserAuthIsSet = createSelector([selectUserAuth], userAuth => Boolean(userAuth));
export const selectAnonymousUserId = createSelector([selectUserAuth], userAuth => ((userAuth === null || userAuth === void 0 ? void 0 : userAuth.isAnonymous) ? userAuth === null || userAuth === void 0 ? void 0 : userAuth.uid : null));
export const selectAvailableOrganizations = createSelector([authSelector], auth => auth.availableOrganizations);
export const selectShowAllOrgsNotices = createSelector([authSelector], auth => auth.showAllOrgsNotices);
export const selectOrgContext = createSelector([authSelector], auth => auth.orgContext);
export const selectContextOrganizationId = createSelector([selectOrgContext], orgContext => orgContext === null || orgContext === void 0 ? void 0 : orgContext.id);
export const selectOrgContextRef = createSelector([selectContextOrganizationId], orgContextId => {
    if (!orgContextId)
        return null;
    return getFirebaseContext().organizationsRef().doc(orgContextId);
});
export const selectContextOrganizationName = createSelector([selectOrgContext], orgContext => orgContext === null || orgContext === void 0 ? void 0 : orgContext.name);
export const selectIsContextOrganizationPublisher = createSelector([selectOrgContext], orgContext => (orgContext === null || orgContext === void 0 ? void 0 : orgContext.organizationType) === OrganizationType.newspaper.value);
export const selectIsOnSubdomain = createSelector([selectOrgContext], orgContext => {
    return getSubdomain() === (orgContext === null || orgContext === void 0 ? void 0 : orgContext.subdomain);
});
export const selectOrganizationHeaderLogo = createSelector([selectOrgContext, selectIsOnSubdomain], (orgContext, isOnSubdomain) => {
    const subdomainLogo = getLocationParams().get('logo');
    if (subdomainLogo)
        return subdomainLogo;
    return isOnSubdomain ? orgContext === null || orgContext === void 0 ? void 0 : orgContext.filingFlowSubdomainImage : null;
});
export const selectIsUserLoggedOut = createSelector([selectAuthLoading, selectUserAuthIsSet], (loading, userAuthIsSet) => {
    // If the auth state is completely done loading and there's no userAuth, we're not authenticated in Firebase via email or anonymous login
    return !loading && !userAuthIsSet;
});
export const selectIsUserLoggedIn = createSelector([selectAuthLoading, selectAuthError, selectUserAuthIsSet], (loading, error, userAuthIsSet) => {
    // If the auth state is completely done loading and there is a userAuth, we have a valid Firebase authentication
    return Boolean((!loading || error) && userAuthIsSet);
});
export const selectIsImpersonating = createSelector([authSelector], auth => !!auth.impersonating);
export const selectIsClaimLogin = createSelector([authSelector], auth => !!auth.isClaimLogin);
export const selectOrderIdClaims = createSelector([authSelector], auth => auth.orderIdClaims);
export const selectActiveOrganizationModel = createSelector([selectActiveOrganization], activeOrganization => {
    return exists(activeOrganization)
        ? getModelFromSnapshot(OrganizationModel, getFirebaseContext(), activeOrganization)
        : null;
});
export const selectIsObituariesActiveOnSomeAvailableOrg = createSelector([selectAvailableOrganizations], availableOrganizations => !!availableOrganizations
    .map(org => getModelFromSnapshot(OrganizationModel, getFirebaseContext(), org))
    .some(orgModal => orgModal.hasAdTypeActive(Product.Obituary)));
export const selectIsClassifiedsActiveOnSomeAvailableOrg = createSelector([selectAvailableOrganizations], availableOrganizations => !!availableOrganizations
    .map(org => getModelFromSnapshot(OrganizationModel, getFirebaseContext(), org))
    .some(orgModal => orgModal.hasAdTypeActive(Product.Classified)));
export const selectHasObituariesActive = createSelector([
    selectShowAllOrgsNotices,
    selectActiveOrganizationModel,
    selectIsObituariesActiveOnSomeAvailableOrg
], (showAllOrgsNotices, activeOrganization, isObituariesActiveOnSomeAvailableOrg) => {
    if (showAllOrgsNotices) {
        return isObituariesActiveOnSomeAvailableOrg;
    }
    return !!(activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.hasAdTypeActive(Product.Obituary));
});
export const selectHasClassifiedsActive = createSelector([
    selectIsPublisher,
    selectShowAllOrgsNotices,
    selectActiveOrganizationModel,
    selectIsClassifiedsActiveOnSomeAvailableOrg
], (isPublisher, showAllOrgsNotices, activeOrganization, isClassifiedsActiveOnSomeAvailableOrg) => {
    if (showAllOrgsNotices) {
        return isClassifiedsActiveOnSomeAvailableOrg;
    }
    if (isPublisher) {
        return !!(activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.hasAdTypeActive(Product.Classified));
    }
    const classifiedsCustomersEnabled = getBooleanFlag(LaunchDarklyFlags.ENABLE_CLASSIFIEDS_CUSTOMERS);
    return (classifiedsCustomersEnabled &&
        (!activeOrganization ||
            (activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.hasAdTypeActive(Product.Classified))));
});
export const selectHasPublicNoticesActive = createSelector([selectAvailableOrganizations, selectActiveOrganization], (availableOrganizations, activeOrganization) => !activeOrganization ||
    !!availableOrganizations
        .map(org => getModelFromSnapshot(OrganizationModel, getFirebaseContext(), org))
        .some(orgModal => orgModal.hasAdTypeActive(Product.Notice)));
export const selectDefaultOrdersRoute = createSelector([selectActiveOrganizationModel], activeOrganization => !activeOrganization || activeOrganization.hasAdTypeActive(Product.Notice)
    ? '/notices'
    : activeOrganization.hasAdTypeActive(Product.Obituary)
        ? '/obituaries'
        : '/classifieds');
/* ------------- Reducer ------------- */
export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_USER]: (state, { user }) => (Object.assign(Object.assign({}, state), { isPublisher: user.data().occupation === OccupationType.publishing.value, alwaysAllowAffidavitDownload: user.data().alwaysAllowAffidavitDownload || false, previousUser: state.user, user })),
    [Types.SET_USER_AUTH]: (state, { userAuth }) => (Object.assign(Object.assign({}, state), { userAuth })),
    [Types.START_AUTH]: state => (Object.assign(Object.assign({}, state), { loading: true })),
    [Types.END_AUTH]: state => (Object.assign(Object.assign({}, state), { loading: false })),
    [Types.SET_ORGANIZATION]: (state, { organization }) => (Object.assign(Object.assign({}, state), { organization })),
    [Types.SET_AVAILABLE_ORGANIZATIONS]: (state, { availableOrganizations }) => (Object.assign(Object.assign({}, state), { availableOrganizations })),
    [Types.SET_ACTIVE_ORGANIZATION]: (state, { activeOrganization }) => (Object.assign(Object.assign({}, state), { activeOrganization })),
    [Types.SHOW_ALL_ORGS_NOTICES]: (state, { showAllOrgsNotices }) => (Object.assign(Object.assign({}, state), { showAllOrgsNotices })),
    [Types.SET_AUTH_ERROR]: (state, { error }) => (Object.assign(Object.assign({}, state), { error })),
    [Types.LOGOUT_SUCCESS]: () => INITIAL_STATE,
    [Types.SET_ORG_CONTEXT]: (state, { orgContext }) => (Object.assign(Object.assign({}, state), { orgContext })),
    [Types.SET_SHOW_PASSWORD_RESET]: (state, { showPasswordReset }) => (Object.assign(Object.assign({}, state), { showPasswordReset })),
    [Types.SET_TEMPORARY_PASSWORD]: (state, { temporaryPassword }) => (Object.assign(Object.assign({}, state), { temporaryPassword })),
    [Types.SET_IMPERSONATING]: (state, { impersonating }) => (Object.assign(Object.assign({}, state), { impersonating })),
    [Types.SET_CLAIM_LOGIN]: (state, { isClaimLogin }) => (Object.assign(Object.assign({}, state), { isClaimLogin })),
    [Types.ADD_ORDER_ID_CLAIM]: (state, { orderIdClaim }) => (Object.assign(Object.assign({}, state), { orderIdClaims: [...state.orderIdClaims, orderIdClaim] }))
});
