var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { exists } from 'lib/types';
import { Form } from 'lib/components/Form';
import UploadButton from 'components/UploadButton';
import LoadingState from 'components/LoadingState';
import { ColumnButton } from 'lib/components/ColumnButton';
import { useGetDocumentProperty } from 'components/hooks/useGetDocumentProperty';
import CardWithHeader from 'lib/components/Card/CardWithHeader';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { useAppDispatch } from 'redux/hooks';
import ToastActions from 'redux/toast';
import { logError } from 'utils/logger';
import SettingsHeader from './SettingsHeader';
function CustomBrandSettings({ user, activeOrganization, description = 'This logo will appear on your public-facing search page and emails sent to clients through Column.', showDeleteButton = false }) {
    const [icon, setIcon] = useGetDocumentProperty(activeOrganization.ref, 'icon');
    const dispatch = useAppDispatch();
    if (!exists(user))
        return _jsx(LoadingState, {});
    const handleDelete = () => __awaiter(this, void 0, void 0, function* () {
        try {
            yield activeOrganization.ref.update({
                icon: '',
                darkLogo: ''
            });
            setIcon('');
            dispatch(ToastActions.toastSuccess({
                headerText: 'Success',
                bodyText: 'Icon removed successfully'
            }));
        }
        catch (error) {
            logError('Failed to delete organization icon', {
                activeOrganization: activeOrganization.id,
                user: user.id
            });
            dispatch(ToastActions.toastError({
                headerText: 'Error',
                bodyText: 'Failed to remove icon'
            }));
        }
    });
    return (_jsx("div", Object.assign({ className: "bg-white rounded-lg border border-gray-300 shadow" }, { children: _jsxs(Form, Object.assign({ id: "branding-settings-table", onSubmit: () => __awaiter(this, void 0, void 0, function* () {
                try {
                    if (icon) {
                        yield activeOrganization.ref.update({
                            // The logo that shows during restricted placement flow
                            icon,
                            // The logo that shows on the display site
                            darkLogo: icon
                        });
                    }
                    dispatch(ToastActions.toastSuccess({
                        headerText: 'Success',
                        bodyText: 'Update successful'
                    }));
                }
                catch (error) {
                    logError('Failed to update organization icon', {
                        activeOrganization: activeOrganization.id,
                        user: user.id
                    });
                    dispatch(ToastActions.toastError({
                        headerText: 'Error',
                        bodyText: 'Update failed'
                    }));
                }
            }) }, { children: [_jsx(SettingsHeader, Object.assign({ header: "Custom Branding", description: "Configure your branding." }, { children: _jsx(ColumnButton, { primary: true, type: "submit", buttonText: "Save" }) })), _jsx(CardWithHeader, Object.assign({ header: {
                        title: 'Organization Logo',
                        description
                    } }, { children: _jsx("div", Object.assign({ className: "flex flex-row items-center" }, { children: _jsxs("div", Object.assign({ className: "flex items-center gap-4" }, { children: [icon && (_jsx("img", { style: { maxHeight: 80, maxWidth: 100 }, src: icon, alt: "icon" })), _jsx(UploadButton, { folder: `documentcloud/${activeOrganization.id}/icons/`, accept: "image/x-png,image/gif,image/jpeg", label: icon ? 'Update Icon' : 'Choose Icon', processUpload: (file) => __awaiter(this, void 0, void 0, function* () { return setIcon(yield file.ref.getDownloadURL()); }) }), showDeleteButton && (_jsx(ColumnButton, { type: "button", onClick: handleDelete, buttonText: "Remove Icon", startIcon: _jsx(XCircleIcon, { className: "h-5 w-5" }) }))] })) })) }))] })) })));
}
export default CustomBrandSettings;
