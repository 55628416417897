var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { ColumnService } from 'lib/services/directory';
import { getErrorReporter } from '../../utils/errors';
// TODO: Consider how we can deduplicate code between this and useAsyncEffect
export default function useSafeAsyncEffect({ fetchData, dependencies, initialData = null, errorConfig }) {
    const [value, setValue] = useState(initialData);
    const [status, setStatus] = useState('loading');
    useEffect(() => {
        let current = true;
        const fetch = () => __awaiter(this, void 0, void 0, function* () {
            setStatus('loading');
            const { response: fetchedData, error: fetchError } = yield fetchData();
            if (fetchError) {
                getErrorReporter().logAndCaptureError((errorConfig === null || errorConfig === void 0 ? void 0 : errorConfig.service) || ColumnService.UNKNOWN, fetchError, (errorConfig === null || errorConfig === void 0 ? void 0 : errorConfig.message) || 'Error in useSafeAsyncEffect', errorConfig === null || errorConfig === void 0 ? void 0 : errorConfig.tags);
                setStatus('error');
                return;
            }
            if (current) {
                setValue(fetchedData);
                setStatus('idle');
            }
        });
        void fetch();
        return () => {
            current = false;
        };
    }, dependencies);
    return {
        value,
        isLoading: status === 'loading',
        isError: status === 'error'
    };
}
