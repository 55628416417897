var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ColumnService } from 'lib/services/directory';
import { getStateLabelByValue } from 'lib/utils/states';
import { logAndCaptureException } from 'utils';
export const newspaperOrderToPublicationCard = (newspaperOrder, filingTypeName) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const { response: newspaper, error: getNewspaperError } = yield newspaperOrder.getNewspaper();
    if (getNewspaperError) {
        logAndCaptureException(ColumnService.OBITS, getNewspaperError, 'Error getting newspaper from newspaper order', {
            newspaperOrderpath: newspaperOrder.ref.path,
            service: ColumnService.OBITS
        });
        throw getNewspaperError;
    }
    const { response: deadline, error: getDeadlineError } = yield newspaperOrder.getDeadline();
    if (getDeadlineError) {
        logAndCaptureException(ColumnService.OBITS, getDeadlineError, 'Error getting deadline from newspaper order', {
            newspaperOrderpath: newspaperOrder.ref.path,
            service: ColumnService.OBITS
        });
        throw getDeadlineError;
    }
    const formattedDate = (_a = deadline.format('MMMM DD, YYYY h:mm A z')) !== null && _a !== void 0 ? _a : '';
    const publicationCard = {
        status: newspaperOrder.modelData.status,
        newspaper,
        publisherName: newspaper.modelData.name,
        state: getStateLabelByValue(newspaper.modelData.state) || '',
        publicationCategory: filingTypeName || '',
        adDeadline: formattedDate,
        publicationSchedule: newspaperOrder.modelData.publishingDates
    };
    return publicationCard;
});
