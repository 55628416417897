var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import useAsyncEffect from 'lib/frontend/hooks/useAsyncEffect';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectActiveOrganization, selectIsPublisher, selectShowAllOrgsNotices, selectUser } from 'redux/auth';
import { Alert } from 'lib/components/Alert';
import { logAndCaptureException, logAndCaptureMessage } from 'utils';
import { apiPost } from 'api/typed';
import { debounce } from 'lodash';
import { safeStringify } from 'lib/utils/stringify';
import { ColumnButton } from 'lib/components/ColumnButton';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { push } from 'connected-react-router';
import { SORT_ASCENDING } from 'lib/types/searchable';
import { ColumnService } from 'lib/services/directory';
import { Product } from 'lib/enums';
import AdsTable from './AdsTable';
import { CANCELLED_ORDERS_TAB, COMPLETED_ORDERS_TAB, DRAFT_ORDERS_TAB, IN_PROGRESS_ORDERS_TAB, ORDER_STATUS_TABS } from './tabs';
import { DEFAULT_ORDER_FILTER, getOrderFilters } from './filters/helpers';
import { AdsTableColumns } from './types';
const OBIT_TABLE_PUBLISHER_COLUMNS = [
    AdsTableColumns.DECEASED_NAME,
    AdsTableColumns.AD_DEADLINE,
    AdsTableColumns.CUSTOMER_NAME,
    AdsTableColumns.CATEGORY,
    AdsTableColumns.PUBLICATION_DATE,
    AdsTableColumns.CUSTOMER_TYPE,
    AdsTableColumns.VERIFICATION,
    AdsTableColumns.STATUS
];
const OBIT_TABLE_PUBLISHER_COLUMNS_WITH_NEWSPAPER_NAME = [
    ...OBIT_TABLE_PUBLISHER_COLUMNS.slice(0, 3),
    AdsTableColumns.NEWSPAPER_NAME,
    ...OBIT_TABLE_PUBLISHER_COLUMNS.slice(3)
];
const OBIT_TABLE_FUNERAL_HOME_COLUMNS = [
    AdsTableColumns.DECEASED_NAME,
    AdsTableColumns.AD_DEADLINE,
    AdsTableColumns.PUBLICATION,
    AdsTableColumns.CATEGORY,
    AdsTableColumns.PUBLICATION_DATE,
    AdsTableColumns.STATUS
];
const getTableColumns = (isPublisher, tableTab, showAllOrgsNotices = false) => {
    const defaultObitsTablePublisherColumns = showAllOrgsNotices
        ? OBIT_TABLE_PUBLISHER_COLUMNS_WITH_NEWSPAPER_NAME
        : OBIT_TABLE_PUBLISHER_COLUMNS;
    const defaultColumns = isPublisher
        ? defaultObitsTablePublisherColumns
        : OBIT_TABLE_FUNERAL_HOME_COLUMNS;
    switch (tableTab.id) {
        case IN_PROGRESS_ORDERS_TAB.id:
        case COMPLETED_ORDERS_TAB.id:
            return defaultColumns;
        case DRAFT_ORDERS_TAB.id:
        case CANCELLED_ORDERS_TAB.id:
            return defaultColumns.filter(tab => tab !== AdsTableColumns.STATUS);
        default:
            logAndCaptureMessage('Unexpected tab selected in obits table', {
                tableTab: tableTab.id
            });
            return defaultColumns;
    }
};
function ObitsTableContainer() {
    const activeOrganization = useAppSelector(selectActiveOrganization);
    const isPublisher = useAppSelector(selectIsPublisher);
    const showAllOrgsNotices = useAppSelector(selectShowAllOrgsNotices);
    const user = useAppSelector(selectUser);
    const [selectedAdTableTab, setSelectedAdTableTab] = useState(ORDER_STATUS_TABS[0]);
    const [userError, setUserError] = useState();
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [rowFilter, setRowFilter] = useState(DEFAULT_ORDER_FILTER);
    const dispatch = useAppDispatch();
    const allowedOrganizations = user === null || user === void 0 ? void 0 : user.data().allowedOrganizations;
    const loadObituaries = () => __awaiter(this, void 0, void 0, function* () {
        setUserError(undefined);
        if (!activeOrganization || !selectedAdTableTab) {
            return [];
        }
        const filters = getOrderFilters({
            product: Product.Obituary,
            activeOrganization,
            selectedAdTableTab,
            tableFilters: rowFilter,
            isPublisher,
            allowedOrganizations,
            showAllOrgsNotices
        });
        const result = yield apiPost('search/orders', {
            search: debouncedSearchTerm,
            filters,
            sort: [{ addeadline: SORT_ASCENDING }]
        });
        if (!result.success) {
            logAndCaptureException(ColumnService.OBITS, result.error, 'Error loading obituaries table', {
                activeOrganization: activeOrganization.id
            });
            setUserError('There was an error loading the data. Please try again.');
            return;
        }
        return result.results;
    });
    const { isLoading, value: obitTableData } = useAsyncEffect({
        fetchData: loadObituaries,
        dependencies: [
            !!activeOrganization,
            isPublisher,
            selectedAdTableTab === null || selectedAdTableTab === void 0 ? void 0 : selectedAdTableTab.id,
            debouncedSearchTerm,
            safeStringify(rowFilter)
        ]
    });
    const updateSearch = useMemo(() => debounce(value => {
        setDebouncedSearchTerm(value);
    }, 400), []);
    useEffect(() => {
        updateSearch(searchTerm);
    }, [searchTerm]);
    return (_jsxs(_Fragment, { children: [userError && (_jsx(Alert, { id: "obituary-table-error", status: "error", title: userError })), _jsx(AdsTable, { product: Product.Obituary, ads: obitTableData !== null && obitTableData !== void 0 ? obitTableData : [], tabs: ORDER_STATUS_TABS, columns: getTableColumns(isPublisher, selectedAdTableTab, showAllOrgsNotices), header: {
                    title: 'Obituaries',
                    subtitle: 'Track the obituaries associated with this account.',
                    additionalContent: (_jsx(ColumnButton, { type: "button", buttonText: "Place an obituary", startIcon: _jsx(PlusCircleIcon, { className: "w-5 h-5" }), onClick: () => {
                            dispatch(push('/obituaries/place'));
                        }, primary: true }))
                }, selectedAdTableTab: selectedAdTableTab, setSelectedAdTableTab: setSelectedAdTableTab, loading: isLoading, activeOrganization: activeOrganization, isPublisher: isPublisher, setSearchTerm: setSearchTerm, rowFilter: rowFilter, setRowFilter: setRowFilter })] }));
}
export default ObitsTableContainer;
