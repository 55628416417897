var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CircularProgress, Button } from '@material-ui/core';
import { UploadIcon } from 'icons';
import React, { useState, useEffect } from 'react';
import { sanitize } from 'lib/helpers';
import Firebase from '../EnoticeFirebase';
import EModal from './modals/EModal';
function UploadButton({ folder, processUpload, processFile, accept, color, label, droppedFile, allowLargeFile, intervalAction }) {
    const [loading, setLoading] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const uploadFile = (file) => __awaiter(this, void 0, void 0, function* () {
        if (!file)
            return;
        setLoading(true);
        intervalAction && intervalAction();
        const snapshot = yield Firebase.storage()
            .ref()
            .child(`${folder}/${new Date().getTime()}/${sanitize(file.name)}`)
            .put(file);
        processUpload && processUpload(snapshot);
        setLoading(false);
    });
    useEffect(() => {
        if (!droppedFile)
            return;
        if (processUpload) {
            void uploadFile(droppedFile);
        }
        if (processFile) {
            processFile(droppedFile);
        }
    }, [droppedFile]);
    useEffect(() => {
        if (loading)
            return;
        uploadError && allowLargeFile && allowLargeFile();
    }, [loading]);
    return (_jsxs(_Fragment, { children: [_jsx("input", { type: "file", accept: accept, style: { display: 'none' }, id: "contained-button-file", onChange: ({ target }) => {
                    const { validity } = target;
                    const [file] = target.files || [];
                    if (!validity.valid) {
                        return;
                    }
                    if (processUpload) {
                        void uploadFile(file);
                    }
                    if (processFile) {
                        processFile(file);
                    }
                    /*
                     * Clear the input value to reset the file input, which is necessary
                     * to allow the user to upload the same file again if needed.
                     */
                    target.value = ''; // eslint-disable-line no-param-reassign
                } }), _jsx("label", Object.assign({ htmlFor: "contained-button-file" }, { children: loading ? (_jsx(CircularProgress, {})) : (_jsx(Button, Object.assign({ id: "upload-button", variant: "contained", color: color || 'primary', component: "span", startIcon: _jsx(UploadIcon, {}), size: "medium", className: "w-auto whitespace-no-wrap inline-flex items-center justify-between px-4 py-2 border border-gray-400 placeholder-gray-700 text-base leading-6 font-medium rounded-md text-gray-700 bg-white" }, { children: _jsx("div", Object.assign({ className: "normal-case" }, { children: label })) }))) })), uploadError && !allowLargeFile && (_jsx(EModal, { setOpen: () => {
                    setUploadError('');
                }, header: "TROUBLE UPLOADING", body: uploadError, buttonText: "OK", onConfirm: () => {
                    setUploadError('');
                } })), _jsx("style", { children: `
      #upload-button {
        background-color: white;
        color: #6B7280;
        box-shadow: none;
        border: 1px #e2e8f0 solid;
        font-size: 16px;
        border-radius: 6px;
      }
      ` })] }));
}
export default UploadButton;
