import * as Sentry from '@sentry/browser';
import { removeUndefinedFields } from 'lib/helpers';
import moment from 'moment';
import { logError, logInfo, logWarn } from 'utils/logger';
export const removeDuplicatesFromArray = (array) => {
    return [...new Set(array)];
};
export const guidGenerator = () => {
    const S4 = function () {
        return ((1 + Math.random()) * 0x10000 || 0).toString(16).substring(1);
    };
    return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
};
export const truncateString = (s, maxLength = 25) => s.length > maxLength ? `${s.slice(0, maxLength)}...` : s;
// vaidate if the object is of Error type or not
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
const isError = (err) => {
    return Object.prototype.toString.call(err) === '[object Error]';
};
const isInUnitTests = () => {
    // See:
    // https://jestjs.io/docs/environment-variables
    return (process &&
        process.env &&
        process.env.NODE_ENV === 'test' &&
        !!process.env.JEST_WORKER_ID);
};
const logAndCaptureErrorHelper = (e, msg, tags = {}, isCriticalError = false) => {
    var _a;
    if (isInUnitTests()) {
        return;
    }
    const formattedTags = Object.assign({}, tags);
    if (isCriticalError) {
        formattedTags.severity = 'critical';
    }
    const formattedMsg = (msg || '').replaceAll('\n', ' ');
    removeUndefinedFields(formattedTags);
    if (formattedMsg) {
        logError(formattedMsg, formattedTags, e);
    }
    for (const k of Object.keys(formattedTags)) {
        Sentry.setTag(k, (_a = formattedTags[k]) !== null && _a !== void 0 ? _a : '');
    }
    if (isError(e)) {
        const errorWithMsg = formattedMsg
            ? new Error(formattedMsg, { cause: e })
            : e;
        Sentry.captureException(errorWithMsg);
    }
    else {
        const errorWithMsg = formattedMsg
            ? new Error(`${formattedMsg} ${JSON.stringify(e)}`)
            : new Error(JSON.stringify(e));
        Sentry.captureException(errorWithMsg);
    }
};
export const logAndCaptureException = (service, err, msg, tags = {}) => {
    return logAndCaptureErrorHelper(err, msg, Object.assign(Object.assign({}, tags), { service }));
};
/**
 * This function is used in SetupSaga on the front-end to enable lib to use logInfo
 */
export const logLibInfo = (msg, tags = {}) => {
    if (isInUnitTests()) {
        return;
    }
    logInfo(msg, tags);
};
/**
 * This function is used in SetupSaga on the front-end to enable lib to use logWarn
 */
export const logLibWarn = (msg, tags = {}) => {
    if (isInUnitTests()) {
        return;
    }
    logWarn(msg, tags);
};
export const logAndCaptureMessage = (msg, tags = {}) => {
    var _a;
    if (isInUnitTests()) {
        return;
    }
    logWarn(msg, tags);
    for (const k of Object.keys(tags)) {
        Sentry.setTag(k, (_a = tags[k]) !== null && _a !== void 0 ? _a : '');
    }
    Sentry.captureMessage(msg);
};
export const logAndCaptureCriticalError = (service, err, msg, tags = {}) => {
    logAndCaptureErrorHelper(err, msg, Object.assign(Object.assign({}, tags), { service }), true);
};
export const calculateTimeDifferenceFromTimestamp = (time) => {
    if (!time)
        return;
    const createdDate = moment(time.toDate());
    return moment(createdDate).fromNow();
};
