import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Popover } from 'lib/components/Popover';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import NewspaperOrderInfoRow from './NewspaperOrderInfoRow';
export function NewspaperOrderInfo({ newspaperOrderFormData, filingTypeName }) {
    const [firstNewspaperOrder, ...popoverNewspaperOrders] = newspaperOrderFormData;
    return (_jsxs("div", Object.assign({ className: "flex flex-row items-center gap-3" }, { children: [_jsx(NewspaperOrderInfoRow, { filingTypeName: filingTypeName, newspaperOrder: firstNewspaperOrder }), popoverNewspaperOrders.length > 0 && (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "font-medium text-sm" }, { children: ["+", popoverNewspaperOrders.length] })), _jsx(Popover, Object.assign({ id: "newspaper-order-info-popover", activator: _jsx(ChevronDownIcon, { className: "w-4 h-4" }), alignment: "right" }, { children: _jsx("div", Object.assign({ className: "flex flex-col m-2 gap-2" }, { children: popoverNewspaperOrders.map((newspaperOrder, index) => {
                                var _a;
                                return (_jsx("div", Object.assign({ className: classNames('w-full', {
                                        'border-t border-column-gray-100 pt-2': !!index
                                    }) }, { children: _jsx(NewspaperOrderInfoRow, { filingTypeName: filingTypeName, newspaperOrder: newspaperOrder, inPopover: true }) }), `${(_a = newspaperOrder.newspaper) === null || _a === void 0 ? void 0 : _a.id}-${newspaperOrder.publishingMedium}-popover-item`));
                            }) })) }))] }))] })));
}
